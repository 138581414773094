// histogram.js

// PUBLIC METHODS =============================================================

// Histogram constructor.
export default function Histogram(minValue=0.0, maxValue=1.0, nullValue=undefined, bucketCount=256) {
    const MIN_BUCKETS = 2;
    const MIN_DELTA   = 1e-7;

    if (bucketCount < MIN_BUCKETS)  bucketCount = MIN_BUCKETS;

    let deltaValue = maxValue - minValue;
    if (deltaValue > -MIN_DELTA && deltaValue < MIN_DELTA) {
        if (deltaValue < 0.0)  deltaValue = -MIN_DELTA;
        else                   deltaValue = MIN_DELTA;
    }

    this.minValue      = minValue;
    this.maxValue      = maxValue;
    this.deltaValue    = deltaValue;
    this.nullValue     = nullValue;
    this.invDeltaValue = 1.0 / deltaValue;
    this.histogram     = new Int32Array(bucketCount);
}

// Clears the specified histogram by filling it with zeroes.
Histogram.prototype.clear = function() {
    this.histogram.fill(0);
};

// Converts a value to a bucket number.
Histogram.prototype.valueToBucket = function(value) {
    const buckets = this.histogram.length;
    value = this.valueToNormValue(value);
    let bucket = Math.floor(value*buckets);
    if (bucket > buckets-1)  bucket = buckets-1;
    if (bucket < 0)          bucket = 0;
    return bucket;
};

// Converts a bucket number to a normalized value.
// This is only an approximation, since bucket numbers are quantized.
Histogram.prototype.bucketToNormValue = function(bucket) {
    const buckets = this.histogram.length;
    let value = 1.0 * bucket / buckets;
    if (value > 1.0)  value = 1.0;
    if (value < 0.0)  value = 0.0;
    return value;
};

// Converts a bucket number to a value.
// This is only an approximation, since bucket numbers are quantized.
Histogram.prototype.bucketToValue = function(bucket) {
    let value = this.bucketToNormValue(bucket);
    value = this.normValueToValue(value);
    return value;
};

// Converts a normalized value to a real value.
Histogram.prototype.normValueToValue = function(value) {
    value = value * this.deltaValue + this.minValue;
    return value;
};

// Converts a real value to a normalized value.
Histogram.prototype.valueToNormValue = function(value) {
    value = (value - this.minValue) * this.invDeltaValue;
    return value;
};

// Adds a value to a bucket in the histogram.
Histogram.prototype.addValue = function(value) {
    if (value !== this.nullValue) {
        let bucket = this.valueToBucket(value);
        ++this.histogram[bucket];
    }
};

// Gets the total number of values that were added to the histogram.
Histogram.prototype.getTotalCount = function() {
    const histogram = this.histogram;
    const buckets = histogram.length;

    let items = 0;
    for (let i=0; i<buckets; ++i) {
        const count = histogram[i];
        items += count;
    }

    return items;
};

// Gets the value from the bucket with the highest count.
Histogram.prototype.getMaxCount = function() {
    const histogram = this.histogram;
    const buckets   = histogram.length;

    let maxCount = 0;
    for (let i=0; i<buckets; ++i) {
        const count = histogram[i];
        if (maxCount < count)  maxCount = count;
    }

    return maxCount;
};

// Calculates the mean and standard deviation (sigma) for the specified histogram.
Histogram.prototype.computeStandardDeviation = function(mean=undefined) {
    const histogram = this.histogram;
    const buckets = histogram.length;

    let items = 0;
    let div = 0;

    // Calculate the mean, if necessary
    if (mean === undefined || mean === null) {
        mean = 0.0;
        for (let i=0; i<buckets; ++i) {
            let count = histogram[i];
            let value = this.bucketToNormValue(i + 0.5);
            mean += count * value;
            items += count;
        }
        div = items;
        if (items > 0) {
            mean /= items;
            --div;
        }
    }
    else {
        for (let i=0; i<buckets; ++i) {
            let count = histogram[i];
            items += count;
        }
        div = items;
    }

    // Calculate the standard deviation
    let total = 0.0;
    for (let i=0; i<buckets; ++i) {
        let count = histogram[i];
        let value = this.bucketToNormValue(i + 0.5);
        let delta = value - mean;
        delta *= delta;
        total += count * delta;
    }
    if (div > 0)
        total /= div;
    else
        total = 0.0;
    let std = Math.sqrt(total);

    // Return the mean and the standard deviation
    return { mean: mean, std: std };
};

// Calculates which values falls at the specified percentile in a histogram.
// Returns the normalized value.
Histogram.prototype.computeNormPercentileValue = function(percentile) {
    const histogram = this.histogram;
    const buckets = histogram.length;

    let total = 0;
    for (let i=0; i<buckets; ++i)
        total += histogram[i];

    let threshold = Math.floor(percentile * total);
    let value = 0.0;
    let bucket = buckets - 1;
    while (bucket >= 0) {
        let count = histogram[bucket];
        if (total - count <= threshold) {
            let percent = (1.0 * total - threshold) / count;
            value = (bucket + percent) / buckets;
            break;
        }
        total -= count;
        --bucket;
    }

    if (value > 1.0)  value = 1.0;
    if (value < 0.0)  value = 0.0;

    return value;
};

// Calculates which values falls at the specified percentile in a histogram.
Histogram.prototype.computePercentileValue = function(percentile) {
    let value = this.computeNormPercentileValue(percentile);
    value = this.normValueToValue(value);
    return value;
};

// Computes the mode (peak of a histogram) of an array of data.
Histogram.prototype.computeMode = function() {
    const histogram = this.histogram;
    const buckets = histogram.length;

    // Find the bucket in the histogram with the highest value
    let countMax = null;
    let maxBucket = null;
    for (let i=0; i<buckets; ++i) {
        let count = histogram[i];
        if (countMax === null || countMax < count) {
            countMax = count;
            maxBucket = i;
        }
    }

    if (maxBucket === null)  maxBucket = 0;

    // Return the highest value
    return this.bucketToNormValue(maxBucket + 1.0);
};

// Computes a high ceiling for a histogram using the specified sigma.
Histogram.prototype.computeHighStd = function(sigma=4.0, mean=undefined) {
    let meanstd = this.computeStandardDeviation(mean);

    let highstd = meanstd.mean + meanstd.std * sigma;

    if (this.deltaValue > 0.0) {
        if      (highstd < this.minValue)  highstd = this.minValue;
        else if (highstd > this.maxValue)  highstd = this.maxValue;
    }
    else {
        if      (highstd < this.maxValue)  highstd = this.maxValue;
        else if (highstd > this.minValue)  highstd = this.minValue;
    }

    return highstd;
};

// Dumps a representation of the histogram to the JavaScript console.
Histogram.prototype.dumpHistogram = function(asGraph=true) {
    const histogram = this.histogram;

    console.log("Histogram:");

    let maxCount = this.getMaxCount();
    for (let i=0; i<histogram.length; ++i) {
        let count = histogram[i];
        if (maxCount < count)  maxCount = count;
    }
    const totalValues = this.getTotalCount();
    const stddev = this.computeStandardDeviation();
    const mode = this.computeMode();
    const nullValue = this.nullValue;
    const minValue = this.minValue;
    const maxValue = this.maxValue;

    if (asGraph) {
        const histogram = this.histogram;

        let columns = 128;
        let rows    = 16;

        if (columns > histogram.length)
            columns = histogram.length;

        let subHistogram = new Int32Array(columns);
        subHistogram.fill(0);

        for (let i=0; i<histogram.length; ++i) {
            let toPos = Math.floor(i * subHistogram.length / histogram.length);
            let count = histogram[i];
            if (subHistogram[toPos] < count)
                subHistogram[toPos] = count;
        }

        for (let row=rows-1; row>=0; --row) {
            let linestr = "  ";
            let cmp = maxCount * row / rows;
            for (let i=0; i<subHistogram.length; ++i) {
                let count = subHistogram[i];
                linestr += (count > cmp) ? "#" : "_";
            }
            console.log(linestr);
        }
    }
    else {
        let hstr = "";
        for (let i=0; i<histogram.length; ++i) {
            let count = histogram[i];
            if (i !== 0)  hstr += ", ";
            hstr += count;
        }

        console.log("  " + hstr);
    }

    console.log("  Range: " + minValue + " - " + maxValue);
    console.log("  Null: " + nullValue);
    console.log("  Mode: " + mode);
    console.log("  Mean: " + stddev.mean);
    console.log("  Std dev: " + stddev.std);
    console.log("  Max Count: " + maxCount);
    console.log("  Total Values: " + totalValues);
    console.log("");
};
