// STATIC VARIABLES ===========================================================

// Log level enumeration.
dconsole.LOG = {
    ALL:   0,  // Shows all logs.
    WARN:  1,  // Shows only warning or error logs.
    ERROR: 2,  // Shows only error logs.
    NONE:  3   // Disables all logging.
};

// Console logging level for the debug console.
dconsole.logLevel = dconsole.LOG.NONE;

// PUBLIC STATIC METHODS ======================================================

// A function that does nothing.
dconsole.nullfunc = function() {};

dconsole.log   = dconsole.nullfunc;
dconsole.info  = dconsole.nullfunc;
dconsole.warn  = dconsole.nullfunc;
dconsole.error = dconsole.nullfunc;

// Sets the logging level for the debug console.
dconsole.setLogLevel = function(logLevel) {
    dconsole.logLevel = logLevel;
    dconsole.log   = (logLevel <= dconsole.LOG.ALL)   ? console.log.bind(window.console)   : dconsole.nullfunc;
    dconsole.info  = (logLevel <= dconsole.LOG.ALL)   ? console.info.bind(window.console)  : dconsole.nullfunc;
    dconsole.warn  = (logLevel <= dconsole.LOG.WARN)  ? console.warn.bind(window.console)  : dconsole.nullfunc;
    dconsole.error = (logLevel <= dconsole.LOG.ERROR) ? console.error.bind(window.console) : dconsole.nullfunc;
};

export default function dconsole() {}

dconsole.setLogLevel(dconsole.LOG.WARN);