
export const blackBgTable = [
  { value: 0.0, color: { red: 0.0, green: 0.0, blue: 0.0, alpha: 1.0 } },
  { value: 1.0, color: { red: 1.0, green: 1.0, blue: 1.0, alpha: 1.0 } },
];

export const bloodLuminosityTable = [
  { value: 0, color: { red: 0.75, green: 0, blue: 0, alpha: 0 } },
  {
    value: 0.1,
    color: { red: 0.75, green: 0, blue: 0, alpha: 0.03529411764705882 },
  },
  {
    value: 0.2,
    color: {
      red: 0.75,
      green: 0.2647058823529412,
      blue: 0,
      alpha: 0.07058823529411765,
    },
  },
  {
    value: 0.3,
    color: {
      red: 0.75,
      green: 0.5823529411764706,
      blue: 0.15294117647058825,
      alpha: 0.047058823529411764,
    },
  },
  {
    value: 0.4,
    color: {
      red: 0.75,
      green: 0.6823529411764706,
      blue: 0.30000000000000004,
      alpha: 0.047058823529411764,
    },
  },
  {
    value: 0.5,
    color: {
      red: 0.75,
      green: 0.7411764705882353,
      blue: 0.44705882352941173,
      alpha: 0.0784313725490196,
    },
  },
  {
    value: 0.6,
    color: {
      red: 0.75,
      green: 0.7352941176470588,
      blue: 0.6205882352941177,
      alpha: 0.1411764705882353,
    },
  },
  {
    value: 0.8,
    color: {
      red: 0.6058823529411765,
      green: 0.75,
      blue: 0.7294117647058823,
      alpha: 0.1568627450980392,
    },
  },
  {
    value: 0.95,
    color: { red: 0.75, green: 0.75, blue: 0.75, alpha: 0.1568627450980392 },
  },
];

export const bloodGradientTable = [
  { value: 0, color: { red: 0.36, green: 0.36, blue: 0.36, alpha: 0 } },
  {
    value: 0.1,
    color: {
      red: 0.4635294,
      green: 0.5599997999999999,
      blue: 0.6,
      alpha: 0.31,
    },
  },
  {
    value: 0.12,
    color: { red: 0.772549, green: 0.933333, blue: 1, alpha: 0.39 },
  },
  { value: 1, color: { red: 0.772549, green: 0.933333, blue: 1, alpha: 0.39 } },
];