
// Include our external dependencies.
import Vmath from "./vmath";


// ============================================================================
// BASE OVERLAYS
// ============================================================================

// ============================================================================
// VolumeOverlay
// ============================================================================

// This is the base class for all overlays drawn on top of the VolumeViewer
// object.
// Overlays support a hierarchical structure, and can handle events
// such as dragging, clicking, and keyboard input, which can be targeted to
// a particular child overlay based on that overlay's screen position or
// selection status.
// This class, and its derivatives, are based on a cooperative model that
// allows all overlays to work together harmoniously.  The architecture is
// also highly extensible.  Specialized overlay classes can be created
// without much trouble.
export class VolumeOverlay {
    // Constructor for the overlay class.
    constructor(parent=null, options=null) {
        VolumeOverlay.registerPrimitive(this);

        this.initializeProperties();

        this._selected       = false;
        this._highlighted    = false;

        this._perViewport    = {};

        this._children       = [];
        this._parent         = null;

        this._redraw                 = false;
        this._shown                  = null;
        this._descendantsSelected    = 0;
        this._descendantsHighlighted = 0;

        this.initializeViewports();

        if (options)  this.setProperties(options);
        if (parent)   parent.addChild(this);
    }

    // Properties list for this class.
    // These properties will automatically be serialized and deserialized
    // via the getProperties() and setProperties() methods, respectively.
    // This list is also used to initialize the properties in the
    // constructor.
    static propertyList() {
        return {
            identifier: null,
            visible:    true,
            selectable: false,
            editable:   true,
            zIndex:     0
        };
    }

    // PRIVATE METHODS ========================================================

    // Generates a token that is used to detect invalid overlays.
    static getNextValidToken() {
        let validToken = VolumeOverlay._nextValidToken;

        ++VolumeOverlay._nextValidToken;
        if (VolumeOverlay._nextValidToken >= Number.MAX_SAFE_INTEGER)
            VolumeOverlay._nextValidToken = Number.MIN_SAFE_INTEGER+1;

        return validToken;
    }

    // Initializes per-viewport cached calculations.
    initializeViewports() {
        let validToken = VolumeOverlay.getNextValidToken();

        this._perViewport[VolumeViewer.VIEW_CENTER] = {
            validToken:   validToken,
            calculations: null
        };
        this._perViewport[VolumeViewer.VIEW_LEFT] = {
            validToken:   validToken,
            calculations: null
        };
        this._perViewport[VolumeViewer.VIEW_RIGHT] = {
            validToken:   validToken,
            calculations: null
        };
    }

    // Returns true if this overlay has been invalidated for the specified
    // viewport.
    isInvalidated(validToken, displayView) {
        const perViewport = this._perViewport;
        if (!(displayView in perViewport))  return true;
        let displayToken = perViewport[displayView].validToken;
        return validToken !== displayToken;
    }

    // Validates this overlay for the specified viewport.
    validate(validToken, displayView) {
        this._perViewport[displayView].validToken = validToken;
    }

    // Updates all ancestors of the overlay when the overlay's
    // selection state is changed.
    updateSelectedAncestors(addSelected=0, addHighlighted=0) {
        let parent = this;
        while (parent) {
            parent._descendantsSelected    += addSelected;
            parent._descendantsHighlighted += addHighlighted;
            parent = parent._parent;
        }
    }

    // Convenience method.  Converts a screen point to a volume point
    // based on the intersection with the current facing plane.
    convertScreenPointToVolume(event, screenPoint) {
        const viewer      = event.viewer;
        const displayView = event.displayView;
        const facingPlane = event.facingPlane;

        let intersect = viewer.getPlaneIntersection(screenPoint.x, screenPoint.y, false,
                                                    [facingPlane]);
        if (intersect) {
            let newPoint = viewer.clampPlaneOffsetToPlane(intersect.point, facingPlane);
            return newPoint;
        }
        else
            return null;
    }

    // Builds a property list for the current object.
    // This uses the static propertyList() method associated with each
    // VolumeOverlay-derived class to build a complete list of all properties
    // for the object.
    buildPropertyList(recursive=true) {
        let propertyList = {};

        let obj = Object.getPrototypeOf(this);
        while (true) {
            // Get the class for this object
            let cls = obj.constructor;

            if (cls === Object)  break;  // just in case

            // Look for a static propertyList method in the class
            if ('propertyList' in cls) {
                let classPropertyList = cls.propertyList();
                if (classPropertyList) {
                    // Copy the properties, overwriting base properties
                    // with derived properties if necessary
                    propertyList = Object.assign(classPropertyList, propertyList);
                }
            }

            if (!recursive)  break;   // only the top level

            if (cls === VolumeOverlay)  break;  // stop at VolumeOverlay

            // Get the base class for this class, and continue
            let superObj = Object.getPrototypeOf(obj);
            obj = superObj;
        }

        return propertyList;
    }

    // STATIC UTILITIES =======================================================

    // Registers an object or class to a specified primitive type.
    // The name of the primitive is obtained from the class' primitive property.
    // Registered primitives are used to construct overlays from a
    // serializable property object.
    static registerPrimitive(obj) {
        // Sanitize inputs
        if (!obj)  return;

        if      (typeof obj === 'function')  obj = obj.prototype;
        else if (typeof obj === 'object')    obj = obj.constructor.prototype;

        if (!obj)  return;
        if (typeof obj !== 'object')  return;

        // Get the name used to register the primitive
        let name = obj.primitive;
        if (!name)
            name = obj.constructor.name;
        if (!name)  return;

        // Ensure our primitive map exists
        if (VolumeOverlay._primitiveMap === undefined)
            VolumeOverlay._primitiveMap = {};

        // Does this primitive already exist in our registry?
        const primitiveMap = VolumeOverlay._primitiveMap;
        if (name in primitiveMap && primitiveMap[name] !== obj) {
            dconsole.warn(`Attempted to re-register primitive type "${name}"`);
            return;
        }

        // Add the class prototype to the registry
        if (!(name in primitiveMap)) {
            VolumeOverlay._primitiveMap[name] = obj;
        }
    }

    // Constructs a primitive based on a serializable property object.
    // May also create child objects, depending on how the original properties
    // were serialized.
    // The specified primitive name must have previously been registered.
    static constructPrimitive(name, parent, options) {
        // Use the name of the primitive in the properties, if no name was
        // specified
        if (!name && options && typeof options === 'object') {
            if ('primitive' in options)
                name = options.primitive;
        }

        if (!name)  return null;

        // Get the prototype from our registry
        const primitiveMap = VolumeOverlay._primitiveMap;
        if (primitiveMap === undefined || !(name in primitiveMap)) {
            dconsole.error(`Attempted to construct unknown primitive type "${name}"!`);
            return null;
        }

        // Construct a new primitive (this recursively builds child
        // primitives as well)
        let newPrimitive = new primitiveMap[name].constructor(parent, options);
        return newPrimitive;
    }

    // Convenience method.  Performs a deep copy on the specified object.
    // Really just a convenient alias for the VolumeViewer call.
    static deepCopy(obj) {
        return VolumeViewer.deepCopy(obj);
    }

    // Convenience method.  Performs a deep comparison of the two specified
    // objects, and returns true if they are equal.
    // Really just a convenient alias for the VolumeViewer call.
    static deepEquals(obj0, obj1) {
        return VolumeViewer.deepEquals(obj0, obj1);
    }

    // Convenience method.  Builds a color string from the specified color,
    // which may be an RGBA object, an array of color components, or a string.
    // Really just a convenient alias for the VolumeViewer call.
    static buildColorString(color) {
        return VolumeViewer.buildColorString(color);
    }

    // Convenience method for chaining inheritable resources.
    // The method will return the first parameter that is not null or undefined.
    // If no resource could be found (that is, if all resources were null
    // or undefined), null is returned.
    static inherit(value0, value1, value2, value3, value4, value5, value6, value7) {
        let value = null;
        if      (value0 !== undefined && value0 !== null)  value = value0;
        else if (value1 !== undefined && value1 !== null)  value = value1;
        else if (value2 !== undefined && value2 !== null)  value = value2;
        else if (value3 !== undefined && value3 !== null)  value = value3;
        else if (value4 !== undefined && value4 !== null)  value = value4;
        else if (value5 !== undefined && value5 !== null)  value = value5;
        else if (value6 !== undefined && value6 !== null)  value = value6;
        else if (value7 !== undefined && value7 !== null)  value = value7;
        return value;
    }

    // PUBLIC PROPERTIES ======================================================

    // Property representing an identifier for the overlay.
    get identifier() {
        return this._identifier;
    }
    set identifier(id) {
        this._identifier = id;
    }

    // Property representing the z-index of the overlay.
    // The z-index has nothing to do with the 3D representation of the overlay.
    // Instead, it is used to control the relative drawing order of overlays.
    // It is similar to the CSS "z-index" property.
    get zIndex() {
        return this._zIndex;
    }
    set zIndex(zIndex) {
        if (!zIndex)  zIndex = 0;
        if (this._zIndex !== zIndex) {
            this._zIndex = zIndex;

            this.requestRedraw();
        }
    }

    // Read-only property representing the topmost overlay in this overlay's
    // hierarchy.
    get root() {
        let parent = this;
        while (parent._parent)
            parent = parent._parent;
        return parent;
    }

    // Read-only property representing the highest-level aggregate overlay
    // that is an ancestor of this overlay.
    // If no ancestors are aggregates, the property returns this overlay.
    get topAggregate() {
        let aggregate = this;
        let parent = this;
        while (parent) {
            if (parent.isAggregate)
                aggregate = parent;
            parent = parent._parent;
        }
        return aggregate;
    }

    // Read-only property representing the highest-level selectable overlay
    // that is an ancestor of this overlay.
    // If no ancestors are selectable, the property returns this overlay.
    get topSelectable() {
        let selectable = this;
        let parent = this;
        while (parent) {
            if (parent.isAggregate && parent.selectable)
                selectable = parent;
            parent = parent._parent;
        }
        return selectable;
    }

    // Property representing the parent of the overlay.
    get parent() {
        return this._parent;
    }
    set parent(parent) {
        this.setParent(parent);
    }

    // Property representing children of the overlay.
    // This makes a shallow copy of the children when getting
    // and setting the list of children.
    // In the setter, note that children may be specified with a
    // serializable property object rather than an actual overlay
    // or overlay hierarchy.  Property objects will be used to
    // automatically construct new child overlays.
    get children() {
        return this._children.slice();  // shallow copy
    }
    set children(children) {
        // We're setting our children; erase our original children
        if (!children) {
            this.clear();
            return;
        }

        // This must be an array
        if (!Array.isArray(children))  return;

        // Create or add all child objects
        this.clear();
        for (let i=0; i<children.length; ++i) {
            let child = children[i];
            if (child instanceof VolumeOverlay) {
                // This is a real overlay, so just add it
                this.addChild(child);
            }
            else if (typeof child === 'object') {
                // This is a property object, so construct new children
                let properties = child;
                if ('primitive' in properties) {
                    let primitive = properties.primitive;
                    child = VolumeOverlay.constructPrimitive(primitive, this, properties);
                    if (child) {
                        this.addChild(child);
                    }
                    else
                        dconsole.error(`Failed to create child primitive type "${primitive}"`);
                }
                else
                    dconsole.error("Could not construct primitive (no type defined)");
            }
            else
                dconsole.error("Child type not recognized");
        }
    }

    // Property representing whether the overlay is visible.
    // Descendants of this overlay are also implicitly hidden when this property
    // is set to false.
    get visible() {
        return this._visible;
    }
    set visible(visible) {
        visible = Boolean(visible);

        if (this._visible !== visible) {
            this._visible = visible;

            this.invalidate();

            // Deselect anything that is not visible
            if (!visible) {
                this.unhighlightAll();
                this.unselectAll();
            }
        }
    }

    // Property representing whether the overlay is selectable.
    // Selectability is not inherited by ancestor or descendant overlays.
    get selectable() {
        return this._selectable;
    }
    set selectable(selectable) {
        selectable = Boolean(selectable);

        if (this._selectable !== selectable) {
            this._selectable = selectable;

            if (!selectable) {
                this.selected    = false;
                this.highlighted = false;
            }
        }
    }

    // Property representing whether the overlay is user-editable.
    // Descendants of this overlay are also implicitly uneditable when this
    // property is set to false.
    get editable() {
        return this._editable;
    }
    set editable(editable) {
        editable = Boolean(editable);
        if (this._editable !== editable) {
            this._editable = editable;

            this.requestRedraw();
        }
    }

    // Property representing whether the overlay is currently selected.
    // If an overlay is not selectable, this property is not allowed to be
    // set to true.
    // In contrast to the "highlighted" property, selection is semi-permanent.
    get selected() {
        return this._selected;
    }
    set selected(selected) {
        selected = Boolean(selected);

        if (selected && !this._selectable)
            selected = false;

        if (this._selected !== selected) {
            this._selected = selected;

            let addSelected = selected ? 1 : -1;
            this.updateSelectedAncestors(addSelected, 0);

            this.requestRedraw();
        }
    }

    // Property representing whether the overlay is currently highlighted.
    // If an overlay is not selectable, this property is not allowed to be
    // set to true.
    // In contrast to the "selected" property, highlighting is transient
    // and only occurs when a user mouses over an overlay or starts dragging it.
    get highlighted() {
        return this._highlighted;
    }
    set highlighted(highlighted) {
        highlighted = Boolean(highlighted);

        if (highlighted && !this._selectable)
            highlighted = false;

        if (this._highlighted !== highlighted) {
            this._highlighted = highlighted;

            let addHighlighted = highlighted ? 1 : -1;
            this.updateSelectedAncestors(0, addHighlighted);

            this.requestRedraw();
        }
    }

    // Read-only property representing whether descendants of this overlay
    // have been selected.
    // If this overlay is itself selected, this property will return true.
    get descendantsSelected() {
        return this._descendantsSelected > 0;
    }

    // Read-only property representing whether descendants of this overlay
    // have been highlighted.
    // If this overlay is itself highlighted, this property will return true.
    get descendantsHighlighted() {
        return this._descendantsHighlighted > 0;
    }

    // PUBLIC METHODS =========================================================

    // Invalidates the overlay.
    // This causes the overlay to be redrawn, and previously cached
    // calculations associated with the overlay are discarded.
    invalidate() {
        let validToken = VolumeOverlay.getNextValidToken();

        this._perViewport[VolumeViewer.VIEW_CENTER].validToken = validToken;
        this._perViewport[VolumeViewer.VIEW_LEFT].validToken   = validToken;
        this._perViewport[VolumeViewer.VIEW_RIGHT].validToken  = validToken;

        this._shown = null;

        this.requestRedraw();

        // Parent objects may have based their own calculations on the
        // calculations of their child objects, so we must invalidate
        // these as well.
        if (this._parent)  this._parent.invalidate();
    }

    // Requests a redraw for the overlay.
    // Unlike the invalidate() method, cached calculations are NOT discarded.
    requestRedraw() {
        let parent = this;
        while (parent) {
            if (!parent._parent)
                parent.onRequestRedraw();
            if (parent._redraw)
                break;
            parent._redraw = true;
            if (!parent._visible)
                break;
            parent = parent._parent;
        }
    }

    // Initializes all private fields from the public properties
    // associated with each class.
    // Slightly dangerous, but oh so convenient.  :)
    initializeProperties() {
        let propertyList = this.buildPropertyList(true);

        let keys = Object.keys(propertyList);
        for (let i=0; i<keys.length; ++i) {
            let key = keys[i];
            let value = propertyList[key];
            let field = '_' + key;  // convert property name to private field name
            this[field] = value;
        }
    }

    // Applies properties to this overlay.
    // If child overlays are included in the supplied property hierarchy,
    // they will be added as well, automatically replacing any existing
    // children owned by the overlay.
    setProperties(properties) {
        if (!properties || typeof properties !== 'object')  return;

        // Get the property list associated with this object
        let propertyList = this.buildPropertyList();

        // ONLY set properties that are in the property list!
        let keys = Object.keys(propertyList);
        for (let i=0; i<keys.length; ++i) {
            let key = keys[i];
            if (key in properties)
                this[key] = properties[key];
        }

        // Special case for children.  This is a little terrifying...
        // Automatically construct children from the supplied properties!
        if ('children' in properties)  this.children = properties.children;
    }

    // Generates a property object hierarchy from this overlay.
    // If the "recursive" option is true, child objects will be
    // included in the property hierarchy.
    getProperties(recursive=false, simplify=undefined) {
        // Properties will always start with the primitive name (for readability).
        // Note that this is a read-only property and cannot be set.
        let properties = {
            primitive: this.primitive
        };

        // Build a property list, and populate our output properties from it
        let propertyList = this.buildPropertyList();

        let keys = Object.keys(propertyList);
        for (let i=0; i<keys.length; ++i) {
            let key = keys[i];
            if (key in this) {
                let value = this[key];
                if (simplify) {
                    // Do not include this property if it is using its default value
                    let defaultValue = propertyList[key];
                    if (!VolumeOverlay.deepEquals(value, defaultValue))
                        properties[key] = value;
                }
                else {
                    properties[key] = value;
                }
            }
        }

        // Properties will always end with the children (again, for readability).
        // Child properties will only be constructed upon request.
        if (recursive) {
            // Recursively build the child property list
            let childList = [];
            const children = this.children;
            for (let i=0; i<children.length; ++i) {
                const child = children[i];
                if (child) {
                    let childSimplify = (simplify !== undefined ? simplify : true);
                    childList.push(child.getProperties(recursive, childSimplify));
                }
            }

            if (simplify) {
                // Do not include a "children" property if there are none
                if (childList.length > 0)
                    properties.children = childList;
            }
            else {
                properties.children = childList;
            }
        }

        return properties;
    }

    // Sets the parent of the current overlay.
    // If the parent is set to null, the overlay is detached from its
    // original hierarchy.
    // If a parent is specified, this overlay will be added to the end of
    // the parent's child list (meaning it will be drawn on top of the
    // other overlays).
    setParent(parent) {
        // Fast discard
        if (this._parent === parent)  return;

        if (parent)
            parent.addChild(this);
        else if (this._parent)
            this._parent.removeChild(this);
    }

    // Adds a child overlay to the current overlay.
    // If "beforeChild" is specified, the child will be placed immediately
    // before the specified sibling.  If it is not specified, is null,
    // or the specified overlay is not owned by the parent, the child
    // will be placed at the end of the parent's child list.
    addChild(child, beforeChild=null) {
        // Sanity check
        if (!child)  return;

        // Make sure we're not creating loops in the hierarchy
        let obj = this;
        while (obj) {
            if (obj === child) {
                dconsole.warn("Attempted to create circular overlay dependency");
                return;
            }
            obj = obj._parent;
        }

        let children = this._children;

        // Special case -- this overlay already owns the child, so just
        // reposition the child in the child list, if necessary.
        if (child._parent === this) {
            const index = children.indexOf(child);
            if (index >= 0) {
                if (child === beforeChild)  return;  // already in the correct place

                let beforeIndex = children.indexOf(beforeChild);
                if (beforeIndex < 0)  beforeIndex = children.length;
                if (index === beforeIndex-1)  return;  // already in the correct place

                if (index < beforeIndex)  --beforeIndex;
                children.splice(index, 1);
                children.splice(beforeIndex, 0, child);
                child.requestRedraw();
                return;
            }
        }

        // Remove the child from its original parent
        if (child._parent)
            child._parent.removeChild(child);

        // Put the child in its specified location
        const index = children.indexOf(beforeChild);
        if (index >= 0)
            children.splice(index, 0, child);
        else
            children.push(child);
        child._parent = this;

        // Update selection status for ancestors
        this.updateSelectedAncestors(child._descendantsSelected,
                                     child._descendantsHighlighted);

        this.invalidate();
    }

    // Removes a child from its parent.
    removeChild(child) {
        if (!child)  return;

        if (child._parent !== this) {
            // We don't own this child
            if (child._parent)
                child._parent.removeChild(child);
            return;
        }

        // Remove the child from our list
        let children = this._children;
        const index = children.indexOf(child);
        if (index >= 0) {
            children.splice(index, 1);
        }

        // Update selection status for ancestors
        this.updateSelectedAncestors(-child._descendantsSelected,
                                     -child._descendantsHighlighted);

        child._parent = null;

        this.invalidate();
    }

    // Destroys this overlay by removing it from its parent and
    // removing its children.
    destroy(recursive=false) {
        this.clear(recursive);
        this.parent = null;
    }

    // Removes all children from this overlay.
    clear(recursive=false) {
        let children = this._children;
        this._children = [];

        for (let i=0; i<children.length; ++i) {
            let child = children[i];
            this.removeChild(child);
        }

        if (recursive) {
            for (let i=0; i<children.length; ++i) {
                let child = children[i];
                child.clear(recursive);
            }
        }

        this.invalidate();
    }

    // Moves the overlay to the beginning of its parent list
    // (placing it underneath its siblings in the drawing order).
    lower() {
        let parent = this._parent;
        if (parent) {
            let children = parent._children;
            if (children.length > 0) {
                let child = children[0];
                parent.addChild(this, child);
            }
        }
    }

    // Moves the overlay to the end of its parent list
    // (placing it above its siblings in the drawing order).
    raise() {
        let parent = this._parent;
        if (parent) {
            parent.addChild(this, null);
        }
    }

    // An iterator method that moves to the first child of the overlay.
    // If the overlay has no children, this method returns null.
    firstChild() {
        let children = this._children;
        if (children.length > 0)
            return children[0];
        return null;
    }

    // An iterator method that moves to the last child of the overlay.
    // If the overlay has no children, this method returns null.
    lastChild() {
        let children = this._children;
        if (children.length > 0)
            return children[children.length-1];
        return null;
    }

    // An iterator method that moves to the next sibling overlay.
    // If the current overlay does not have a parent, or is last
    // in the parent's list of children, this method returns null.
    nextSibling() {
        let parent = this._parent;
        if (parent) {
            let siblings = parent._children;
            let index = siblings.indexOf(this);
            if (index >= 0 && index < siblings.length-1)
                return siblings[index+1];
        }
        return null;
    }

    // An iterator method that moves to the previous sibling overlay.
    // If the current overlay does not have a parent, or is first
    // in the parent's list of children, this method returns null.
    prevSibling() {
        let parent = this._parent;
        if (parent) {
            let siblings = parent._children;
            let index = siblings.indexOf(this);
            if (index > 0)
                return siblings[index-1];
        }
        return null;
    }

    // An iterator method that moves to the first child of the overlay's parent.
    firstSibling() {
        let parent = this._parent;
        if (parent)
            return parent.firstChild();
        return this;
    }

    // An iterator method that moves to the last child of the overlay's parent.
    lastSibling() {
        let parent = this._parent;
        if (parent)
            return parent.lastChild();
        return this;
    }

    // An iterator method that moves to the next overlay in a recursive,
    // depth-first, top-to-bottom traversal of the overlay hierarchy.
    // Returns the next overlay in the traversal.
    // If there is no overlay after this one, the method will loop back to the
    // first overlay.
    next() {
        // STM_TODO - use filter as parameter

        // First, try our first child
        let children = this._children;
        if (children.length > 0) {
            return children[0];
        }
        // No children, look for siblings
        let child  = this;
        let parent = this._parent;
        while (parent) {
            let siblings = parent._children;
            let index = siblings.indexOf(child);
            ++index;
            if (index < siblings.length)
                return siblings[index];

            // No siblings, get the parent's sibling
            child = parent;
            parent = parent._parent;
        }
        return child;
    }

    // An iterator method that moves to the previous overlay in a recursive,
    // depth-first, top-to-bottom traversal of the overlay hierarchy.
    // Returns the previous overlay in the traversal.
    // If there is no overlay before this one, the method will loop back to the
    // last overlay.
    prev() {
        // STM_TODO - use filter as parameter

        let child = this;
        let parent = this._parent;
        if (parent) {
            // Look for siblings
            let siblings = parent._children;
            let index = siblings.indexOf(child);
            if (index <= 0) {
                // We are firstborn, so return the parent
                return parent;
            }
            else
                child = siblings[index-1];
        }

        // Get the last deepest descendant for what we found
        while (child) {
            let children = child._children;
            if (children.length <= 0)
                break;
            child = children[children.length-1];
        }
        return child;
    }

    // Returns true if this overlay is the first child of its parent,
    // or false otherwise.
    // If the overlay has no parent, the method returns true.
    isFirstChild() {
        if (!this._parent)  return true;
        const children = this._parent._children;
        if (children.length > 0 && children[0] === this)
            return true;
        return false;
    }

    // Returns true if this overlay is the last child of its parent,
    // or false otherwise.
    // If the overlay has no parent, the method returns true.
    isLastChild() {
        if (!this._parent)  return true;
        const children = this._parent._children;
        if (children.length > 0 && children[children.length-1] === this)
            return true;
        return false;
    }

    // Finds all descendant overlays that match the provided filter.
    findDescendants(filter, includeSelf=false) {
        let descendants = [];
        this.processDescendants(c => { if (filter(c)) descendants.push(c); }, null, null, includeSelf);
        return descendants;
    }

    // Finds the first descendant overlay that matches the provided filter.
    findDescendant(filter, includeSelf=false) {
        let descendant = null;
        this.processDescendants(c => { if (filter(c)) { descendant = c; return true; } }, null, null, includeSelf);
        return descendant;
    }

    // Finds all child overlays that match the provided filter.
    findChildren(filter) {
        let children = [];
        this.processChildren(c => { if (filter(c)) children.push(c); });
        return children;
    }

    // Finds the first child overlay that matches the provided filter.
    findChild(filter) {
        let child = null;
        this.processChildren(c => { if (filter(c)) { child = c; return true; } });
        return child;
    }

    // Processes all children of the overlay based on an action function.
    // Can be used to build lists or set properties.
    processChildren(action) {
        if (typeof action !== 'function')  action = null;
        if (!action)  return;

        const children = this._children;
        for (let i=0; i<children.length; ++i) {
            const child = children[i];
            if (child)
                if (action(child))
                    break;
        }
    }

    // Processes all descendants of the overlay based on an action function.
    // Can be used to build lists or set properties.
    processDescendants(preAction=null, childFilter=null, postAction=null,
                       includeSelf=false) {
        const MAX_RECURSION = VolumeOverlay._MAX_RECURSION;

        if (typeof preAction  !== 'function')  preAction   = null;
        if (typeof postAction !== 'function')  postAction  = null;

        if (!preAction && !postAction)  return;

        const minLevel = includeSelf ? 0 : 1;

        function processRecursive(child, level) {
            if (!child)  return false;

            if (level < minLevel || (childFilter === null || childFilter(child))) {
                if (level >= minLevel && preAction)
                    if (preAction(child))
                        return true;  // abort

                if (level < MAX_RECURSION) {
                    const children = child._children;
                    for (let i=0; i<children.length; ++i) {
                        const child = children[i];
                        if (processRecursive(child, level+1))
                            return true;  // abort
                    }
                }

                if (level >= minLevel && postAction)
                    if (postAction(child))
                        return true;  // abort
            }

            return false;
        }

        processRecursive(this, 0);
    }

    // Returns true if the overlay is visible, or false if it is not.
    // This method differs from the visible property in that it also
    // checks the visibility of all ancestor overlays.
    // The dynamic "shown" state is not checked.
    isVisible() {
        let parent = this;
        while (parent) {
            if (!parent.visible)
                return false;
            parent = parent._parent;
        }
        return true;
    }

    // Returns true if the overlay is visible and shown, or false if it is not.
    // This method differs from the isVisible() method in that it also checks
    // the dynamic "shown" state.
    isShown() {
        let parent = this;
        while (parent) {
            if (!parent.visible || !parent._shown)
                return false;
            parent = parent._parent;
        }
        return true;
    }

    // Returns true if the overlay is user-editable, or false if it is not.
    // This method differs from the editable property in that it also
    // checks the editability of all ancestor overlays.
    // Visibility and selection properties are not checked.
    isEditable() {
        let parent = this;
        while (parent) {
            if (!parent.editable)
                return false;
            parent = parent._parent;
        }
        return true;
    }

    // Gets the cached calculation object associated with the specified
    // viewport.
    // Note that this method assumes that the calculations are cached and
    // up to date.
    getCalculations(displayView) {
        let viewCalculations = null;
        const perViewport = this._perViewport;
        if (displayView in perViewport)
            viewCalculations = perViewport[displayView].calculations;
        if (!viewCalculations)  viewCalculations = VolumeOverlay._EMPTY_OBJECT;
        return viewCalculations;
    }

    // Finds the overlay that most closely matches the given screen coordinates.
    // Scans all descendants at or below the overlay from which the query is made.
    // If topLevel is specified, that overlay will be used as the root overlay
    // for the search instead.
    // Note that topLevel must be part of the same hierarchy.
    // The method returns the most closely matching overlay.
    findSelectableOverlay(pointX, pointY, filter=null, topLevel=null) {
        let root = this.root;
        if (root !== this) {
            // Use the root overlay to make the query.
            // It has all of the necessary viewer information.
            if (topLevel === null)
                topLevel = this;
            return root.findSelectableOverlay(pointX, pointY, filter, topLevel);
        }

        // This is the root overlay, but we have no viewer information,
        // and therefore no way of converting overlays to screen coordinates.
        // The method has failed, so return null.
        return null;
    }

    // Deselects all overlays in the hierarchy in which this overlay resides.
    // If "skip" is specified, the specified overlay is left alone.
    unselectAll(skip=null) {
        const MAX_RECURSION = VolumeOverlay._MAX_RECURSION;

        function clearRecursive(child, skip, level) {
            if (!child)  return;

            if (level < MAX_RECURSION) {
                const children = child._children;
                for (let i=0; i<children.length; ++i) {
                    const child = children[i];
                    if (child._descendantsSelected > 0)
                        clearRecursive(child, skip, level+1);
                }
            }

            if (child.selected && (skip !== child))
                child.selected = false;
        }

        clearRecursive(this.root, skip, 0);
    }

    // Unhighlights all overlays in the hierarchy in which this overlay resides.
    // If "skip" is specified, the specified overlay is left alone.
    unhighlightAll(skip=null) {
        const MAX_RECURSION = VolumeOverlay._MAX_RECURSION;

        function clearRecursive(child, skip, level) {
            if (!child)  return;

            if (level < MAX_RECURSION) {
                const children = child._children;
                for (let i=0; i<children.length; ++i) {
                    const child = children[i];
                    if (child._descendantsHighlighted > 0)
                        clearRecursive(child, skip, level+1);
                }
            }

            if (child.highlighted && (skip !== child))
                child.highlighted = false;
        }

        clearRecursive(this.root, skip, 0);
    }

    // Selects the current overlay.
    // Similar to setting the "selected" property to true, but this method
    // also clears selections for all other items in the hierarchy.
    select() {
        this.unselectAll(this);
        this.selected = true;
    }

    // Highlights the current overlay.
    // Similar to setting the "highlighted" property to true, but this method
    // also clears highlights for all other items in the hierarchy.
    highlight() {
        this.unhighlightAll(this);
        this.highlighted = true;
    }

    // Gets a list of all selected and highlighted descendant overlays.
    getSelectedAndHighlighted() {
        const MAX_RECURSION = VolumeOverlay._MAX_RECURSION;

        let selected    = [];
        let highlighted = [];

        function buildRecursive(child, level) {
            if (!child)  return;

            if (level < MAX_RECURSION) {
                const children = child._children;
                for (let i=0; i<children.length; ++i) {
                    const child = children[i];
                    if (child.descendantsSelected || child.descendantsHighlighted) {
                        buildRecursive(child, level+1);
                    }
                }
            }

            if (child.selected)
                selected.push(child);
            if (child.highlighted)
                highlighted.push(child);
        }

        buildRecursive(this, 0);

        return {
            selected:    selected,
            highlighted: highlighted
        };
    }

    // Gets a list of all selected descendant overlays.
    getSelected() {
        let items = this.getSelectedAndHighlighted();
        return items.selected;
    }

    // Gets a list of all highlighted descendant overlays.
    getHighlighted() {
        let items = this.getSelectedAndHighlighted();
        return items.highlighted;
    }

    // Moves the selection state to the next selectable item in the
    // overlay hierarchy.
    selectNext(topSelectableOnly=true) {
        let selected = null;

        let selectedList = this.root.getSelected();
        if (selectedList.length > 0) {
            // Something is already selected
            selected = selectedList[selectedList.length-1];
            if (topSelectableOnly)
                selected = selected.topSelectable;
            let next = selected.next();
            while (next !== selected) {
                if (next.selectable && next.isVisible()) {
                    if (!topSelectableOnly || next === next.topSelectable) {
                        selected = next;
                        break;
                    }
                }
                next = next.next();
            }
        }
        else {
            // Nothing is selected, so select something
            // Try a visible overlay first
            selectedList = this.root.findDescendants(c => c.selectable && c.isShown(), true);
            // Failing that, settle for one that's off-screen
            if (selectedList.length <= 0)
                selectedList = this.root.findDescendants(c => c.selectable && c.isVisible(), true);
            if (selectedList.length > 0) {
                selected = selectedList[0];
                if (topSelectableOnly)
                    selected = selected.topSelectable;
            }
        }

        if (selected)  selected.select();
        return selected;
    }

    // Moves the selection state to the previous selectable item in the
    // overlay hierarchy.
    selectPrev(topSelectableOnly=true) {
        let selected = null;

        let selectedList = this.root.getSelected();
        if (selectedList.length > 0) {
            selected = selectedList[selectedList.length-1];
            if (topSelectableOnly)
                selected = selected.topSelectable;
            let prev = selected.prev();
            while (prev !== selected) {
                if (prev.selectable && prev.isVisible()) {
                    if (!topSelectableOnly || prev === prev.topSelectable) {
                        selected = prev;
                        break;
                    }
                }
                prev = prev.prev();
            }
        }
        else {
            // Nothing is selected, so select something
            // Try a visible overlay first
            selectedList = this.root.findDescendants(c => c.selectable && c.isShown(), true);
            // Failing that, settle for one that's off-screen
            if (selectedList.length <= 0)
                selectedList = this.root.findDescendants(c => c.selectable && c.isVisible(), true);
            if (selectedList.length > 0) {
                selected = selectedList[selectedList.length-1];
                if (topSelectableOnly)
                    selected = selected.topSelectable;
            }
        }

        if (selected)  selected.select();
        return selected;
    }

    // Tells the overlay to show its highest-level aggregate (which may be
    // itself).  Overlays are responsible for knowing the best way to change
    // the volume viewer's orientation, zoom level, etc. to display themselves.
    show() {
        let top = this.topAggregate;

        // STM_TODO - HACK
        let root = this.root;
        if (root && root._viewer) {
            const viewer = root._viewer;
            let event = Object.freeze({
                viewer: viewer
            });
            top.onRequestShow(event);
        }
    }

    // Shows the first selected overlay in the hierarchy.
    showSelected() {
        let selected = this.getSelected();
        if (selected.length > 0) {
            selected[0].show();
        }
    }

    // OVERRIDEABLE METHODS ===================================================

    // Returns the unique name of the overlay type.
    // Used when serializing and deserializing properties.
    // This method must be overridden by derived classes.
    get primitive() {
        return this.constructor.name;
    }

    // Returns true if the overlay is an aggregate -- that is, if it owns
    // and is responsible for controlling all of its descendants.
    // When the child of an aggregate overlay is selected or highlighted,
    // the entire aggregate hierarchy will be drawn on top of other overlays.
    // All mouse and keyboard events will also go to the aggregate, which is
    // responsible for propagating the event to the actual selected overlay.
    // (The aggregate and the selected overlay may be the same object.)
    // This method may be overridden by derived classes.
    get isAggregate() {
        return false;
    }

    // Invoked when a redraw is requested.
    // Does nothing by default.
    // This method may be overridden by derived classes.
    onRequestRedraw() {
    }

    // Invoked when somebody has requested that the overlay show itself.
    // This method is responsible for updating the volume viewer to
    // an appropriate orientation, zoom level, etc. in order to show the
    // overlay.
    // This method may be overridden by derived classes.
    onRequestShow(event) {
    }

    // Builds an object containing rendering hints.
    // These hints are passed to rendered descendant overlays and may be
    // used to modify the appearance of those overlays.
    // As implied by the name, these are hints only, and are not enforced.
    // This method may be overridden by derived classes.
    onBuildRenderHints(event, calculations) {
        return null;
    }

    // Starts a dragging operation on the overlay.
    // Information related to the volume viewer and the current context
    // is provided in the form of an event object.
    // This method may be overridden by derived classes.
    onStartDrag(event) {
        return false;
    }

    // Continues a dragging operation on the overlay.
    // This event will only be received by this overlay if the onStartDrag()
    // method was called earlier for this overlay.  Naked drag events should
    // never occur.
    // This method may be overridden by derived classes.
    onDrag(event) {
        return false;
    }

    // Completes a dragging operation on the overlay.
    // This event will only be received by this overlay if the onStartDrag()
    // method was called earlier for this overlay.  Naked drag events should
    // never occur.
    // This method may be overridden by derived classes.
    onEndDrag(event) {
        return false;
    }

    // Handles a single-click or tablet touch event on the overlay.
    // This method may be overridden by derived classes.
    onClick(event) {
        return false;
    }

    // Handles keydown events on the overlay.
    // The target overlay that typically receives this event is the currently
    // highlighted or selected overlay in the viewer.
    // This method may be overridden by derived classes.
    onKeyDown(event) {
        return false;
    }

    // Returns true if the overlay should be drawn, or false otherwise.
    // This implicitly affects all descendants of the overlay as well.
    // This method may be overridden by derived classes.
    onShouldShow(event) {
        return true;
    }

    // Performs expensive calculations associated with a specific viewport.
    // Results will be cached and re-used when possible.
    // Note that a single volume viewer may render to more than one viewport
    // during a render pass!
    // This method may be overridden by derived classes.
    onCalculate(event) {
        return null;
    }

    // Determines whether the specified screen position is "part of" the overlay.
    // Criteria for this determination varies from overlay to overlay.
    // Invoked from the findSelectableOverlay() method.
    // This method may be overridden by derived classes.
    onQuerySelectable(event, calculations) {
    }

    // Draws the contents of the overlay, prior to the children being rendered.
    // This method may be overridden by derived classes.
    onPreRender(event, calculations) {
    }

    // Draws the contents of the overlay, after the children have been rendered.
    // This method may be overridden by derived classes.
    onPostRender(event, calculations) {
    }
}
// Register this primitive
VolumeOverlay.registerPrimitive(VolumeOverlay);

// Static variables owned by VolumeOverlay.
VolumeOverlay._nextValidToken   = 0;
VolumeOverlay._MAX_RECURSION    = 64;
VolumeOverlay._EMPTY_OBJECT     = Object.freeze({});

VolumeOverlay.PRIORITY_LOW      = 0;
VolumeOverlay.PRIORITY_NORMAL   = 1;
VolumeOverlay.PRIORITY_HIGH     = 2;
VolumeOverlay.PRIORITY_OVERRIDE = 65536;

VolumeOverlay.DEFAULT_FONT      = "bold 10pt Arial";


// ============================================================================
// VolumeOverlayRoot
// ============================================================================

// This is an overlay that is specifically set up to be a root node,
// and to contain VolumeViewer information that can be used to draw and
// trap events.
// This overlay should ONLY be instantiated by the volume viewer.
export class VolumeOverlayRoot extends VolumeOverlay {
    // Constructor for the overlay class.
    constructor(parent, options, viewer, canvas, context) {
        super();
        if (!viewer && !canvas && !context) {
            throw "Cannot create root overlay without a volume viewer!";
        }
        this._viewer      = viewer;
        this._canvas      = canvas;
        this._context     = context;
        this._animationId = null;

        this._dragging = false;
        this._handlers = null;
        this._target   = null;
        this._dragView = null;
        this._dragFace = null;

        if (options)  this.setProperties(options);
        if (parent)   parent.addChild(this);
    }

    // PRIVATE METHODS ========================================================

    // Builds an event object for callbacks.
    // The event object, by default, contains a basic set of information
    // that is useful to nearly any callback.  Additional information may
    // be added by the caller.
    // The event is frozen so that it may not be modified by callbacks.
    buildEvent(viewport, extraParams) {
        let hasPrecision = this._viewer.hasPrecision();
        let event = {
            viewer:       this._viewer,
            hasPrecision: hasPrecision,
            context:      this._context,
            viewport:     viewport,
            displayView:  viewport.displayView
        };

        if (extraParams)  Object.assign(event, extraParams);

        return Object.freeze(event);
    }

    // Builds a list of potential controllers for a target.
    // Controllers are basically all of the aggregate overlays that are
    // ancestors of the target overlay, as well as the target itself.
    // The list is reversed so that the highest-level overlays come first
    // in the list.
    // This list is used to invoke callbacks, with the highest-level overlays
    // getting first crack at processing the event.
    buildControllerList(target) {
        let controllers = [];
        if (target) {
            controllers.push(target);

            let parent = target._parent;
            while (parent) {
                if (parent !== this && parent.isAggregate)
                    controllers.push(parent);
                parent = parent._parent;
            }
            controllers.reverse();
        }

        return controllers;
    }

    // Builds a list of potential overlay targets, based on their selection or
    // highlighting status.
    // These overlay targets will be the ones that receive keyboard input
    // or other commands that are not dependent on screen position.
    buildTargetList() {
        let targets = [];
        let target  = null;
        let items = this.getSelectedAndHighlighted();
        if (items.selected.length) {
            targets = items.selected;
            target  = items.selected[0];
        }
        else if (items.highlighted.length) {
            targets = items.highlighted;
            target  = items.highlighted[0];
        }

        if (target) {
            return {
                target:  target,
                targets: targets
            };
        }
        return null;
    }

    // Updates cached calculations from the top level.
    updateCalculations(displayView) {
        const viewer  = this._viewer;
        const context = this._context;

        if (!viewer)   return;
        if (!context)  return;

        // Perform any setup or coordinate calculation needed for overlays
        const viewport = viewer.getViewport(displayView);
        this.calculateHierarchy(context, viewport);
    }

    // Generates useful information about the planes currently displayed
    // in the volume viewer, if planes are being displayed.
    generatePlaneData(viewer) {
        let viewPlanes = [];

        // Only useful if we are showing planes...
        if (viewer.canShowPlanes()) {
            let planes;
            if (viewer.isAutoPlaneEnabled()) {
                // One plane
                let facingPlane = viewer.getFacingPlane();
                planes = [Math.abs(facingPlane)];
            }
            else {
                // Three planes
                planes = [1, 2, 3];
            }

            let planeStepData = viewer.getPlaneStepData();

            // Compute useful plane data, including plane thickness and orientation
            for (let i=0; i<planes.length; ++i) {
                const plane = planes[i];
                let planeData = viewer.getPlaneNormal(plane);
                if (planeData) {
                    let planePoint  = planeData.point;
                    let planeNormal = planeData.normal;

                    let planePos = Math.abs(plane) - 1;

                    let stepData = null;
                    if      (planePos === 0)  stepData = planeStepData.xStep;
                    else if (planePos === 1)  stepData = planeStepData.yStep;
                    else if (planePos === 2)  stepData = planeStepData.zStep;

                    if (stepData) {
                        let stepIncrement = stepData.stepIncrement;

                        let viewPlane = {
                            plane:     plane,
                            point:     planePoint,
                            normal:    planeNormal,
                            thickness: stepIncrement
                        };
                        viewPlanes.push(Object.freeze(viewPlane));
                    }
                }
            }
        }

        // Return the data
        return Object.freeze(viewPlanes);
    }

    // Updates all calculations for visible objects in the hierarchy.
    // As a side effect, also updates the shown state of each overlay.
    calculateHierarchy(context, viewport) {
        if (!this._viewer)  return;
        if (!context)       return;
        if (!viewport)      return;

        const MAX_RECURSION = VolumeOverlay._MAX_RECURSION;
        const viewer        = this._viewer;
        const displayView   = viewport.displayView;
        const validToken    = this._perViewport[displayView].validToken;

        // Build an event for onCalculate() callbacks
        const calculateEvent = this.buildEvent(viewport);

        // Build an event for onShouldShow() callbacks
        const showEvent = this.buildEvent(viewport, {
            planes: this.generatePlaneData(viewer)
        });

        function calculateRecursive(child, calcShown, level) {
            if (!child)  return;

            if (child.visible) {
                if (child._shown === null)
                    calcShown = true;

                // Resolve shown state
                if (calcShown)  child._shown = child.onShouldShow(showEvent);

                // Process children before the parent.
                // Calculations will bubble up from the leaf nodes, so that
                // parent overlays can use their children's calculations
                if (level < MAX_RECURSION) {
                    const children = child._children;
                    for (let i=0; i<children.length; ++i) {
                        const child = children[i];
                        calculateRecursive(child, calcShown, level+1);
                    }
                }

                // Now, for THIS overlay...
                const perViewport = child._perViewport;
                let valid = false;
                // STM_TODO - this needs some work
                if (perViewport && (displayView in perViewport) &&
                    !child.isInvalidated(validToken, displayView))
                    valid = true;

                // If our cache is invalid, recalculate
                if (!valid) {
                    let viewCalculations = child.onCalculate(calculateEvent);
                    perViewport[displayView].calculations = viewCalculations;
                    child.validate(validToken, displayView);
                }
            }
            else {
                child._shown = null;
            }
        }

        let calcShown = (this._shown === null);

        calculateRecursive(this, calcShown, 0);
    }

    // Performs rendering on the entire overlay hierarchy.
    renderHierarchy(context, viewport) {
        if (!this._viewer)  return;
        if (!context)       return;
        if (!viewport)      return;

        const MAX_RECURSION = VolumeOverlay._MAX_RECURSION;
        const EMPTY_OBJECT  = VolumeOverlay._EMPTY_OBJECT;
        const viewer        = this._viewer;
        const displayView   = viewport.displayView;

        // Build an event for onPreRender() and onPostRender() callbacks.
        // Note that renderHints will be updated and passed down through the
        // hierarchy based on additional calls to onBuildRenderHints().
        const event = this.buildEvent(viewport, {
            renderHints: EMPTY_OBJECT
        });

        let zIndices = [[],[]];

        // Because overlays can have different zIndex values, and because
        // this controls the draw order of the overlays, we need to:
        // a) build a list of all the zIndex values that are actually used
        // by overlays in the hierarchy, and
        // b) run through the entire hierarchy in multiple passes, one for each
        // zIndex value, and render the overlays associated with each
        // zIndex for each pass.
        //
        // Yeah, this is painful.  Why did I decide to support this again?

        // Build the list of zIndex values...
        function buildZIndices(child, isParentSelected, level) {
            if (!child)  return;

            // Invisible children are ignored
            if (child.visible) {
                let isChildSelected;

                // Selected and highlighted overlays get a higher priority
                if (child.isAggregate)
                    isChildSelected = child.descendantsSelected || child.descendantsHighlighted;
                else
                    isChildSelected = child.selected || child.highlighted;

                isParentSelected = isParentSelected || isChildSelected;
                let zIndex = child.zIndex;
                let offset = isParentSelected ? 1 : 0;
                if (zIndices[offset].indexOf(zIndex) < 0)
                    zIndices[offset].push(zIndex);

                if (level < MAX_RECURSION) {
                    const children = child._children;
                    for (let i=0; i<children.length; ++i) {
                        const child = children[i];
                        buildZIndices(child, isParentSelected, level+1);
                    }
                }
            }
        }

        // This method handles one rendering pass
        function renderRecursive(child, event, zIndex, drawSelected, isParentSelected, level) {
            if (!child)  return;

            if (child.visible && child._shown) {
                context.save(); try {
                    let viewCalculations = child.getCalculations(displayView);

                    // Is this an overlay we should render during this pass?
                    let isChildSelected;
                    if (child.isAggregate)
                        isChildSelected = child.descendantsSelected || child.descendantsHighlighted;
                    else
                        isChildSelected = child.selected || child.highlighted;
                    isParentSelected = isParentSelected || isChildSelected;
                    let shouldRender = drawSelected === isParentSelected;
                    if (shouldRender)
                        shouldRender = child.zIndex === zIndex;

                    // Call this before rendering children
                    if (shouldRender)
                        child.onPreRender(event, viewCalculations);

                    if (level < MAX_RECURSION) {
                        const children = child._children;
                        if (children.length > 0) {
                            let newEvent = event;

                            // Generate render hints for descendants of this overlay
                            let hints = child.onBuildRenderHints(event, viewCalculations);
                            if (hints && typeof hints === 'object') {
                                if (Object.keys(hints).length > 0) {
                                    let newHints = Object.freeze(Object.assign({}, event.renderHints, hints));
                                    newEvent = Object.freeze(Object.assign({}, newEvent, {renderHints: newHints}));
                                }
                            }

                            // Render all children recursively
                            for (let i=0; i<children.length; ++i) {
                                const child = children[i];
                                renderRecursive(child, newEvent, zIndex, drawSelected, isParentSelected, level+1);
                            }
                        }
                    }

                    // Call this after rendering children
                    if (shouldRender)
                        child.onPostRender(event, viewCalculations);

                    child._redraw = false;
                } finally { context.restore(); }
            }
        }

        // Build and sort the z indices
        buildZIndices(this, false, 0);
        zIndices[0].sort();
        zIndices[1].sort();

        // Make all of our rendering passes
        for (let s=0; s<=1; ++s) {
            let drawSelected = Boolean(s);
            for (let i=0; i<zIndices[s].length; ++i) {
                let zIndex = zIndices[s][i];
                renderRecursive(this, event, zIndex, drawSelected, false, 0);
            }
        }
    }

    // PUBLIC METHODS =========================================================

    // Finds the overlay that most closely matches the given screen coordinates.
    // Scans all descendants at or below the overlay from which the query is made.
    // If topLevel is specified, that overlay will be used as the root overlay
    // for the search instead.
    // Note that topLevel must be part of the same hierarchy.
    // The method returns the most closely matching overlay.
    // This method overrides the default method of the same name and provides
    // volume viewer information for coordinate conversion.
    findSelectableOverlay(pointX, pointY, filter=null, topLevel=null) {
        if (!this._viewer)  return null;
        if (!this._context) return null;

        if (filter && typeof filter !== 'function')  filter = null;

        if (!topLevel)  topLevel = this;

        // Make sure "root" is in our hierarchy
        let top = topLevel;
        while (top) {
            if (top === this)
                break;
            top = top._parent;
        }
        if (!top)  return null;

        const viewer  = this._viewer;
        const context = this._context;

        let bestSelection = null;

        // This method will be passed, during a findSelectableOverlay() query,
        // to any overlay that overrides the onQuerySelectable() method.
        // If a descendant overlay has information about a selectable item in
        // an overlay, it must call this function (via event.updateQuery()),
        // which will update the search and help this method find the
        // "best" overlay that will be returned by the query.
        function updateSelectionQuery(overlay, distance,
                                      priority=VolumeOverlay.PRIORITY_NORMAL,
                                      override=true) {
            if (!overlay)  return;

            const selectable = overlay.selectable;
            const selected   = overlay.isAggregate ? overlay.descendantsSelected : overlay.selected;
            const zIndex     = overlay.zIndex;
            const visible    = overlay.isShown();

            let update;

            // This logic determines the relative priority of the overlay.
            // "update" will be set to true if the overlay passed to this
            // method is a better match than the previously-matched overlay.
            // If the query comparison is equal in all other ways, "override"
            // will determine whether the earlier or later query wins.
            if      (!visible)                           update = false;
            else if (!selectable)                        update = false;
            else if (!bestSelection)                     update = true;
            else if (priority > bestSelection.priority)  update = true;
            else if (priority < bestSelection.priority)  update = false;
            else if (selected > bestSelection.selected)  update = true;
            else if (selected < bestSelection.selected)  update = false;
            else if (distance < bestSelection.distance)  update = true;
            else if (distance > bestSelection.distance)  update = false;
            else if (zIndex   > bestSelection.zIndex)    update = true;
            else if (zIndex   < bestSelection.zIndex)    update = false;
            else if (override)                           update = true;
            else                                         update = false;

            if (update) {
                bestSelection = {
                    priority: priority,
                    selected: selected,
                    distance: distance,
                    zIndex:   zIndex,
                    overlay:  overlay
                };
            }
        }

        const displayView = viewer.displayToView(pointX, pointY);
        const viewport    = viewer.getViewport(displayView);

        // Make sure all calculations are up-to-date and cached!!
        this.updateCalculations(displayView);

        // Build an event for the query
        const event = this.buildEvent(viewport, {
            screenPoint: Vmath.createVector(pointX, pointY),
            updateQuery: updateSelectionQuery
        });

        const MAX_RECURSION = VolumeOverlay._MAX_RECURSION;

        // Perform our recursive query.
        // Queries follow a bottom-to-top traversal order.
        function querySelectionRecursive(child, level) {
            if (!child)  return;

            if (child.visible && child._shown) {
                if (level < MAX_RECURSION) {
                    const children = child._children;
                    for (let i=0; i<children.length; ++i) {
                        const child = children[i];
                        querySelectionRecursive(child, level+1);
                    }
                }

                let viewCalculations = child.getCalculations(displayView);
                if (child.selectable) {
                    if (!filter || filter(child))
                        child.onQuerySelectable(event, viewCalculations);
                }
            }
        }

        // Iterate through the entire hierarchy, looking for the best match
        querySelectionRecursive(topLevel, 0);

        if (bestSelection)
            return bestSelection.overlay;

        // Return null if nothing matched
        return null;
    }

    // Selects the best matching overlay at the specified screen coordinate.
    // Returns the selected overlay, or null if none was found.
    selectAtPoint(pointX, pointY) {
        let selected = this.findSelectableOverlay(pointX, pointY);
        if (selected)
            selected.select();
        else
            this.unselectAll();
        return selected;
    }

    // Highlights the best matching overlay at the specified screen coordinate.
    // Returns the highlighted overlay, or null if none was found.
    highlightAtPoint(pointX, pointY) {
        let selected = this.findSelectableOverlay(pointX, pointY);
        if (selected)
            selected.highlight();
        else
            this.unhighlightAll();
        return selected;
    }

    // Top-level call to start a dragging operation on an overlay.
    // At the time of this call, we don't yet know which overlay will be
    // dragged.  Part of our job is to figure that out.
    // This method will invoke onStartDrag() for any controllers of the
    // drag target.
    startDrag(point, displayView) {
        // Conveniences
        const viewer   = this._viewer;
        const viewport = viewer.getViewport(displayView);

        // Look for a target based on the screen position at the start
        // of the drag...
        let target = this.findSelectableOverlay(point.x, point.y, c => c.isEditable());

        // Possible alternative code: if we can't find a target, and
        // we have a selected overlay, drag that.
        // Potentially dangerous or annoying, which is why this is commented
        // out for now.  :)
        //if (!target) {
        //    let selected = this.getSelected();
        //    if (selected.length > 0)
        //        target = selected[0];
        //}

        // No target found, so we can't drag
        if (!target)  return false;

        // Disallow dragging of non-editable targets.
        // (There is probably a better way to handle this...)
        if (!target.isEditable())  return false;

        // Build an event for onStartDrag() callbacks
        let facingPlane = viewer.getFacingPlane();
        const event = this.buildEvent(viewport, {
            target:      target,
            screenPoint: point,
            facingPlane: facingPlane
        });

        // Hacky code for tablets, which can't detect mouse moves.
        // Highlight the target overlay for the duration of the drag operation.
        if (!event.hasPrecision) {
            this.highlightAtPoint(point.x, point.y);
        }

        // Descend through the controllers, invoking onStartDrag() for each
        // one, until we find one that handles the event.  All controllers
        // up to and including that one will also be sent onDrag() and
        // onEndDrag() events as the drag progresses.
        let handled = false;
        let controllers = this.buildControllerList(target);
        let handlers = [];
        for (let i=0; i<controllers.length; ++i) {
            let handler = controllers[i];
            handlers.push(handler);
            if (handler.onStartDrag(event)) {
                handled = true;
                break;
            }
        }

        // None of the targets will accept responsibility.  Abort.
        if (!handled)
            return false;

        // Remember this stuff for later
        this._dragging = true;
        this._handlers = handlers;
        this._target   = target;
        this._dragView = displayView;
        this._dragFace = facingPlane;

        // We handled this event
        return true;
    }

    // Top-level call to continue a dragging operation on an overlay.
    // This method will invoke onDrag() for any controllers of the
    // drag target.
    drag(point) {
        // Conveniences
        const dragging    = this._dragging;
        const handlers    = this._handlers;
        const target      = this._target;
        const displayView = this._dragView;
        const facingPlane = this._dragFace;

        const viewer   = this._viewer;
        const viewport = viewer.getViewport(displayView);

        // Build an event for onDrag() callbacks
        const event = this.buildEvent(viewport, {
            target:      target,
            screenPoint: point,
            facingPlane: facingPlane
        });

        if (!dragging)  return false;

        // Call all of our handlers with an onDrag() event.
        // Note that higher-level controllers can process a drag
        // event and return true, which will prevent descendant handlers
        // from receiving it.
        for (let i=0; i<handlers.length; ++i) {
            let handler = handlers[i];
            if (handler.onDrag(event))
                return true;
        }

        // Nobody handled the drag
        return false;
    }

    // Top-level call to complete a dragging operation on an overlay.
    // This method will invoke onEndDrag() for any controllers of the
    // drag target.
    endDrag(point) {
        // Conveniences
        const dragging    = this._dragging;
        const handlers    = this._handlers;
        const target      = this._target;
        const displayView = this._dragView;
        const facingPlane = this._dragFace;

        const viewer   = this._viewer;
        const viewport = viewer.getViewport(displayView);

        // Build an event for onEndDrag() callbacks
        const event = this.buildEvent(viewport, {
            target:      target,
            screenPoint: point,
            facingPlane: facingPlane
        });

        // Clear out the dragging information we were hauling around
        this._dragging = false;
        this._handlers = null;
        this._target   = null;
        this._dragView = null;
        this._dragFace = null;

        if (!dragging)  return false;

        // On tablets, unhighlight the target overlay
        if (!event.hasPrecision)
            this.unhighlightAll();

        // Call all of our handlers with an onEndDrag() callback.
        for (let i=0; i<handlers.length; ++i) {
            let handler = handlers[i];
            handler.onEndDrag(event);
        }

        // We handled this event
        return true;
    }

    // Top-level call to handle a click operation on an overlay.
    // At the time of this call, we don't yet know which overlay will be
    // clicked.  Part of our job is to figure that out.
    // This method will invoke onClick() for any controllers of the
    // drag target.
    click(point) {
        const viewer   = this._viewer;
        const viewport = viewer.displayToView(point.x, point.y);

        // Look for a target based on the screen position where the click
        // occurred...
        let target = this.findSelectableOverlay(point.x, point.y);

        // No target was found -- just unselect everything and pass back
        // control to the caller.
        if (!target) {
            this.unselectAll();
            return false;
        }

        // Build an event for onClick() callbacks
        let facingPlane = viewer.getFacingPlane();
        const event = this.buildEvent(viewport, {
            target:      target,
            screenPoint: point,
            facingPlane: facingPlane
        });

        // Descend through the controllers, invoking onClick() for each
        // one, until we find one that handles the event.
        let handlers = this.buildControllerList(target);
        for (let i=0; i<handlers.length; ++i) {
            let handler = handlers[i];
            if (handler.onClick(event)) {
                return true;
            }
        }

        // Nobody handled the event, so our default action is to select
        // the target.
        target.select();

        // We handled it!
        return true;
    }

    // Handles keydown events on the overlay.
    // The target overlay that typically receives this event is the currently
    // highlighted or selected overlay in the viewer.
    // This method may be overridden by derived classes.
    keyDown(keyEvent) {
        const viewer   = this._viewer;
        const viewport = viewer.getViewport();

        // Build a list of potential targets for the keyboard event.
        // Although we currently only use the first one, we may eventually
        // process multiple targets.
        let items = this.buildTargetList();
        if (!items)  return false;  // no potential targets

        // Build an event for onKeyDown() callbacks
        const target = items.target;
        const event = this.buildEvent(viewport, {
            target:   target,
            keyEvent: keyEvent
        });

        // Descend through the controllers, invoking onKeyDown() for each
        // one, until we find one that handles the event.
        let handlers = this.buildControllerList(target);
        for (let i=0; i<handlers.length; ++i) {
            let handler = handlers[i];
            if (handler.onKeyDown(event)) {
                return true;
            }
        }

        // Nobody handled the key event
        return false;
    }

    // Performs all calculations and rendering for the overlay hierarchy
    render(passes) {
        if (!this._redraw)   return;
        if (!this._viewer)   return;
        if (!this._canvas)   return;
        if (!this._context)  return;

        const viewer  = this._viewer;
        const canvas  = this._canvas;
        const context = this._context;

        // We may be rendering to multiple viewports.  For example,
        // stereo mode uses two viewports.  Just to make this even MORE
        // complicated...

        // STM_TODO - use cache??
        if (!passes)  passes = viewer.generatePasses();
        if (!passes)  return;

        // First, perform ALL setup and coordinate calculation needed for overlays
        for (let i=0; i<passes.length; ++i)
        {
            const viewport = passes[i];
            this.calculateHierarchy(context, viewport);
        }

        // Clear the canvas
        context.clearRect(0, 0, canvas.width, canvas.height);

        // Now it's time to render the overlays
        for (let i=0; i<passes.length; ++i)
        {
            const viewport = passes[i];

            // STM_TODO - handle canvas size better
            context.save();  try {
                // Set up a clip area so we don't draw outside our viewport
                let scaleX = viewport.canvasWidth  / viewport.width;
                let scaleY = viewport.canvasHeight / viewport.height;
                let transX = -viewport.rectX * scaleX;
                let transY = -viewport.rectY * scaleY;

                // Set up a clipping region for this viewport, and clear it.
                // Overlays are not allowed to draw on any viewport except
                // the current one!
                context.clearRect(viewport.canvasX, viewport.canvasY, viewport.canvasWidth, viewport.canvasHeight);
                context.beginPath();
                context.rect(viewport.scissorX, viewport.scissorY+1, viewport.scissorWidth, viewport.scissorHeight);
                context.clip();
                context.setTransform(scaleX, 0, 0, scaleY, transX, transY);

                // Render the overlays for this viewport
                this.renderHierarchy(context, viewport);
            }
            finally { context.restore(); }
        }
    }

    // OVERRIDEABLE METHODS ===================================================

    // Returns the unique name of the overlay type.
    // Used when serializing and deserializing properties.
    // This method must be overridden by derived classes.
    get primitive() {
        return "root";
    }

    // Invoked when a redraw is requested.
    // This method may be overridden by derived classes.
    onRequestRedraw() {
        if (this._animationId === null) {
            const root = this;
            this._animationId = requestAnimationFrame(function() {
                root._animationId = null;
                root.render();
            });
        }
    }
}
// Register this primitive
VolumeOverlay.registerPrimitive(VolumeOverlayRoot);


// ============================================================================
// PRIMITIVES
// ============================================================================

// ============================================================================
// VolumeOverlayGroup
// ============================================================================

// This overlay represents a simple group of other overlays.
// Doesn't do much besides hold them.
export class VolumeOverlayGroup extends VolumeOverlay {
    // Constructor for the overlay class.
    constructor(parent, options) {
        super();

        if (options)  this.setProperties(options);
        if (parent)   parent.addChild(this);
    }

    // OVERRIDEABLE METHODS ===================================================

    // Returns the unique name of the overlay type.
    // Used when serializing and deserializing properties.
    // This method must be overridden by derived classes.
    get primitive() {
        return "group";
    }
}
// Register this primitive
VolumeOverlay.registerPrimitive(VolumeOverlayGroup);


// ============================================================================
// VolumeOverlayDisplayGroup
// ============================================================================

// This overlay is a container for other overlays.
// It also provides default colors, line sizes, fonts, etc. for
// all descendant overlays inside the group, though the renderHints
// mechanism.
// These may be overridden by the descendant overlays themselves,
// or by descendant display groups.
// This overlay type provides a single location where most overlay
// properties may be set.
export class VolumeOverlayDisplayGroup extends VolumeOverlayGroup {
    // Constructor for the overlay class.
    constructor(parent, options) {
        super();

        if (options)  this.setProperties(options);
        if (parent)   parent.addChild(this);
    }

    // Properties list for this class.
    // These properties will automatically be serialized and deserialized
    // via the getProperties() and setProperties() methods, respectively.
    // This list is also used to initialize the properties in the
    // constructor.
    static propertyList() {
        return {
            pointColor:          null,
            pointHighlightColor: null,
            pointSelectColor:    null,
            pointRadius:         null,

            lineColor:           null,
            lineHighlightColor:  null,
            lineSelectColor:     null,
            lineWidth:           null,
            lineHighlightWidth:  null,
            lineSelectWidth:     null,

            textFont:            null,
            textColor:           null
        };
    }

    // PUBLIC PROPERTIES ======================================================

    // Property representing the default color of points for descendants of
    // this overlay.
    get pointColor() {
        return this._pointColor;
    }
    set pointColor(color) {
        color = VolumeOverlay.buildColorString(color);
        if (this._pointColor !== color) {
            this._pointColor = color;

            this.requestRedraw();
        }
    }

    // Property representing the default color of highlighted points for
    // descendants of this overlay.
    get pointHighlightColor() {
        return this._pointHighlightColor;
    }
    set pointHighlightColor(color) {
        color = VolumeOverlay.buildColorString(color);
        if (this._pointHighlightColor !== color) {
            this._pointHighlightColor = color;

            this.requestRedraw();
        }
    }

    // Property representing the default color of selected points for
    // descendants of this overlay.
    get pointSelectColor() {
        return this._pointSelectColor;
    }
    set pointSelectColor(color) {
        color = VolumeOverlay.buildColorString(color);
        if (this._pointSelectColor !== color) {
            this._pointSelectColor = color;

            this.requestRedraw();
        }
    }

    // Property representing the default size/radius of points for
    // descendants of this overlay.
    get pointRadius() {
        return this._pointRadius;
    }
    set pointRadius(radius) {
        if (radius !== null && typeof radius === 'number')
            if (radius < 0.0)  radius = 0.0;

        if (this._pointRadius !== radius) {
            this._pointRadius = radius;

            this.invalidate();  // ugly
        }
    }

    // Property representing the default color of lines for descendants
    // of this overlay.
    get lineColor() {
        return this._lineColor;
    }
    set lineColor(color) {
        color = VolumeOverlay.buildColorString(color);
        if (this._lineColor !== color) {
            this._lineColor = color;

            this.requestRedraw();
        }
    }

    // Property representing the default color of highlighted lines for
    // descendants of this overlay.
    get lineHighlightColor() {
        return this._lineHighlightColor;
    }
    set lineHighlightColor(color) {
        color = VolumeOverlay.buildColorString(color);
        if (this._lineHighlightColor !== color) {
            this._lineHighlightColor = color;

            this.requestRedraw();
        }
    }

    // Property representing the default color of selected lines for
    // descendants of this overlay.
    get lineSelectColor() {
        return this._lineSelectColor;
    }
    set lineSelectColor(color) {
        color = VolumeOverlay.buildColorString(color);
        if (this._lineSelectColor !== color) {
            this._lineSelectColor = color;

            this.requestRedraw();
        }
    }

    // Property representing the default width of lines for descendants
    // of this overlay.
    get lineWidth() {
        return this._lineWidth;
    }
    set lineWidth(lineWidth) {
        if (this._lineWidth !== lineWidth) {
            this._lineWidth = lineWidth;

            this.requestRedraw();
        }
    }

    // Property representing the default width of highlighted lines for
    // descendants of this overlay.
    get lineHighlightWidth() {
        return this._lineHighlightWidth;
    }
    set lineHighlightWidth(lineWidth) {
        if (this._lineHighlightWidth !== lineWidth) {
            this._lineHighlightWidth = lineWidth;

            this.requestRedraw();
        }
    }

    // Property representing the default width of selected lines for
    // descendants of this overlay.
    get lineSelectWidth() {
        return this._lineSelectWidth;
    }
    set lineSelectWidth(lineWidth) {
        if (this._lineSelectWidth !== lineWidth) {
            this._lineSelectWidth = lineWidth;

            this.requestRedraw();
        }
    }

    // Property representing the default text font used by descendants
    // of this overlay.
    get textFont() {
        return this._textFont;
    }
    set textFont(font) {
        if (!font)  font = null;
        if (font && typeof font !== 'string')  return;

        if (this._textFont !== font) {
            this._textFont = font;

            this.requestRedraw();
        }
    }

    // Property representing the default color of text for descendants
    // of this overlay.
    get textColor() {
        return this._textColor;
    }
    set textColor(color) {
        color = VolumeOverlay.buildColorString(color);
        if (this._textColor !== color) {
            this._textColor = color;

            this.requestRedraw();
        }
    }

    // OVERRIDEABLE METHODS ===================================================

    // Returns the unique name of the overlay type.
    // Used when serializing and deserializing properties.
    // This method must be overridden by derived classes.
    get primitive() {
        return "display-group";
    }

    // Builds an object containing rendering hints.
    // These hints are passed to rendered descendant overlays and may be
    // used to modify the appearance of those overlays.
    // As implied by the name, these are hints only, and are not enforced.
    // This method may be overridden by derived classes.
    onBuildRenderHints(event, calculations) {
        let pointColor          = this._pointColor || undefined;
        let pointHighlightColor = this._pointHighlightColor || undefined;
        let pointSelectColor    = this._pointSelectColor || undefined;
        let pointRadius         = this._pointRadius !== null ? this._pointRadius : undefined;

        let lineColor           = this._lineColor || undefined;
        let lineHighlightColor  = this._lineHighlightColor || undefined;
        let lineSelectColor     = this._lineSelectColor || undefined;
        let lineWidth           = this._lineWidth !== null ? this._lineWidth : undefined;
        let lineHighlightWidth  = this._lineHighlightWidth !== null ? this._lineHighlightWidth : undefined;
        let lineSelectWidth     = this._lineSelectWidth !== null ? this._lineSelectWidth : undefined;

        let textFont            = this._textFont !== null ? this._textFont : undefined;
        let textColor           = this._textColor !== null ? this._textColor : undefined;

        return {
            pointColor:          pointColor,
            pointHighlightColor: pointHighlightColor,
            pointSelectColor:    pointSelectColor,
            pointRadius:         pointRadius,

            lineColor:           lineColor,
            lineHighlightColor:  lineHighlightColor,
            lineSelectColor:     lineSelectColor,
            lineWidth:           lineWidth,
            lineHighlightWidth:  lineHighlightWidth,
            lineSelectWidth:     lineSelectWidth,

            textFont:            textFont,
            textColor:           textColor
        };
    }
}
// Register this primitive
VolumeOverlay.registerPrimitive(VolumeOverlayDisplayGroup);


// ============================================================================
// VolumeOverlayBorders
// ============================================================================

// This overlay draws borders around the planes in MPR or 2D viewing mode.
// Primarily used for debugging.
export class VolumeOverlayBorders extends VolumeOverlay {

    // OVERRIDEABLE METHODS ===================================================

    // Returns the unique name of the overlay type.
    // Used when serializing and deserializing properties.
    // This method must be overridden by derived classes.
    get primitive() {
        return "borders";
    }

    // Draws the contents of the overlay, after the children have been rendered.
    // This method may be overridden by derived classes.
    onPostRender(event, calculations) {
        const viewer      = event.viewer;
        const context     = event.context;
        const renderHints = event.renderHints;
        const displayView = event.viewport.displayView;

        if (!viewer.canShowPlanes())  return;

        let planes = [1, 2, 3];
        if (viewer.isAutoPlaneEnabled())
            planes = [viewer.getFacingPlane()];

        for (let i=0; i<planes.length; ++i) {
            const plane = planes[i];
            //let boundary = viewer.generatePlaneBorders(plane);
            let boundary = viewer.generateFaceBorders(plane, true);
            if (boundary.length >= 2) {
                for (let i=0; i<boundary.length; ++i) {
                    let point = boundary[i];
                    let screen = viewer.pointToDisplay(point.x, point.y, point.z, displayView);
                    boundary[i] = screen;
                }

                context.strokeStyle = VolumeOverlay.inherit(
                    renderHints.borderColor,
                    "#0040FF"
                );

                context.lineWidth = VolumeOverlay.inherit(
                    renderHints.lineWidth,
                    1.5
                );

                context.beginPath();
                context.moveTo(boundary[0].x, boundary[0].y);
                for (let i=1; i<boundary.length; ++i) {
                    context.lineTo(boundary[i].x, boundary[i].y);
                }
                context.closePath();
                context.stroke();
            }
        }
    }
};
// Register this primitive
VolumeOverlay.registerPrimitive(VolumeOverlayBorders);


// ============================================================================
// VolumeOverlayPlaneCrosshair
// ============================================================================

// This overlay draws a crosshair based on the plane offset in the volume
// viewer.
// It should not be instantiated by any class other than the volume viewer.
export class VolumeOverlayPlaneCrosshair extends VolumeOverlay {
    // Constructor for the overlay class.
    constructor(parent, options) {
        super();

        if (options)  this.setProperties(options);
        if (parent)   parent.addChild(this);
    }

    // Properties list for this class.
    // These properties will automatically be serialized and deserialized
    // via the getProperties() and setProperties() methods, respectively.
    // This list is also used to initialize the properties in the
    // constructor.
    static propertyList() {
        return {
            color:         null,
            lineWidth:     null,
            innerDistance: null,
            outerDistance: null
        };
    }

    // PUBLIC PROPERTIES ======================================================

    // Property representing the color of the crosshair.
    // This may be a string, an array of colors, or a color object.
    get color() {
        return this._color;
    }
    set color(color) {
        color = VolumeOverlay.buildColorString(color);
        if (this._color !== color) {
            this._color = color;

            this.requestRedraw();
        }
    }

    // Property representing the line width of the crosshairs.
    get lineWidth() {
        return this._lineWidth;
    }
    set lineWidth(lineWidth) {
        if (lineWidth !== null && typeof lineWidth === 'number')
            if (lineWidth < 0.5)  lineWidth = 0.5;

        if (this._lineWidth !== lineWidth) {
            this._lineWidth = lineWidth;

            this.requestRedraw();
        }
    }

    // Property representing the distance from the center to the start of
    // the crosshair line, in pixels.
    get innerDistance() {
        return this._innerDistance;
    }
    set innerDistance(dist) {
        if (dist !== null && typeof dist === 'number')
            if (dist < 0.0)  dist = 0.0;

        if (this._innerDistance !== dist) {
            this._innerDistance = dist;

            this.requestRedraw();
        }
    }

    // Property representing the distance from the center to the end of
    // the crosshair line, in pixels.
    get outerDistance() {
        return this._outerDistance;
    }
    set outerDistance(dist) {
        if (dist !== null && typeof dist === 'number')
            if (dist < 0.0)  dist = 0.0;

        if (this._outerDistance !== dist) {
            this._outerDistance = dist;

            this.requestRedraw();
        }
    }

    // OVERRIDEABLE METHODS ===================================================

    // Returns the unique name of the overlay type.
    // Used when serializing and deserializing properties.
    // This method must be overridden by derived classes.
    get primitive() {
        return "plane-crosshair";
    }

    // Draws the contents of the overlay, after the children have been rendered.
    // This method may be overridden by derived classes.
    onPostRender(event, calculations) {
        const viewer      = event.viewer;
        const context     = event.context;
        const renderHints = event.renderHints;
        const displayView = event.viewport.displayView;

        if (!viewer.canShowPlanes())  return;

        let planePoint = viewer.getPlaneOffset();
        planePoint = viewer.convertToVolumeOffset(planePoint);

        let screenPoint = viewer.pointToDisplay(planePoint.x, planePoint.y, planePoint.z, displayView);
        if (!screenPoint)  return;

        context.strokeStyle = VolumeOverlay.inherit(
            this._color,
            renderHints.lineColor,
            "yellow"
        );
        context.lineWidth = VolumeOverlay.inherit(
            this._lineWidth,
            renderHints.lineWidth,
            2
        );
        let inner = VolumeOverlay.inherit(
            this._innerDistance,
            12
        );
        let outer = VolumeOverlay.inherit(
            this._outerDistance,
            24
        );

        context.shadowBlur  = 2;
        context.shadowColor = "black";

        context.beginPath();
        context.moveTo(screenPoint.x, screenPoint.y-outer);
        context.lineTo(screenPoint.x, screenPoint.y-inner);
        context.moveTo(screenPoint.x, screenPoint.y+outer);
        context.lineTo(screenPoint.x, screenPoint.y+inner);
        context.moveTo(screenPoint.x-outer, screenPoint.y);
        context.lineTo(screenPoint.x-inner, screenPoint.y);
        context.moveTo(screenPoint.x+outer, screenPoint.y);
        context.lineTo(screenPoint.x+inner, screenPoint.y);
        context.stroke();
    }
}
// Register this primitive
VolumeOverlay.registerPrimitive(VolumeOverlayPlaneCrosshair);


// ============================================================================
// VolumeOverlayPlaneLine
// ============================================================================

export class VolumeOverlayPlaneLine extends VolumeOverlay {
    // Constructor for the overlay class.
    constructor(parent, options) {
        super();

        this._dragPoint    = null;

        if (options)  this.setProperties(options);
        if (parent)   parent.addChild(this);
    }

    // Properties list for this class.
    // These properties will automatically be serialized and deserialized
    // via the getProperties() and setProperties() methods, respectively.
    // This list is also used to initialize the properties in the
    // constructor.
    static propertyList() {
        return {
            selectable:   true,
            lineColor:    null,
            lineWidth:    null,
            closePath:    false,
            canDragGroup: true,
            plane:        null
        };
    }

    // PRIVATE METHODS ========================================================

    // Convenience method.
    // Converts a screen point to a volume point, based on the intersection
    // of the screen point with the currently facing plane.
    // If planes are not visible, this method cannot convert screen points
    // and will return null.
    convertScreenPointToVolume(event, screenPoint) {
        let newPoint;
        let plane = this._plane;
        if (plane) {
            newPoint = event.viewer.getLinePlaneIntersection(screenPoint.x, screenPoint.y,
                                                             plane.point, plane.normal, false);
            if (newPoint) {
                newPoint = newPoint.point;
                newPoint = event.viewer.clampPointToPlane(newPoint, plane.point, plane.normal);
            }
        }
        else {
            newPoint = super.convertScreenPointToVolume(event, screenPoint);
        }
        return newPoint;
    }

    // Simplifies a line by culling points unnecessary points.
    simplify(event, startIndex=undefined, endIndex=undefined) {
        const displayView = event.displayView;

        let points = this.findChildren(c => c instanceof VolumeOverlayPoint && c.isVisible());

        // Sanity checks
        if (startIndex === undefined || startIndex === null)
            startIndex = 0;
        if (endIndex === undefined || endIndex === null)
            endIndex = points.length-1;
        if (startIndex < 0)
            startIndex = 0;
        if (endIndex > points.length-1)
            endIndex = points.length-1;

        let positions = [];
        for (let i=startIndex; i<=endIndex; ++i) {
            const point = points[i];
            let calc = point.getCalculations(displayView);

            if (calc)  positions.push(calc.screenPoint);
            else       positions.push(null);  // what else can we do?
        }

        const MAX_OFFSET  = 1.5;
        const MAX_OFFSET2 = MAX_OFFSET*MAX_OFFSET;

        // Douglas-Peucker, do your thing!
        function simplifySegment(subStart, subEnd) {
            if (subStart+1 >= subEnd)
                return;
            let startPoint = positions[subStart];
            let endPoint   = positions[subEnd];
            let bestDist  = null;
            let bestIndex = null;
            for (let i=subStart+1; i<subEnd; ++i) {
                let position = positions[i];
                if (position) {
                    let dist = Vmath.distanceToLineSegmentSquared(position, startPoint, endPoint);
                    if (dist >= MAX_OFFSET2) {
                        if (bestDist === null || bestDist < dist) {
                            bestDist  = dist;
                            bestIndex = i;
                        }
                    }
                }
            }
            if (bestIndex !== null) {
                // Recurse
                simplifySegment(subStart, bestIndex);
                simplifySegment(bestIndex, subEnd);
            }
            else {
                // Wipe out intermediate points
                for (let i=subStart+1; i<subEnd; ++i) {
                    const index = i + startIndex;
                    let point = points[index];
                    points[index] = null;
                    point.destroy();
                }
            }
        }

        // A little hacky...
        let subEnd   = 0;
        let subStart = positions.length;
        for (let i=0; i<positions.length; ++i) {
            let position = positions[i];
            if (position !== null) {
                if (subStart > i)  subStart = i;
                if (subEnd < i)    subEnd   = i;
            }
        }

        simplifySegment(subStart, subEnd);
    }

    // Gets the ideal viewing plane for the line.
    getViewingPlane(viewer) {
        const plane = this._plane;

        // Look for visible child points
        const children = this.findDescendants(c => c instanceof VolumeOverlayPoint &&
                                                   c.isVisible() && c.point);

        // Fail if there are none
        if (children.length <= 0)
            return null;

        // If this line has an associated plane, our job is easy...
        if (plane) {
            // Return the viewing plane based on the plane property.
            return {
                point:  plane.point,
                normal: plane.normal
            };
        }
        else {
            // No plane.  We gotta do this the hard way.

            let bestPoint  = null;
            let bestLine   = null;
            let bestNormal = null;

            // This algorithm will look for two vectors in the polyline that
            // are not collinear.  If it can find them, it can use the cross
            // product of the two vectors to generate a normal.  If it can't,
            // things get nastier.
            const EPSILON = 1e-7;
            let pos = 0;
            while (pos < children.length-1) {
                let child0 = children[pos];
                bestPoint = child0.point;  // found at least one point!
                let child1 = children[pos+1];
                let delta0 = Vmath.delta(child1.point, child0.point);
                if (Vmath.lengthSquared(delta0) >= EPSILON) {
                    bestLine = delta0;  // found at least one line!
                    break;
                }
                ++pos;
            }

            ++pos;
            while (pos < children.length-1) {
                let child2 = children[pos];
                let child3 = children[pos+1];
                let delta1 = Vmath.delta(child3.point, child2.point);
                if (Vmath.lengthSquared(delta1) >= EPSILON) {
                    let normal = Vmath.cross(bestLine, delta1);
                    if (Vmath.lengthSquared(normal) >= EPSILON) {
                        bestNormal = Vmath.normalize(normal);  // found a normal! Done!
                        break;
                    }
                }
                ++pos;
            }

            if (bestNormal !== null) {
                // We have three points, and therefore, a planar normal!
                return {
                    point:  bestPoint,
                    normal: bestNormal
                };
            }

            if (bestLine !== null) {
                // We have two points.  As there are an infinite number of
                // potential planes on which this line might reside, pick
                // the best plane (of three), as defined by being the
                // "most coplanar" with the line (i.e. having the dot
                // product closest to zero).  Build in a slight bias
                // for the plane facing us.
                let facingPlane = viewer.getFacingPlane();
                facingPlane = Math.abs(facingPlane) - 1;

                let planeMatrix = viewer.getPlaneMatrix();
                let planeVectors = Vmath.vectorsFromMatrix(planeMatrix);
                let bestDot = null, bestPlaneNormal = null;
                let planes = [facingPlane, (facingPlane+2)%3, (facingPlane+1)%2];
                for (let i=0; i<planes.length; ++i) {
                    let planeNormal = planeVectors[planes[i]];
                    let dot = Vmath.dot(planeNormal, bestLine);
                    if (bestDot === null || Math.abs(bestDot) > Math.abs(dot)) {
                        bestDot = dot;
                        bestPlaneNormal = planeNormal;
                    }
                }

                if (bestPlaneNormal !== null) {
                    // We have a "best" plane.
                    // Now make sure the plane normal is orthogonal
                    // to the line!
                    let cross1 = Vmath.cross(bestLine, bestPlaneNormal);
                    let cross2 = Vmath.cross(cross1, bestLine);
                    cross2 = Vmath.normalize(cross2);
                    return {
                        point:  bestPoint,
                        normal: cross2
                    };
                }
                return null;
            }

            if (bestPoint !== null) {
                // We have one point.  Normals here are irrelevant --
                // just use the normal of the plane most closely facing us.
                let facingPlane = viewer.getFacingPlane();
                let planeNormal = viewer.getPlaneNormal(facingPlane);
                return {
                    point:  bestPoint,
                    normal: planeNormal.normal
                };
            }

            // We have failed.
            return null;
        }
    }

    // Translates all descendants by the specified delta.
    // The delta must be in volume space.
    translateDescendants(delta) {
        if (this.canDragGroup) {
            const descendants = this.findDescendants(c => c instanceof VolumeOverlayPoint && c.isVisible());
            for (let i=0; i<descendants.length; ++i) {
                let child = descendants[i];
                if (child) {
                    let point = child.point;
                    child.point = Vmath.add(point, delta);
                }
            }
        }
    }

    // PUBLIC PROPERTIES ======================================================

    // Property representing the color of lines for this overlay.
    get lineColor() {
        return this._lineColor;
    }
    set lineColor(lineColor) {
        lineColor = VolumeOverlay.buildColorString(lineColor);
        if (this._lineColor !== lineColor) {
            this._lineColor = lineColor;

            this.requestRedraw();
        }
    }

    // Property representing the width of lines for this overlay.
    get lineWidth() {
        return this._lineWidth;
    }
    set lineWidth(lineWidth) {
        if (lineWidth !== null && typeof lineWidth === 'number')
            if (lineWidth < 0.5)  lineWidth = 0.5;

        if (this._lineWidth !== lineWidth) {
            this._lineWidth = lineWidth;

            this.requestRedraw();
        }
    }

    // Property representing whether the path for this line should be closed
    // (that is, whether its endpoints should be connected, forming a loop).
    get closePath() {
        return this._closePath;
    }
    set closePath(closePath) {
        closePath = Boolean(closePath);

        if (this._closePath !== closePath) {
            this._closePath = closePath;

            this.invalidate();
        }
    }

    // Property representing whether the entire line can be dragged as a group.
    get canDragGroup() {
        return this._canDragGroup;
    }
    set canDragGroup(enable) {
        this._canDragGroup = Boolean(enable);
    }

    // Property representing the plane to which the points on this line
    // will be limited.
    // If this property is null, points are not limited to a plane and may
    // be anywhere in the volume, but this does make it more difficult to
    // edit points through dragging or clicking.
    get plane() {
        return VolumeOverlay.deepCopy(this._plane);
    }
    set plane(plane) {
        if (typeof plane !== 'object')  return;

        // STM_TODO - eventually, use "d" instead of "point"
        // (but leave "point" in the setter for compatibility)
        let point  = plane ? plane.point : null;
        let normal = plane ? plane.normal : null;

        if (point) {
            if (Vmath.isVector3(point))
                point = Vmath.copyVector(point);
            else
                point = null;
        }
        if (normal) {
            if (Vmath.isVector3(normal))
                normal = Vmath.copy(normal);
            else
                normal = null;
        }

        plane = (point && normal) ? { point: point, normal: normal } : null;

        if (!VolumeOverlay.deepEquals(this._plane, plane)) {
            this._plane = plane;

            // Nothing else to do here for now...
        }
    }

    // OVERRIDEABLE METHODS ===================================================

    // Returns the unique name of the overlay type.
    // Used when serializing and deserializing properties.
    // This method must be overridden by derived classes.
    get primitive() {
        return "line";
    }

    // Returns true if the overlay is an aggregate -- that is, if it owns
    // and is responsible for controlling all of its descendants.
    // When the child of an aggregate overlay is selected or highlighted,
    // the entire aggregate hierarchy will be drawn on top of other overlays.
    // All mouse and keyboard events will also go to the aggregate, which is
    // responsible for propagating the event to the actual selected overlay.
    // (The aggregate and the selected overlay may be the same object.)
    // This method may be overridden by derived classes.
    get isAggregate() {
        return true;
    }

    // Invoked when somebody has requested that the overlay show itself.
    // This method is responsible for updating the volume viewer to
    // an appropriate orientation, zoom level, etc. in order to show the
    // overlay.
    // This method may be overridden by derived classes.
    onRequestShow(event) {
        // STM_TODO - this algorithm is a hot mess.  Refactor.
        const viewer = event.viewer;

        let plane = this.getViewingPlane(viewer);
        if (!plane)  return;

        let point  = plane.point;
        let normal = plane.normal;

        let bestAxis;
        let bestDot;

        // Determines whether an axis closely matches the one we pass in
        function orientAxis(axis, dir, row, abs=true) {
            let dot = Vmath.dot(dir, row);
            if (abs) {
                if (bestAxis === null || Math.abs(bestDot) < Math.abs(dot)) {
                    bestAxis = axis;
                    bestDot  = dot;
                }
            }
            else {
                if (bestAxis === null || bestDot < dot) {
                    bestAxis = axis;
                    bestDot  = dot;
                }
            }
        }

        // Attempts to generate a rotation matrix from a normal,
        // matching the provided reference matrix as closely as possible.
        function generateMatrix(normal, matchMatrix) {
            normal = Vmath.copy(normal);

            let newMatrix = Vmath.vectorsFromMatrix(Vmath.createIdentityMatrix3());

            let scale;

            // Find the axis that most closely matches the normal vector
            bestAxis = null; bestDot = null;
            orientAxis(0, normal, matchMatrix[0]);
            orientAxis(1, normal, matchMatrix[1]);
            orientAxis(2, normal, matchMatrix[2]);
            if (bestAxis === null || bestDot === null)  return;

            let axis0 = bestAxis;
            let axis1 = (axis0+1) % 3;
            let axis2 = (axis1+1) % 3;

            scale = bestDot < 0.0 ? -1.0 : 1.0;
            let absNormal = Vmath.scale(normal, scale);
            absNormal = Vmath.normalize(absNormal);
            newMatrix[axis0] = Vmath.copy(absNormal);

            // Look for the second axis, which must be orthogonal to the first
            let cross1 = Vmath.copy(matchMatrix[axis1]);
            let cross2 = Vmath.cross(absNormal, cross1);

            // Find the axis that most closely matches the second vector
            bestAxis = null; bestDot = null;
            orientAxis(axis1, cross2, matchMatrix[axis1]);
            orientAxis(axis2, cross2, matchMatrix[axis2]);
            if (bestAxis === null || bestDot === null)  return;

            if (bestAxis === axis2) {
                let temp = axis2;
                axis2    = axis1;
                axis1    = temp;
            }

            //scale = bestDot < 0.0 ? -1.0 : 1.0;
            //cross2 = Vmath.scale(cross2, scale);
            cross2 = Vmath.normalize(cross2);
            newMatrix[axis1] = cross2;

            // Only one vector left...
            cross1 = Vmath.cross(cross2, absNormal);

            bestAxis = null; bestDot = null;
            orientAxis(axis2, cross1, matchMatrix[axis2]);
            if (bestAxis === null || bestDot === null)  return;

            //scale = bestDot < 0.0 ? -1.0 : 1.0;
            //cross1 = Vmath.scale(cross1, scale);
            cross1 = Vmath.normalize(cross1);
            newMatrix[axis2] = cross1;

            // This is a hack...
            newMatrix.push([axis0, axis1, axis2]);

            // Return the new matrix
            return newMatrix;
        }

        // Build a plane matrix that is as close to the default plane matrix
        // as possible.
        let planeMatrix = Vmath.vectorsFromMatrix(Vmath.createIdentityMatrix3());

        let newPlaneMatrix = generateMatrix(normal, planeMatrix);
        if (!newPlaneMatrix)  return;

        let planeAxes = newPlaneMatrix[3];  // hack!
        let axis = planeAxes[0];

        let curPlaneOffset = viewer.getPlaneOffset();
        let curVolOffset = viewer.convertToVolumeOffset(curPlaneOffset);

        // Generate a plane offset in the direction of the plane representing
        // the normal, but leave the two orthogonal offsets alone.
        let planeOffset = curPlaneOffset;
        let dot = Vmath.dot(newPlaneMatrix[axis], point);
        if (axis === 0) { planeOffset.px = dot; }
        if (axis === 1) { planeOffset.py = dot; }
        if (axis === 2) { planeOffset.pz = dot; }

        // We have a new plane matrix!
        newPlaneMatrix = Vmath.matrixFromVectors(newPlaneMatrix);

        // Now we do the same for the rotation matrix...
        let curRotationMatrix = viewer.getRotationMatrix();

        // Find the axis that most closely matches the normal vector
        bestAxis = null; bestDot = null;
        orientAxis(0, normal, { x: curRotationMatrix.m00, y: curRotationMatrix.m10, z: curRotationMatrix.m20 });
        orientAxis(1, normal, { x: curRotationMatrix.m01, y: curRotationMatrix.m11, z: curRotationMatrix.m21 });
        orientAxis(2, normal, { x: curRotationMatrix.m02, y: curRotationMatrix.m12, z: curRotationMatrix.m22 });
        if (bestAxis === null || bestDot === null)  return;

        // Is it the one facing us?
        let majorRotation = false;
        let rotationMatrix;
        if (bestAxis === 2) {
            // Yes.  Preserve the other two axes if possible.
            rotationMatrix = curRotationMatrix;
        }
        else {
            // No.  We are free to hose the other two axes.

            // Look for the standard orientation that most closely matches this one
            majorRotation = true;
            bestAxis = null; bestDot = null;
            for (let i=1; i<=6; ++i) {
                let orientation = VolumeViewer.getOrientationFromFace(i);
                let idealMatrix = viewer.generateRotationFromOrientation(orientation);
                orientAxis(i, normal, { x: idealMatrix.m02, y: idealMatrix.m12, z: idealMatrix.m22 }, false);
            }
            if (bestAxis === null || bestDot === null)  return;

            let orientation = VolumeViewer.getOrientationFromFace(bestAxis);
            let idealMatrix = viewer.generateRotationFromOrientation(orientation);
            rotationMatrix = idealMatrix;
        }

        rotationMatrix = [
            { x: rotationMatrix.m00, y: rotationMatrix.m10, z: rotationMatrix.m20 },
            { x: rotationMatrix.m01, y: rotationMatrix.m11, z: rotationMatrix.m21 },
            { x: rotationMatrix.m02, y: rotationMatrix.m12, z: rotationMatrix.m22 }
        ];

        let newRotationMatrix = generateMatrix(normal, rotationMatrix);
        let axes = newRotationMatrix[3];

        // Build a new plane offset specifically for auto-plane mode
        let dot0 = Vmath.dot(newRotationMatrix[axes[0]], point);
        let dot1 = Vmath.dot(newRotationMatrix[axes[1]], curVolOffset);
        let dot2 = Vmath.dot(newRotationMatrix[axes[2]], curVolOffset);

        if (majorRotation)
            dot1 = dot2 = 0.0;

        let tempOffset = Vmath.createVector(dot2, dot1, dot0);

        // Generate the plane offset
        let tempRotationMatrix = Vmath.matrixFromVectors(newRotationMatrix[axes[2]], newRotationMatrix[axes[1]], newRotationMatrix[axes[0]]);
        newRotationMatrix = Vmath.transpose(tempRotationMatrix);
        let rotationOffset = Vmath.transform(tempOffset, tempRotationMatrix);
        rotationOffset = { px: rotationOffset.x, py: rotationOffset.y, pz: rotationOffset.z };

        // Finally, set the new state!
        if (!viewer.canShowPlanes() || !viewer.isAutoPlaneEnabled()) {
            let propertyState = {
                PlaneMatrix: newPlaneMatrix,
                PlaneOffset: planeOffset,
                RotationMatrix: newRotationMatrix
            };
            viewer.animateToPropertyState(propertyState, null, true);
        }
        else {
            let propertyState = {
                PlaneOffset: rotationOffset,
                RotationMatrix: newRotationMatrix
            };
            viewer.animateToPropertyState(propertyState, null, true);
        }
    }

    // Builds an object containing rendering hints.
    // These hints are passed to rendered descendant overlays and may be
    // used to modify the appearance of those overlays.
    // As implied by the name, these are hints only, and are not enforced.
    // This method may be overridden by derived classes.
    onBuildRenderHints(event, calculations) {
        let hidePoints, selected, highlighted, showHighlighted;

        hidePoints = event.renderHints.hidePoints;
        let pointCount = calculations.screenPoints.length;
        if (pointCount > 2)
            hidePoints = true;
        if (this.descendantsSelected)
            selected = true;
        if (this.descendantsHighlighted)
            highlighted = true;
        if (hidePoints)
            showHighlighted = this.isEditable();

        return {
            hidePoints:           hidePoints,
            ancestorsSelected:    selected,
            ancestorsHighlighted: highlighted,
            showHighlightedPoint: showHighlighted
        };
    }

    // Starts a dragging operation on the overlay.
    // Information related to the volume viewer and the current context
    // is provided in the form of an event object.
    // This method may be overridden by derived classes.
    onStartDrag(event) {
        const viewer      = event.viewer;
        const screenPoint = event.screenPoint;
        const target      = event.target;

        // Eliminate cases where we can't drag
        if (this._plane === null && !viewer.canShowPlanes())
            return false;
        if (target === this && !this.canDragGroup)
            return false;

        let newPoint = this.convertScreenPointToVolume(event, screenPoint);
        if (newPoint) {
            this._dragPoint = newPoint;

            return true;
        }

        return true;
    }

    // Continues a dragging operation on the overlay.
    // This event will only be received by this overlay if the onStartDrag()
    // method was called earlier for this overlay.  Naked drag events should
    // never occur.
    // This method may be overridden by derived classes.
    onDrag(event) {
        const screenPoint = event.screenPoint;
        const target      = event.target;

        let newPoint = this.convertScreenPointToVolume(event, screenPoint);
        if (newPoint) {
            if (target instanceof VolumeOverlayPlaneLine) {
                let oldPoint = this._dragPoint;
                this._dragPoint = newPoint;

                let delta = Vmath.delta(newPoint, oldPoint);

                target.translateDescendants(delta);

                return true;
            }
            else if (target instanceof VolumeOverlayPoint) {
                target.point = newPoint;
                return true;
            }
        }

        return false;
    }

    // Completes a dragging operation on the overlay.
    // This event will only be received by this overlay if the onStartDrag()
    // method was called earlier for this overlay.  Naked drag events should
    // never occur.
    // This method may be overridden by derived classes.
    onEndDrag(event) {
        this._dragPoint = null;

        return false;
    }

    // Handles keydown events on the overlay.
    // The target overlay that typically receives this event is the currently
    // highlighted or selected overlay in the viewer.
    // This method may be overridden by derived classes.
    onKeyDown(event) {
        const target   = event.target;
        const keyEvent = event.keyEvent;

        const keyCode  = (keyEvent.which || keyEvent.keyCode || 0);
        const ctrlKey  = (keyEvent.ctrlKey || 0);
        const shiftKey = (keyEvent.shiftKey || 0);
        const altKey   = (keyEvent.altKey || 0);

        if (keyCode === 46 || keyCode === 8) {  // delete, backspace
            if (target === this) {
                this.destroy();
                return true;
            }
            else if (target instanceof VolumeOverlayPoint) {
                target.destroy();
                if (this._children.length === 0)
                    this.destroy();
                return true;
            }
            this.destroy();
            return true;
        }

        return false;
    }

    // Returns true if the overlay should be drawn, or false otherwise.
    // This implicitly affects all descendants of the overlay as well.
    // This method may be overridden by derived classes.
    onShouldShow(event) {
        const viewer      = event.viewer;
        const displayView = event.displayView;
        const planes      = event.planes;

        let shouldShow = false;
        if (viewer.canShowPlanes() && viewer.isAutoPlaneEnabled()) {
            for (let i=0; i<planes.length; ++i) {
                const plane = planes[i];

                const planePoint     = plane.point;
                const planeNormal    = plane.normal;
                const planeThickness = plane.thickness;

                let minDist = null;
                let maxDist = null;

                function addToBounds(point) {
                    let dist = Vmath.distanceToPlane(point, planePoint, planeNormal);
                    if (minDist === null || minDist > dist)  minDist = dist;
                    if (maxDist === null || maxDist < dist)  maxDist = dist;
                }

                const descendants = this.findDescendants(c => c instanceof VolumeOverlayPoint && c.isVisible());
                for (let i=0; i<descendants.length; ++i) {
                    const child = descendants[i];
                    if (child) {
                        let pointValue = child.point;
                        if (pointValue)
                            addToBounds(pointValue);
                    }
                }

                let delta = maxDist - minDist;
                if (delta <= planeThickness && minDist <= planeThickness*0.5 && maxDist > -planeThickness*0.5) {
                    shouldShow = true;
                    break;
                }
            }
        }
        else {
            shouldShow = true;
        }

        return shouldShow;
    }

    // Performs expensive calculations associated with a specific viewport.
    // Results will be cached and re-used when possible.
    // Note that a single volume viewer may render to more than one viewport
    // during a render pass!
    // This method may be overridden by derived classes.
    onCalculate(event) {
        const viewer      = event.viewer;
        const viewport    = event.viewport;
        const displayView = event.displayView;

        let rightmost = null;  // STM_TODO - formalize this and make it better
        let length = 0.0;
        let prevPoint = null;

        let screenPoints = [];

        const children = this.findDescendants(c => c instanceof VolumeOverlayPoint && c.isVisible());
        for (let i=0; i<children.length; ++i) {
            const child = children[i];
            if (child) {
                if (child.parent === this) {
                    let curPoint = viewer.convertToMetricOffset(child.point);
                    if (prevPoint) {
                        let distX = curPoint.mx - prevPoint.mx;
                        let distY = curPoint.my - prevPoint.my;
                        let distZ = curPoint.mz - prevPoint.mz;
                        let dist = Math.sqrt(distX*distX + distY*distY + distZ*distZ);
                        length += dist;
                    }
                    prevPoint = curPoint;

                    let viewCalculations = child.getCalculations(displayView);
                    if (viewCalculations.screenPoint) {
                        screenPoints.push(viewCalculations.screenPoint);
                        let screenPoint = viewCalculations.screenPoint;
                        if (rightmost === null || rightmost.x < screenPoint.x) {
                            rightmost = screenPoint;
                        }
                    }
                }
            }
        }

        let path = null;
        if (screenPoints.length > 0) {
            path = new Path2D();
            path.moveTo(screenPoints[0].x, screenPoints[0].y);
            for (let i=1; i<screenPoints.length; ++i)
                path.lineTo(screenPoints[i].x, screenPoints[i].y);
            if (this._closePath)
                path.closePath();
        }

        return {
            screenPoints: screenPoints,
            linePath:     path,
            length:       length,
            rightmost:    rightmost
        };
    }

    // Determines whether the specified screen position is "part of" the overlay.
    // Criteria for this determination varies from overlay to overlay.
    // Invoked from the findSelectableOverlay() method.
    // This method may be overridden by derived classes.
    onQuerySelectable(event, calculations) {
        const hasPrecision = event.hasPrecision;

        if (calculations.screenPoints) {
            const MAX_DISTANCE  = hasPrecision ? 16.0 : 28.0;
            const MAX_DISTANCE2 = MAX_DISTANCE * MAX_DISTANCE;

            const screenPoint     = event.screenPoint;
            const childPoints     = calculations.screenPoints;
            const childPointCount = childPoints.length;
            for (let i=0; i<childPointCount; ++i) {
                if (i === childPointCount-1 && !this._closePath)
                    break;
                let line0 = childPoints[i];
                let line1 = childPoints[(i+1)%childPointCount];
                let distance2 = Vmath.distanceToLineSegmentSquared(screenPoint, line0, line1);

                if (distance2 <= MAX_DISTANCE2)
                    event.updateQuery(this, distance2, VolumeOverlay.PRIORITY_MEDIUM);
            }
        }
    }

    // Draws the contents of the overlay, prior to the children being rendered.
    // This method may be overridden by derived classes.
    onPreRender(event, calculations) {
        const context     = event.context;
        const renderHints = event.renderHints;
        const path        = calculations.linePath;
        const length      = calculations.length;

        let highlighted = this.descendantsHighlighted || renderHints.ancestorsHighlighted;
        let selected    = this.descendantsSelected || renderHints.ancestorsSelected;

        if (path) {
            context.strokeStyle = VolumeOverlay.inherit(
                selected    ? renderHints.lineSelectColor : null,
                highlighted ? renderHints.lineHighlightColor : null,
                this._lineColor,
                renderHints.lineColor,
                "yellow"
            );
            context.lineWidth = VolumeOverlay.inherit(
                selected    ? renderHints.lineSelectWidth : null,
                highlighted ? renderHints.lineHighlightWidth : null,
                this._lineWidth,
                renderHints.lineWidth,
                1
            );

            context.lineJoin  = "round";

            if (selected) {
                context.shadowBlur  = 2;
                context.shadowBlur  = 1;
                context.shadowColor = "black";
            }
            else if (highlighted) {
                context.shadowBlur  = 2;
                context.shadowBlur  = 1;
                context.shadowColor = "black";
            }

            context.stroke(path);
        }
    }
}
// Register this primitive
VolumeOverlay.registerPrimitive(VolumeOverlayPlaneLine);


// ============================================================================
// VolumeOverlayMeasuredLine
// ============================================================================

// This overlay draws a line between two points, and provides a measurement
// as a nearby text block when the line is highlighted or selected.
export class VolumeOverlayMeasuredLine extends VolumeOverlayPlaneLine {
    // Constructor for the overlay class.
    constructor(parent, options) {
        super();

        if (options)  this.setProperties(options);
        if (parent)   parent.addChild(this);
    }

    // Properties list for this class.
    // These properties will automatically be serialized and deserialized
    // via the getProperties() and setProperties() methods, respectively.
    // This list is also used to initialize the properties in the
    // constructor.
    static propertyList() {
        return {
            textFont:  null,
            textColor: null
        }
    }

    // PUBLIC PROPERTIES ======================================================

    // Property representing the text font used to draw measurements.
    get textFont() {
        return this._textFont;
    }
    set textFont(font) {
        if (!font)  font = null;
        if (font && typeof font !== 'string')  return;

        if (this._textFont !== font) {
            this._textFont = font;

            this.requestRedraw();
        }
    }

    // Property representing the text color used to draw measurements.
    get textColor() {
        return this._textColor;
    }
    set textColor(color) {
        color = VolumeOverlay.buildColorString(color);
        if (this._textColor !== color) {
            this._textColor = color;

            this.requestRedraw();
        }
    }

    // OVERRIDEABLE METHODS ===================================================

    // Returns the unique name of the overlay type.
    // Used when serializing and deserializing properties.
    // This method must be overridden by derived classes.
    get primitive() {
        return "measured-line";
    }

    // Draws the contents of the overlay, after the children have been rendered.
    // This method may be overridden by derived classes.
    onPostRender(event, calculations) {
        super.onPostRender(event, calculations);

        const context     = event.context;
        const path        = calculations.linePath;
        const length      = calculations.length;
        const renderHints = event.renderHints;

        let highlighted = this.descendantsHighlighted || renderHints.ancestorsHighlighted;
        let selected    = this.descendantsSelected || renderHints.ancestorsSelected;

        if (path) {
            if (selected || highlighted) {
                context.font = VolumeOverlay.inherit(
                    this._textFont,
                    renderHints.textFont,
                    VolumeOverlay.DEFAULT_FONT
                );

                context.fillStyle = VolumeOverlay.inherit(
                    this._textColor,
                    renderHints.textColor,
                    "white"
                );

                context.strokeStyle = "#00000080";
                context.lineJoin    = "round";
                context.shadowBlur  = 0;

                let text = `${length.toFixed(2)} mm`;

                const x = calculations.rightmost.x+16;
                const y = calculations.rightmost.y;
                context.strokeText(text, x, y);
                context.fillText(text, x, y);
            }
        }
    }
}
// Register this primitive
VolumeOverlay.registerPrimitive(VolumeOverlayMeasuredLine);


// ============================================================================
// VolumeOverlayPoint
// ============================================================================

export class VolumeOverlayPoint extends VolumeOverlay {
    // Constructor for the overlay class.
    constructor(parent, options) {
        super();

        this._dragging    = false;

        if (options)  this.setProperties(options);
        if (parent)   parent.addChild(this);
    }

    // Properties list for this class.
    // These properties will automatically be serialized and deserialized
    // via the getProperties() and setProperties() methods, respectively.
    // This list is also used to initialize the properties in the
    // constructor.
    static propertyList() {
        return {
            selectable:  true,
            point:       null,
            pointColor:  null,
            pointRadius: null
        };
    }

    // PUBLIC PROPERTIES ======================================================

    // Property representing the position of this overlay point.
    get point() {
        return VolumeOverlay.deepCopy(this._point);
    }
    set point(point) {
        if (!point && !this._point)  return;

        if (point) {
            if (!Vmath.isVector3(point)) {
                return;
            }
        }

        if (!VolumeOverlay.deepEquals(this._point, point)) {
            this._point = VolumeOverlay.deepCopy(point);

            this.invalidate();
        }
    }

    // Property representing the color of this overlay point.
    get pointColor() {
        return this._pointColor;
    }
    set pointColor(pointColor) {
        pointColor = VolumeOverlay.buildColorString(pointColor);
        if (this._pointColor !== pointColor) {
            this._pointColor = pointColor;

            this.requestRedraw();
        }
    }

    // Property representing the radius of this overlay point, in pixels.
    get pointRadius() {
        return this._pointRadius;
    }
    set pointRadius(radius) {
        if (radius !== null && typeof radius === 'number')
            if (radius < 0.0)  radius = 0.0;

        if (this._pointRadius !== radius) {
            this._pointRadius = radius;

            this.invalidate();
        }
    }

    // OVERRIDEABLE METHODS ===================================================

    // Returns the unique name of the overlay type.
    // Used when serializing and deserializing properties.
    // This method must be overridden by derived classes.
    get primitive() {
        return "point";
    }

    // Returns true if the overlay is an aggregate -- that is, if it owns
    // and is responsible for controlling all of its descendants.
    // When the child of an aggregate overlay is selected or highlighted,
    // the entire aggregate hierarchy will be drawn on top of other overlays.
    // All mouse and keyboard events will also go to the aggregate, which is
    // responsible for propagating the event to the actual selected overlay.
    // (The aggregate and the selected overlay may be the same object.)
    // This method may be overridden by derived classes.
    get isAggregate() {
        return true;
    }

    // Invoked when somebody has requested that the overlay show itself.
    // This method is responsible for updating the volume viewer to
    // an appropriate orientation, zoom level, etc. in order to show the
    // overlay.
    // This method may be overridden by derived classes.
    onRequestShow(event) {
        const viewer = event.viewer;

        // No need to change anything if we're not showing planes
        if (!viewer.canShowPlanes())  return;

        const point = this._point;

        // This code will set the plane offset so that the facing plane
        // will be showing the point.  It will attempt to leave the other
        // two planes alone.
        // We don't care about changing the rotation matrix, because
        // these are one-dimensional points and the in-plane orientation
        // won't matter.
        if (!viewer.isAutoPlaneEnabled()) {
            // MPR mode
            let facingPlane = viewer.getFacingPlane();

            let planeOffset = viewer.getPlaneOffset();
            let planeMatrix = viewer.getPlaneMatrix();
            let planeVectors = Vmath.vectorsFromMatrix(planeMatrix);
            let axis = Math.abs(facingPlane) - 1;

            let dot = Vmath.dot(planeVectors[axis], point);
            if (axis === 0) { planeOffset.px = dot; }
            if (axis === 1) { planeOffset.py = dot; }
            if (axis === 2) { planeOffset.pz = dot; }

            let propertyState = {
                PlaneOffset: planeOffset
            };
            viewer.animateToPropertyState(propertyState, null, true);
        }
        else {
            // 2D mode
            let planeOffset  = viewer.getPlaneOffset();
            let volumeOffset = viewer.convertToVolumeOffset(planeOffset);

            let rotationMatrix  = viewer.getRotationMatrix();
            rotationMatrix      = Vmath.transpose(rotationMatrix);
            let rotationVectors = Vmath.vectorsFromMatrix(rotationMatrix);

            let dot0 = Vmath.dot(rotationVectors[0], volumeOffset);
            let dot1 = Vmath.dot(rotationVectors[1], volumeOffset);
            let dot2 = Vmath.dot(rotationVectors[2], point);

            let tempOffset = Vmath.createVector(dot0, dot1, dot2);
            let tempRotationMatrix = Vmath.matrixFromVectors(rotationVectors);
            tempOffset = Vmath.transform(tempOffset, tempRotationMatrix);

            planeOffset = { px: tempOffset.x, py: tempOffset.y, pz: tempOffset.z };

            let propertyState = {
                PlaneOffset: planeOffset
            };
            viewer.animateToPropertyState(propertyState, null, true);
        }
    }

    // Starts a dragging operation on the overlay.
    // Information related to the volume viewer and the current context
    // is provided in the form of an event object.
    // This method may be overridden by derived classes.
    onStartDrag(event) {
        const viewer = event.viewer;
        const target = event.target;

        // Eliminate cases where we can't drag
        if (target === this && !viewer.canShowPlanes())
            return false;

        return true;
    }

    // Continues a dragging operation on the overlay.
    // This event will only be received by this overlay if the onStartDrag()
    // method was called earlier for this overlay.  Naked drag events should
    // never occur.
    // This method may be overridden by derived classes.
    onDrag(event) {
        const screenPoint = event.screenPoint;

        let newPoint = this.convertScreenPointToVolume(event, screenPoint);
        if (newPoint) {
            this.point = newPoint;

            return true;
        }

        return false;
    }

    // Completes a dragging operation on the overlay.
    // This event will only be received by this overlay if the onStartDrag()
    // method was called earlier for this overlay.  Naked drag events should
    // never occur.
    // This method may be overridden by derived classes.
    onEndDrag(event) {
        return false;
    }

    // Handles a single-click or tablet touch event on the overlay.
    // This method may be overridden by derived classes.
    onClick(event) {
        return false;
    }

    // Handles keydown events on the overlay.
    // The target overlay that typically receives this event is the currently
    // highlighted or selected overlay in the viewer.
    // This method may be overridden by derived classes.
    onKeyDown(event) {
        const keyEvent = event.keyEvent;

        const keyCode  = (keyEvent.which || keyEvent.keyCode || 0);
        const ctrlKey  = (keyEvent.ctrlKey || 0);
        const shiftKey = (keyEvent.shiftKey || 0);
        const altKey   = (keyEvent.altKey || 0);

        if (keyCode === 46 || keyCode === 8) {  // delete, backspace
            this.destroy();
            return true;
        }

        return false;
    }

    // Returns true if the overlay should be drawn, or false otherwise.
    // This implicitly affects all descendants of the overlay as well.
    // This method may be overridden by derived classes.
    onShouldShow(event) {
        const viewer      = event.viewer;
        const displayView = event.displayView;
        const planes      = event.planes;

        // Only draw this point if it is inside a viewing plane
        let shouldShow = false;
        if (viewer.canShowPlanes() && viewer.isAutoPlaneEnabled()) {
            const point = this._point;

            for (let i=0; i<planes.length; ++i) {
                const plane = planes[i];

                const planePoint     = plane.point;
                const planeNormal    = plane.normal;
                const planeThickness = plane.thickness;

                let dist = Vmath.distanceToPlane(point, planePoint, planeNormal);
                if (dist <= planeThickness*0.5 && dist > -planeThickness*0.5) {
                    shouldShow = true;
                    break;
                }
            }
        }
        else {
            shouldShow = true;
        }

        return shouldShow;
    }

    // Performs expensive calculations associated with a specific viewport.
    // Results will be cached and re-used when possible.
    // Note that a single volume viewer may render to more than one viewport
    // during a render pass!
    // This method may be overridden by derived classes.
    onCalculate(event) {
        const viewer      = event.viewer;
        const viewport    = event.viewport;
        const displayView = viewport.displayView;

        let point = this._point;
        if (point) {
            point = viewer.convertToVolumeOffset(point);
            point = viewer.pointToDisplay(point.x, point.y, point.z, displayView);
        }
        else {
            point = null;
        }

        return {
            screenPoint: point
        };
    }

    // Determines whether the specified screen position is "part of" the overlay.
    // Criteria for this determination varies from overlay to overlay.
    // Invoked from the findSelectableOverlay() method.
    // This method may be overridden by derived classes.
    onQuerySelectable(event, calculations) {
        const hasPrecision = event.hasPrecision;

        if (calculations.screenPoint) {
            const MAX_DISTANCE  = hasPrecision ? 8.0 : 18.0;
            const MAX_DISTANCE2 = MAX_DISTANCE * MAX_DISTANCE;

            const overlayPoint = calculations.screenPoint;
            const screenPoint  = event.screenPoint;

            let distance2 = Vmath.distanceSquared(overlayPoint, screenPoint);

            if (distance2 <= MAX_DISTANCE2)
                event.updateQuery(this, distance2, VolumeOverlay.PRIORITY_HIGH);
        }
    }

    // Draws the contents of the overlay, after the children have been rendered.
    // This method may be overridden by derived classes.
    onPostRender(event, calculations) {
        const context     = event.context;
        const renderHints = event.renderHints;
        const path        = calculations.pointPath;
        const screenPoint = calculations.screenPoint;

        // Ancestor overlays may want us hidden so we don't clutter
        // the screen.
        // Determine whether we should be hidden or not, based on
        // render hints.
        let hidePoint = Boolean(event.renderHints.hidePoints);
        if (hidePoint) {
            if (event.renderHints.showFirstPoint) {
                if (this.prevSibling() === null)
                    hidePoint = false;
            }
            if (event.renderHints.showLastPoint) {
                if (this.nextSibling() === null)
                    hidePoint = false;
            }
            if (event.renderHints.showHighlightedPoint) {
                if (this.highlighted || this.selected)
                    hidePoint = false;
            }
        }

        // Okay, now draw!
        if (this._point && !hidePoint) {
            let highlighted = this.descendantsHighlighted;
            let selected    = this.descendantsSelected;

            context.strokeStyle = VolumeOverlay.inherit(
                selected    ? renderHints.pointSelectColor : null,
                highlighted ? renderHints.pointHighlightColor : null,
                this._pointColor,
                renderHints.pointColor,
                "#FF4040"
            );

            if (selected) {
                context.lineWidth   = 2.5;
                context.shadowBlur  = 1;
                context.shadowColor = "black";
            }
            else if (highlighted) {
                context.lineWidth   = 2.5;
                context.shadowBlur  = 1;
                context.shadowColor = "black";
            }
            else {
                context.lineWidth   = 1.5;
            }

            let pointRadius = VolumeOverlay.inherit(
                this._pointRadius,
                renderHints.pointRadius,
                7
            );

            context.beginPath();
            context.arc(screenPoint.x, screenPoint.y, pointRadius, 0, Math.PI*2, true);

            context.stroke();
        }
    }
};
// Register this primitive
VolumeOverlay.registerPrimitive(VolumeOverlayPoint);


// ============================================================================
// WIZARDS
// ============================================================================

// ============================================================================
// VolumeOverlayWizard
// ============================================================================

export class VolumeOverlayWizard extends VolumeOverlay {
    // Constructor for the overlay class.
    constructor(parent, options) {
        super();

        if (options)  this.setProperties(options);
        if (parent)   parent.addChild(this);
    }

    // Properties list for this class.
    // These properties will automatically be serialized and deserialized
    // via the getProperties() and setProperties() methods, respectively.
    // This list is also used to initialize the properties in the
    // constructor.
    static propertyList() {
        return {
            selectable: true
        };
    };

    // PRIVATE METHODS ========================================================

    // Transfers selection state from this wizard to the specified child.
    transferSelection(child) {
        if (this.highlighted)
            child.highlight();
        if (this.selected)
            child.select();
    }

    // PUBLIC METHODS =========================================================

    // Finalizes the overlay that the wizard was building.
    // Child overlays are all moved up one level, and the wizard itself
    // is destroyed.
    finalize() {
        this.unselectAll();
        this.unhighlightAll();

        let parent   = this.parent;
        let children = this.children;
        for (let i=children.length-1; i>=0; --i) {
            let child = children[i];
            parent.addChild(child, this);
        }
        this.destroy();
    }

    // Cancels the wizard by making it destroy itself.
    // This implicitly destroys the child overlays as well.
    cancel() {
        this.destroy();
    }

    // OVERRIDEABLE METHODS ===================================================

    // Returns the unique name of the overlay type.
    // Used when serializing and deserializing properties.
    // This method must be overridden by derived classes.
    get primitive() {
        return "wizard";
    }

    // Returns true if the overlay is an aggregate -- that is, if it owns
    // and is responsible for controlling all of its descendants.
    // When the child of an aggregate overlay is selected or highlighted,
    // the entire aggregate hierarchy will be drawn on top of other overlays.
    // All mouse and keyboard events will also go to the aggregate, which is
    // responsible for propagating the event to the actual selected overlay.
    // (The aggregate and the selected overlay may be the same object.)
    // This method may be overridden by derived classes.
    get isAggregate() {
        return true;
    }

    // Determines whether the specified screen position is "part of" the overlay.
    // Criteria for this determination varies from overlay to overlay.
    // Invoked from the findSelectableOverlay() method.
    // This method may be overridden by derived classes.
    onQuerySelectable(event, calculations) {
        // This is the way we intercept all keyboard and mouse commands:
        // we give ourselves a higher priority than any other overlays!
        event.updateQuery(this, 0.0, VolumeOverlay.PRIORITY_OVERRIDE, false);
    }

    // Handles keydown events on the overlay.
    // The target overlay that typically receives this event is the currently
    // highlighted or selected overlay in the viewer.
    // This method may be overridden by derived classes.
    onKeyDown(event) {
        const keyEvent = event.keyEvent;

        const keyCode  = (keyEvent.which || keyEvent.keyCode || 0);
        const ctrlKey  = (keyEvent.ctrlKey || 0);
        const shiftKey = (keyEvent.shiftKey || 0);
        const altKey   = (keyEvent.altKey || 0);

        if (keyCode === 27) {  // escape
            this.cancel();
            return true;
        }
        if (keyCode === 13) {  // return
            this.finalize();
            return true;
        }

        return false;
    }
}
// Register this primitive
VolumeOverlay.registerPrimitive(VolumeOverlayWizard);


// ============================================================================
// VolumeOverlayPlaneLineWizard
// ============================================================================

export class VolumeOverlayPlaneLineWizard extends VolumeOverlayWizard {
    // Constructor for the overlay class.
    constructor(parent, options) {
        super();

        this._dragging        = false;

        if (options)  this.setProperties(options);
        if (parent)   parent.addChild(this);
    }

    // Properties list for this class.
    // These properties will automatically be serialized and deserialized
    // via the getProperties() and setProperties() methods, respectively.
    // This list is also used to initialize the properties in the
    // constructor.
    static propertyList() {
        return {
            linePrimitive:   null,
            lineProperties:  null,
            pointPrimitive:  null,
            pointProperties: null
        };
    }

    // PRIVATE METHODS ========================================================

    // Creates a line as a child of the wizard, if the line doesn't already
    // exist.
    createLine(options=null) {
        let linePrimitive = this.linePrimitive;
        if (options || this._lineProperties)
            options = Object.assign({}, this._lineProperties, options);
        let line = this.findChild(c => c.primitive === linePrimitive && c.isVisible());
        if (!line)  line = VolumeOverlay.constructPrimitive(linePrimitive, this, options);
        return line;
    }

    // Sets the plane associated with the line.
    setLinePlane(line, viewer, facingPlane) {
        let plane = line.plane;
        if (!plane)  line.plane = viewer.getPlaneNormal(facingPlane);
    }

    // Creates a point as a child of the wizard's line primitive.
    createPoint(options=null) {
        let pointPrimitive = this.pointPrimitive;
        if (options || this._pointProperties)
            options = Object.assign({}, this._pointProperties, options);
        let line = this.createLine();
        if (!line)  return null;
        let point = VolumeOverlay.constructPrimitive(pointPrimitive, line, options);
        return point;
    }

    // Gets all visible child points associated with the wizard's line primitive.
    // These must match the point primitive type used by the wizard.
    getPoints(line=null) {
        let pointPrimitive = this.pointPrimitive;
        let points = [];
        if (!line)  line = this.createLine();
        if (line)  points = line.findChildren(c => c.primitive === pointPrimitive && c.isVisible());
        return points;
    }

    // PUBLIC PROPERTIES ======================================================

    // Property representing a named line primitive type.
    // When the wizard creates a new line, it will build the primitive based
    // on this type.
    get linePrimitive() {
        if (this._linePrimitive)
            return this._linePrimitive;
        else
            return VolumeOverlayPlaneLine.prototype.primitive;
    }
    set linePrimitive(primitive) {
        if (primitive && typeof primitive === 'function')
            primitive = primitive.prototype.primitive;
        if (primitive && typeof primitive === 'string')
            this._linePrimitive = primitive;
        else
            this._linePrimitive = null;
    }

    // Property representing an object of primitive line type properties.
    // When the wizard creates a new line, it will build the primitive based
    // on these properties.
    get lineProperties() {
        return VolumeOverlay.deepCopy(this._lineProperties);
    }
    set lineProperties(properties) {
        if (properties && typeof properties === 'object') {
            this._lineProperties = VolumeOverlay.deepCopy(properties);
        }
        else {
            this._lineProperties = null;
        }
    }

    // Property representing a named point primitive type.
    // When the wizard creates a new point, it will build the primitive based
    // on this type.
    get pointPrimitive() {
        if (this._pointPrimitive)
            return this._pointPrimitive;
        else
            return VolumeOverlayPoint.prototype.primitive;
    }
    set pointPrimitive(primitive) {
        if (primitive && typeof primitive === 'function')
            primitive = primitive.prototype.primitive;
        if (primitive && typeof primitive === 'string')
            this._pointPrimitive = primitive;
        else
            this._pointPrimitive = null;
    }

    // Property representing an object of primitive point type properties.
    // When the wizard creates a new point, it will build the primitive based
    // on these properties.
    get pointProperties() {
        return VolumeOverlay.deepCopy(this._pointProperties);
    }
    set pointProperties(properties) {
        if (properties && typeof properties === 'object') {
            this._pointProperties = VolumeOverlay.deepCopy(properties);
        }
        else {
            this._pointProperties = null;
        }
    }

    // OVERRIDEABLE METHODS ===================================================

    // Returns the unique name of the overlay type.
    // Used when serializing and deserializing properties.
    // This method must be overridden by derived classes.
    get primitive() {
        return "wizard-plane-line";
    }

    // Starts a dragging operation on the overlay.
    // Information related to the volume viewer and the current context
    // is provided in the form of an event object.
    // This method may be overridden by derived classes.
    onStartDrag(event) {
        const viewer      = event.viewer;
        const screenPoint = event.screenPoint;
        const facingPlane = event.facingPlane;

        let line = this.createLine();
        let points = this.getPoints();
        if (points.length < 2) {
            this.setLinePlane(line, viewer, facingPlane);
            let newPoint = line.convertScreenPointToVolume(event, screenPoint);
            if (newPoint) {
                let options = { point: newPoint };
                if (points.length <= 0)  points.push(this.createPoint(options));
                if (points.length <= 1)  points.push(this.createPoint(options));

                let child1 = points[1];

                this.transferSelection(child1);

                this._dragging = true;

                return true;
            }
        }

        return false;
    }

    // Continues a dragging operation on the overlay.
    // This event will only be received by this overlay if the onStartDrag()
    // method was called earlier for this overlay.  Naked drag events should
    // never occur.
    // This method may be overridden by derived classes.
    onDrag(event) {
        const viewer      = event.viewer;
        const screenPoint = event.screenPoint;
        const facingPlane = event.facingPlane;

        let line = this.createLine();
        let points = this.getPoints();
        if (points.length >= 2) {
            let child1 = points[1];

            this.transferSelection(child1);

            let newPoint = line.convertScreenPointToVolume(event, screenPoint);
            if (newPoint) {
                child1.point = newPoint;

                return true;
            }
        }
        return true;
    }

    // Completes a dragging operation on the overlay.
    // This event will only be received by this overlay if the onStartDrag()
    // method was called earlier for this overlay.  Naked drag events should
    // never occur.
    // This method may be overridden by derived classes.
    onEndDrag(event) {
        this._dragging = false;

        let points = this.getPoints();
        if (points.length >= 2)
            this.finalize();

        return true;
    }

    // Handles a single-click or tablet touch event on the overlay.
    // This method may be overridden by derived classes.
    onClick(event) {
        const viewer      = event.viewer;
        const screenPoint = event.screenPoint;
        const facingPlane = event.facingPlane;

        let line   = this.createLine();
        let points = this.getPoints();
        if (points.length >= 2) {
            this.finalize();
            return true;
        }

        let newPoint = line.convertScreenPointToVolume(event, screenPoint);
        if (newPoint) {
            this.setLinePlane(line, viewer, facingPlane);
            let plane = line.plane;
            if (plane)
                newPoint = viewer.clampPointToPlane(newPoint, plane.point, plane.normal);

            if (points.length === 0) {
                let child = this.createPoint({ point: newPoint });
                this.transferSelection(child);
            }
            else if (points.length === 1) {
                let child = this.createPoint({ point: newPoint });
                this.transferSelection(child);
                this.finalize();
            }
            else {
                this.finalize();
            }
        }

        return true;
    }
}
// Register this primitive
VolumeOverlay.registerPrimitive(VolumeOverlayPlaneLineWizard);


// ============================================================================
// VolumeOverlayMeasuredLineWizard
// ============================================================================

export class VolumeOverlayMeasuredLineWizard extends VolumeOverlayPlaneLineWizard {
    // Constructor for the overlay class.
    constructor(parent, options) {
        super();

        if (options)  this.setProperties(options);
        if (parent)   parent.addChild(this);
    }

    // Properties list for this class.
    // These properties will automatically be serialized and deserialized
    // via the getProperties() and setProperties() methods, respectively.
    // This list is also used to initialize the properties in the
    // constructor.
    static propertyList() {
        return {
            linePrimitive:   VolumeOverlayMeasuredLine.prototype.primitive,
            lineProperties:  null,
            pointPrimitive:  null,
            pointProperties: null
        };
    }

    // OVERRIDEABLE METHODS ===================================================

    // Returns the unique name of the overlay type.
    // Used when serializing and deserializing properties.
    // This method must be overridden by derived classes.
    get primitive() {
        return "wizard-measured-line";
    }
}
// Register this primitive
VolumeOverlay.registerPrimitive(VolumeOverlayMeasuredLineWizard);


// ============================================================================
// VolumeOverlayFreehandLineWizard
// ============================================================================

export class VolumeOverlayFreehandLineWizard extends VolumeOverlayPlaneLineWizard {
    // Constructor for the overlay class.
    constructor(parent, options) {
        super();

        this._dragging        = false;

        this._startDragIndex  = null;

        if (options)  this.setProperties(options);
        if (parent)   parent.addChild(this);
    }

    // OVERRIDEABLE METHODS ===================================================

    // Returns the unique name of the overlay type.
    // Used when serializing and deserializing properties.
    // This method must be overridden by derived classes.
    get primitive() {
        return "wizard-freehand-line";
    }

    // Builds an object containing rendering hints.
    // These hints are passed to rendered descendant overlays and may be
    // used to modify the appearance of those overlays.
    // As implied by the name, these are hints only, and are not enforced.
    // This method may be overridden by derived classes.
    onBuildRenderHints(event, calculations) {
        // This wizard has its own style.  Ignore any render hints
        // from our ancestors.
        let selected    = this.descendantsSelected;
        let highlighted = this.descendantsHighlighted;
        return {
            pointRadius:          7,
            pointColor:           "#C0C0FF",
            pointSelectColor:     "#C0C0FF",
            lineSelectWidth:      1.5,
            ancestorsSelected:    selected,
            ancesrorsHighlighted: highlighted,
            hidePoints:           true,
            showHighlightedPoint: true,
            showFirstPoint:       true,
            showLastPoint:        true
        };
    }

    // Starts a dragging operation on the overlay.
    // Information related to the volume viewer and the current context
    // is provided in the form of an event object.
    // This method may be overridden by derived classes.
    onStartDrag(event) {
        const viewer      = event.viewer;
        const screenPoint = event.screenPoint;
        const facingPlane = event.facingPlane;

        let line = this.createLine();
        let points = this.getPoints();

        this._dragging       = true;
        this._dragStartIndex = points.length-1;

        this.setLinePlane(line, viewer, facingPlane);
        let newPoint = line.convertScreenPointToVolume(event, screenPoint);
        if (newPoint) {
            let plane = line.plane;
            if (plane)
                newPoint = viewer.clampPointToPlane(newPoint, plane.point, plane.normal);

            this.createPoint({ point: newPoint });

            line.select();

            return true;
        }

        return false;
    }

    // Continues a dragging operation on the overlay.
    // This event will only be received by this overlay if the onStartDrag()
    // method was called earlier for this overlay.  Naked drag events should
    // never occur.
    // This method may be overridden by derived classes.
    onDrag(event) {
        const viewer      = event.viewer;
        const screenPoint = event.screenPoint;
        const facingPlane = event.facingPlane;

        let line = this.createLine();
        let newPoint = line.convertScreenPointToVolume(event, screenPoint);
        if (newPoint) {
            let plane = line.plane;
            if (plane)
                newPoint = viewer.clampPointToPlane(newPoint, plane.point, plane.normal);

            this.createPoint({ point: newPoint });

            return true;
        }

        return true;
    }

    // Completes a dragging operation on the overlay.
    // This event will only be received by this overlay if the onStartDrag()
    // method was called earlier for this overlay.  Naked drag events should
    // never occur.
    // This method may be overridden by derived classes.
    onEndDrag(event) {
        const viewer      = event.viewer;
        const screenPoint = event.screenPoint;

        let line = this.createLine();
        line.simplify(event, this._dragStartIndex);

        // If we end our drag near the starting point, we are implicitly
        // closing the loop and finishing the wizard
        let firstOverlay = line.firstChild();
        let nearOverlay = firstOverlay.findSelectableOverlay(screenPoint.x, screenPoint.y);
        if (nearOverlay !== null) {
            line.closePath = true;
            this.finalize();
        }

        this._dragging       = false;
        this._dragStartIndex = null;

        return true;
    }

    // Handles a single-click or tablet touch event on the overlay.
    // This method may be overridden by derived classes.
    onClick(event) {
        const viewer      = event.viewer;
        const screenPoint = event.screenPoint;
        const facingPlane = event.facingPlane;

        let line = this.createLine();

        // If we click on the first point, we are implicitly closing the loop
        // and finishing the wizard
        let firstOverlay = line.firstChild();
        if (firstOverlay) {
            let nearOverlay = firstOverlay.findSelectableOverlay(screenPoint.x, screenPoint.y);
            if (nearOverlay !== null) {
                line.closePath = true;
                this.finalize();
                return true;
            }
        }

        // If we click on the last point, we are NOT closing the loop, but we
        // are finishing the wizard.
        let lastOverlay = line.lastChild();
        if (lastOverlay) {
            let nearOverlay = lastOverlay.findSelectableOverlay(screenPoint.x, screenPoint.y);
            if (nearOverlay !== null) {
                this.finalize();
                return true;
            }
        }

        // Otherwise, add a new point
        let newPoint = line.convertScreenPointToVolume(event, screenPoint);
        if (newPoint) {
            this.setLinePlane(line, viewer, facingPlane);
            let plane = line.plane;
            if (plane)
                newPoint = viewer.clampPointToPlane(newPoint, plane.point, plane.normal);

            let options = { point: newPoint };

            this.createPoint(options);

            line.select();
        }

        return true;
    }
}
// Register this primitive
VolumeOverlay.registerPrimitive(VolumeOverlayFreehandLineWizard);


// ============================================================================
// VolumeOverlayPointWizard
// ============================================================================

export class VolumeOverlayPointWizard extends VolumeOverlayWizard {
    // Constructor for the overlay class.
    constructor(parent, options) {
        super();

        if (options)  this.setProperties(options);
        if (parent)   parent.addChild(this);
    }

    // Properties list for this class.
    // These properties will automatically be serialized and deserialized
    // via the getProperties() and setProperties() methods, respectively.
    // This list is also used to initialize the properties in the
    // constructor.
    static propertyList() {
        return {
            pointPrimitive:  null,
            pointProperties: null
        };
    }

    // PRIVATE METHODS ========================================================

    // Creates a point.
    createPoint(options=null) {
        if (options || this._pointProperties)
            options = Object.assign({}, this._pointProperties, options);
        let pointPrimitive = this.pointPrimitive;
        let point = VolumeOverlay.constructPrimitive(pointPrimitive, this, options);
        return point;
    }

    // Gets a list of all visible child points owned by this wizard.
    getPoints() {
        let points = this.findChildren(c => c.primitive === this.pointPrimitive && c.isVisible());
        return points;
    }

    // PUBLIC PROPERTIES ======================================================

    // Property representing a named point primitive type.
    // When the wizard creates a new point, it will build the primitive based
    // on this type.
    get pointPrimitive() {
        if (this._pointPrimitive)
            return this._pointPrimitive;
        else
            return VolumeOverlayPoint.prototype.primitive;
    }
    set pointPrimitive(primitive) {
        if (primitive && typeof primitive === 'function')
            primitive = primitive.prototype.primitive;
        if (primitive && typeof primitive === 'string')
            this._pointPrimitive = primitive;
        else
            this._pointPrimitive = null;
    }

    // Property representing an object of primitive point type properties.
    // When the wizard creates a new point, it will build the primitive based
    // on these properties.
    get pointProperties() {
        return VolumeOverlay.deepCopy(this._pointProperties);
    }
    set pointProperties(properties) {
        if (properties && typeof properties === 'object') {
            this._pointProperties = VolumeOverlay.deepCopy(properties);
        }
        else {
            this._pointProperties = null;
        }
    }

    // OVERRIDEABLE METHODS ===================================================

    // Returns the unique name of the overlay type.
    // Used when serializing and deserializing properties.
    // This method must be overridden by derived classes.
    get primitive() {
        return "wizard-point";
    }

    // Starts a dragging operation on the overlay.
    // Information related to the volume viewer and the current context
    // is provided in the form of an event object.
    // This method may be overridden by derived classes.
    onStartDrag(event) {
        return false;
    }

    // Continues a dragging operation on the overlay.
    // This event will only be received by this overlay if the onStartDrag()
    // method was called earlier for this overlay.  Naked drag events should
    // never occur.
    // This method may be overridden by derived classes.
    onDrag(event) {
        return false;
    }

    // Completes a dragging operation on the overlay.
    // This event will only be received by this overlay if the onStartDrag()
    // method was called earlier for this overlay.  Naked drag events should
    // never occur.
    // This method may be overridden by derived classes.
    onEndDrag(event) {
        let points = this.getPoints();
        if (points.length >= 1)
            this.finalize();

        return false;
    }

    // Handles a single-click or tablet touch event on the overlay.
    // This method may be overridden by derived classes.
    onClick(event) {
        const viewer      = event.viewer;
        const screenPoint = event.screenPoint;
        const facingPlane = event.facingPlane;

        let points = this.getPoints();
        if (points.length >= 1) {
            this.finalize();
            return true;
        }

        let newPoint = this.convertScreenPointToVolume(event, screenPoint);
        if (newPoint) {
            let options = { point: newPoint };

            if (points.length === 0) {
                let child = this.createPoint(options);
                this.transferSelection(child);
                this.finalize();
            }
            else {
                this.finalize();
            }
        }
    }
}
// Register this primitive
VolumeOverlay.registerPrimitive(VolumeOverlayPointWizard);





// ============================================================================
// EXAMPLES
// ============================================================================

// ============================================================================
// TEMPLATE EXAMPLE OF A DERIVED OVERLAY
// ============================================================================

// This is an example template for an overlay class derived from VolumeOverlay.
// It should NOT be used directly.  Instead, copy the class, rename it, and
// fill in the appropriate fields and methods.
export class VolumeOverlayTemplate extends VolumeOverlay {
    // Constructor for the overlay class.
    constructor(parent, options) {
        // Always call the base constructor like this, with no parameters.
        super();

        // Note: you do not need to initialize fields that are associated
        // with properties.  This is already done for you as a convenience,
        // using the propertyList() method associated with this class.

        // You are, however, free to add additional fields as needed here.

        // Always invoke these two calls last, in this order.
        if (options)  this.setProperties(options);
        if (parent)   parent.addChild(this);
    }

    // Properties list for this class.
    // These properties will automatically be serialized and deserialized
    // via the getProperties() and setProperties() methods, respectively.
    // This list is also used to initialize the properties in the
    // constructor.
    static propertyList() {
        // This method should return an object containing the properties
        // you want to be able to set and get, along with their default
        // values.
        // You only need to include the properties for this specific
        // class.  The VolumeOverlay class automatically builds a complete
        // list by ascending the class hierarchy.
        // If you want to override default property values from a base class,
        // you may also include them here with their new default values.
        return {
            selectable: true,  // overrides default "selectable" value from base overlay class
            replaceMe:  8,
            replaceMe2: Vmath.createVector(0.1, 0.2, 0.3)
        };
    }

    // PUBLIC PROPERTIES ======================================================

    // Getters and setters for your properties go here.

    // Property representing something you can replace.
    get replaceMe() {
        // Value types may be returned outright.
        return this._replaceMe;
    }
    set replaceMe(replaceMe) {
        // Sanity checks go here.
        if (!replaceMe)       replaceMe = 8;
        if (replaceMe < 1)    replaceMe = 1;
        if (replaceMe > 20)   replaceMe = 20;

        // Make sure the property has actually changed.
        if (this._replaceMe !== replaceMe) {
            // Value types may be replaced outright.
            this._replaceMe = replaceMe;

            // If this property changes stuff that will affect your onCalculate() method, call:
            // this.invalidate();
            // If it only changes stuff that you're drawing (colors, for example), call:
            this.requestRedraw();
            // Call one or the other, but not both.
        }
    }

    // Property representing something else you can replace.
    get replaceMe2() {
        // If this is an object or array, a deep copy should be made.
        return VolumeOverlay.deepCopy(this._replaceMe2);
    }
    set replaceMe2(replaceMe2) {
        // Sanity checks go here.
        if (replaceMe2 === undefined || replaceMe2 === null)  return;
        if (typeof replaceMe2 !== 'object')    return;
        if (typeof replaceMe2.x !== 'number')  return;
        if (typeof replaceMe2.y !== 'number')  return;
        if (typeof replaceMe2.z !== 'number')  return;

        // Make sure the property has actually changed.
        if (this._replaceMe2.x !== replaceMe2.x ||
            this._replaceMe2.y !== replaceMe2.y ||
            this._replaceMe2.z !== replaceMe2.z) {
            // If this is an object or array, a deep copy should be made.
            // (It is recommended that you copy fields individually if you want
            // to discard unnecessary information.)
            this._replaceMe2 = VolumeOverlay.deepCopy(replaceMe2);

            // If this property changes stuff that will affect your onCalculate() method, call:
            this.invalidate();
            // If it only changes stuff that you're drawing (colors, for example), call:
            // this.requestRedraw();
            // Call one or the other, but not both.
        }
    }

    // Add as many additional properties as you want here...

    // OVERRIDDEN METHODS =====================================================

    // Returns the unique name of the overlay type.
    // Used when serializing and deserializing properties.
    // This method must be overridden by derived classes.
    get primitive() {
        // This is a primitive name for your class.  It identifies your class
        // when it is described as part of a JSON property object.
        // THIS NAME MUST BE UNIQUE TO YOUR CLASS!
        return "template";
    }

    // Returns true if the overlay is an aggregate -- that is, if it owns
    // and is responsible for controlling all of its descendants.
    // When the child of an aggregate overlay is selected or highlighted,
    // the entire aggregate hierarchy will be drawn on top of other overlays.
    // All mouse and keyboard events will also go to the aggregate, which is
    // responsible for propagating the event to the actual selected overlay.
    // (The aggregate and the selected overlay may be the same object.)
    // This method may be overridden by derived classes.
    get isAggregate() {
        // This should always return either "true" or "false".
        // Aggregate state should always be the same for all objects in the
        // same class; it should NOT be dynamically changed per object.
        return true;
    }

    // Starts a dragging operation on the overlay.
    // Information related to the volume viewer and the current context
    // is provided in the form of an event object.
    // This method may be overridden by derived classes.
    onStartDrag(event) {
        // Conveniences
        const viewer      = event.viewer;       // the VolumeViewer that owns this overlay
        const displayView = event.displayView;  // the identifier of the viewport
        const target      = event.target;       // the lowest-level target object that is being dragged
        const facingPlane = event.facingPlane;  // the plane number of the current facing plane

        // This method should pass back either a true or false.
        // Returning true means that this overlay handled the onStartDrag() event
        // and that further dragging operations should not be propagated
        // to descendants of this overlay.
        // Returning false will cause the event to bubble downwards to the
        // original target object.
        return false;
    }

    // Continues a dragging operation on the overlay.
    // This event will only be received by this overlay if the onStartDrag()
    // method was called earlier for this overlay.  Naked drag events should
    // never occur.
    // This method may be overridden by derived classes.
    onDrag(event) {
        // Conveniences
        const viewer      = event.viewer;       // the VolumeViewer that owns this overlay
        const displayView = event.displayView;  // the identifier of the viewport
        const target      = event.target;       // the lowest-level target object that is being dragged
        const screenPoint = event.screenPoint;  // the 2D screen point to which the overlay is being dragged
        const facingPlane = event.facingPlane;  // the plane number of the current facing plane

        // Note that overlays are responsible for performing their own
        // conversions from a 2D screen point to a 3D volume point during drag
        // operations.
        // Typically, the overlay will want to call:
        // let intersect = viewer.getPlaneIntersection(screenPoint.x, screenPoint.y, false, [facingPlane]);
        // to perform the coordinate conversion, but some overlays may want to
        // perform other conversions.

        // This method should pass back either a true or false.
        // Returning true means that this overlay handled the drag() event
        // and that further dragging operations should not be propagated
        // to descendants of this overlay.
        // Returning false will cause the event to bubble downwards to the
        // original target object.
        return false;
    }

    // Completes a dragging operation on the overlay.
    // This event will only be received by this overlay if the onStartDrag()
    // method was called earlier for this overlay.  Naked drag events should
    // never occur.
    // This method may be overridden by derived classes.
    onEndDrag(event) {
        // Conveniences
        const viewer      = event.viewer;       // the VolumeViewer that owns this overlay
        const displayView = event.displayView;  // the identifier of the viewport
        const target      = event.target;       // the lowest-level target object that is being dragged
        const facingPlane = event.facingPlane;  // the plane number of the current facing plane

        // This method should pass back either a true or false.
        // Returning true means that this overlay handled the onEndDrag() event.
        // However, the return value is typically ignored by calling classes.
        return false;
    }

    // Handles a single-click or tablet touch event on the overlay.
    // This method may be overridden by derived classes.
    onClick(event) {
        // Conveniences
        const viewer      = event.viewer;       // the VolumeViewer that owns this overlay
        const displayView = event.displayView;  // the identifier of the viewport
        const target      = event.target;       // the lowest-level target object that is being dragged
        const facingPlane = event.facingPlane;  // the plane number of the current facing plane

        // This method should pass back either a true or false.
        // Returning true means that this overlay handled the onClick() event
        // and that the event should not be propagated to descendants
        // of this overlay.
        // Returning false will cause the event to bubble downwards to the
        // original target object.
        // If no overlay handles the onClick() event, the default action is to
        // select the target overlay.
        return false;
    }

    // Handles keydown events on the overlay.
    // The target overlay that typically receives this event is the currently
    // highlighted or selected overlay in the viewer.
    // This method may be overridden by derived classes.
    onKeyDown(event) {
        // Conveniences
        const viewer      = event.viewer;       // the VolumeViewer that owns this overlay
        const displayView = event.displayView;  // the identifier of the viewport
        const target      = event.target;       // the lowest-level target object that is being dragged
        const keyEvent    = event.keyEvent;     // the actual keydown event object that triggered this event

        const keyCode  = (keyEvent.which || keyEvent.keyCode || 0);
        const ctrlKey  = (keyEvent.ctrlKey || 0);
        const shiftKey = (keyEvent.shiftKey || 0);
        const altKey   = (keyEvent.altKey || 0);

        // Example (deletes this object when the Delete key is pressed):
        if (keyCode === 46 || keyCode === 8) {  // delete, backspace
            this.destroy();
            return true;
        }

        // This method should pass back either a true or false.
        // Returning true means that this overlay handled the onKeyDown() event
        // and that the event should not be propagated to descendants
        // of this overlay.
        // Returning false will cause the event to bubble downwards to the
        // original target object.
        // If no overlay handles the onKeyDown() event, the event will be handled
        // directly by the volume viewer.
        return false;
    }

    // Returns true if the overlay should be drawn, or false otherwise.
    // This implicitly affects all descendants of the overlay as well.
    // This method may be overridden by derived classes.
    onShouldShow(event) {
        // Conveniences
        const viewer      = event.viewer;       // the VolumeViewer that owns this overlay
        const displayView = event.displayView;  // the identifier of the viewport
        const planes      = event.planes;       // plane information in the current view

        // Modify this method if you want to dynamically control whether
        // the overlay should be drawn or not.
        // This is not as efficient as directly setting the visible property
        // for the overlay, but is useful for short-circuiting the drawing
        // process based on things like volume viewer orientation.
        // Return true if the overlay should be drawn, or false otherwise.
        return true;
    }

    // Performs expensive calculations associated with a specific viewport.
    // Results will be cached and re-used when possible.
    // Note that a single volume viewer may render to more than one viewport
    // during a render pass!
    // This method may be overridden by derived classes.
    onCalculate(event) {
        // Conveniences
        const viewer      = event.viewer;       // the VolumeViewer that owns this overlay
        const displayView = event.displayView;  // the identifier of the viewport

        // Expensive calculations, such as coordinate conversions, should go in
        // this method.
        // Do whatever calculations you need to do here, and put the results
        // in the returned object below.

        // Example (converting a point from volume space to screen space):
        let replaceMe2Screen = viewer.convertToVolumeOffset(this._replaceMe2);
        replaceMe2Screen = viewer.pointToDisplay(replaceMe2Screen.x, replaceMe2Screen.y, replaceMe2Screen.z, displayView);

        // Return an object that you will use when drawing or selecting primitives.
        return {
            // Add content here.
            replaceMe2Screen: replaceMe2Screen
        };
    }

    // Determines whether the specified screen position is "part of" the overlay.
    // Criteria for this determination varies from overlay to overlay.
    // Invoked from the findSelectableOverlay() method.
    // This method may be overridden by derived classes.
    onQuerySelectable(event, calculations) {
        // "calculations" is the object you created in your onCalculate() method.

        // Conveniences
        const viewer       = event.viewer ;       // the VolumeViewer that owns this overlay
        const hasPrecision = event.hasPrecision;  // true if using mouse events, false if using touch
        const displayView  = event.displayView;   // the identifier of the viewport
        const screenPoint  = event.screenPoint;   // the 2D screen point which is being queried

        // Example:
        // In this section, compute the distance (squared) between your
        // calculated annotation feature and the display point being queried
        // (from the event object).
        const replaceMe2Screen = calculations.replaceMe2Screen;
        let distance2 = Vmath.distanceSquared(screenPoint, replaceMe2Screen);

        // If the distance (squared) is sufficiently close, call this method:
        //
        // event.updateQuery(this, distance2, VolumeOverlay.PRIORITY_MEDIUM);
        //
        // The priority may be any number, but low, medium, and high
        // priorities are predefined.
        // High priority should be used for small features, like points.
        // Medium priority should be used for moderate-sized features, like lines.
        // Low priority should be used for large features, like areas or volumes.
        //
        // If you can't compute a distance, or such a distance would be
        // nonsensical (such as when you are inside a polygon), use 0.
        const MAX_DISTANCE  = hasPrecision ? 12 : 20;
        const MAX_DISTANCE2 = MAX_DISTANCE * MAX_DISTANCE;
        if (distance2 <= MAX_DISTANCE2)
            event.updateQuery(this, distance2, VolumeOverlay.PRIORITY_HIGH);

        // The algorithm that determines "part-of-ness" is not lazy.
        // All visible, selectable overlays will be considered in the search,
        // and a final determination will be made based on the best match
        // that is passed to the event.update method.
    }

    // Draws the contents of the overlay, prior to the children being rendered.
    // This method may be overridden by derived classes.
    onPreRender(event, calculations) {
        // "calculations" is the object you created in your onCalculate() method.

        // Conveniences
        const viewer      = event.viewer;       // the VolumeViewer that owns this overlay
        const displayView = event.displayView;  // the identifier of the viewport
        const context     = event.context;      // the 2D context used for drawing

        // Draw your primitives here, using the 2D context above.
        // Anything drawn here will be drawn *before* this primitive's
        // children are rendered.
        // Note that this has no effect on the draw order if a child's zIndex
        // is different from that of its parent.

        // In this example, we do nothing here.
    }

    // Draws the contents of the overlay, after the children have been rendered.
    // This method may be overridden by derived classes.
    onPostRender(event, calculations) {
        // "calculations" is the object you created in your onCalculate() method.

        // Conveniences
        const viewer      = event.viewer;       // the VolumeViewer that owns this overlay
        const displayView = event.displayView;  // the identifier of the viewport
        const context     = event.context;      // the 2D context used for drawing
        const renderHints = event.renderHints;  // render hints provided by ancestors

        // Draw your primitives here, using the 2D context above.
        // Anything drawn here will be drawn *after* this primitive's
        // children are rendered.
        // Note that this has no effect on the draw order if a child's zIndex
        // is different from that of its parent.

        // Example:

        // This is an example of using the overlay's inherit() method to
        // provide fallback values if the top-level values aren't set.
        const replaceMe   = VolumeOverlay.inherit(
            this._replaceMe,        // use this if possible; if not set, then
            renderHints.lineWidth,  // use this if possible; if not set, then
            1                       // use this
        );
        const replaceMe2  = calculations.replaceMe2Screen;
        const selected    = this.selected;
        const highlighted = this.highlighted;
        if (selected) {
            context.strokeStyle = "#FFFFFF";
            context.shadowBlur  = 3;
            context.shadowColor = "black";
        }
        else if (highlighted) {
            context.strokeStyle = "#C0C0FF";
            context.shadowBlur  = 3;
            context.shadowColor = "black";
        }
        else {
            context.strokeStyle = "#FF00FF";
        }
        context.lineWidth = this._replaceMe;

        // Draw a diamond.
        context.beginPath();
        context.moveTo(replaceMe2.x, replaceMe2.y-10);
        context.lineTo(replaceMe2.x-10, replaceMe2.y);
        context.lineTo(replaceMe2.x, replaceMe2.y+10);
        context.lineTo(replaceMe2.x+10, replaceMe2.y);
        context.closePath();
        context.stroke();
    }
}
// You will also want to register your primitive type, like so:
VolumeOverlay.registerPrimitive(VolumeOverlayTemplate);

// STM_TODO - show example creation code here
