
// ============================================================================
// Vmath
// ============================================================================

// Utilities for linear algebra.
export default class Vmath {
    // Returns true if the specified object is empty
    // (it is neither a vector of any order, nor a matrix of any order).
    static isEmpty(o) {
        return !Vmath.isVector(o) && !Vmath.isMatrix(o);
    }

    // Returns true if the specified object is a vector of any order.
    static isVector(p) {
        if (!p)  return false;
        return (p.x !== undefined || p.y !== undefined);
    }

    // Returns true if the specified object is a vector of at least order 2.
    static isVector2(p) {
        if (!p)  return false;
        return (p.x !== undefined && p.y !== undefined);
    }

    // Returns true if the specified object is a vector of at least order 3.
    static isVector3(p) {
        if (!p)  return false;
        return (p.x !== undefined && p.y !== undefined && p.z !== undefined);
    }

    // Returns true if the specified object is a vector of at least order 4.
    static isVector4(p) {
        if (!p)  return false;
        return (p.x !== undefined && p.y !== undefined && p.z !== undefined &&
                p.w !== undefined);
    }

    // Returns true if the specified object is a matrix of any order.
    static isMatrix(m) {
        if (!m)  return false;
        return (
            m.m00 !== undefined || m.m01 !== undefined ||
            m.m10 !== undefined || m.m11 !== undefined
        );
    }

    // Returns true if the specified object is a matrix of at least order 2.
    static isMatrix2(m) {
        if (!m)  return false;
        return (
            m.m00 !== undefined && m.m01 !== undefined &&
            m.m10 !== undefined && m.m11 !== undefined
        );
    }

    // Returns true if the specified object is a matrix of at least order 3.
    static isMatrix3(m) {
        if (!Vmath.isMatrix2(m))  return false;
        return (
            m.m02 !== undefined &&
            m.m12 !== undefined &&
            m.m20 !== undefined && m.m21 !== undefined && m.m22 !== undefined
        );
    }

    // Returns true if the specified object is a matrix of at least order 4.
    static isMatrix4(m) {
        if (!Vmath.isMatrix3(m))  return false;
        return (
            m.m03 !== undefined &&
            m.m13 !== undefined &&
            m.m23 !== undefined &&
            m.m30 !== undefined && m.m31 !== undefined && m.m32 !== undefined && m.m33 !== undefined
        );
    }

    // Builds an empty (zero-filled) vector of order 2.
    // Returns the new vector.
    static createEmptyVector2() {
        return { x: 0, y: 0 };
    }

    // Builds an empty (zero-filled) vector of order 3.
    // Returns the new vector.
    static createEmptyVector3() {
        return { x: 0, y: 0, z: 0 };
    }

    // Builds an empty (zero-filled) vector of order 4.
    // Returns the new vector.
    static createEmptyVector4() {
        return { x: 0, y: 0, z: 0, w: 0 };
    }

    // Builds an identity matrix of order 2.
    // Returns the new matrix.
    static createIdentityMatrix2() {
        return {
            m00: 1, m01: 0,
            m10: 0, m11: 1
        };
    }

    // Builds an identity matrix of order 3.
    // Returns the new matrix.
    static createIdentityMatrix3() {
        return {
            m00: 1, m01: 0, m02: 0,
            m10: 0, m11: 1, m12: 0,
            m20: 0, m21: 0, m22: 1
        };
    }

    // Builds an identity matrix of order 4.
    // Returns the new matrix.
    static createIdentityMatrix4() {
        return {
            m00: 1, m01: 0, m02: 0, m03: 0,
            m10: 0, m11: 1, m12: 0, m13: 0,
            m20: 0, m21: 0, m22: 1, m23: 0,
            m30: 0, m31: 0, m32: 0, m33: 1
        };
    }

    // Builds a vector from the specified parameters.
    // Returns the new vector.
    static createVector(x, y, z, w) {
        return { x: x, y: y, z: z, w: w };
    }

    // Builds a 2x2 matrix from the specified parameters.
    // Returns the new matrix.
    static createMatrix2(m00, m01,
                         m10, m11) {
        return {
            m00: m00, m01: m01,
            m10: m10, m11: m11
        };
    }

    // Builds a 3x3 matrix from the specified parameters.
    // Returns the new matrix.
    static createMatrix3(m00, m01, m02,
                         m10, m11, m12,
                         m20, m21, m22) {
        return {
            m00: m00, m01: m01, m02: m02,
            m10: m10, m11: m11, m12: m12,
            m20: m20, m21: m21, m22: m22
        };
    }

    // Builds a 4x4 matrix from the specified parameters.
    // Returns the new matrix.
    static createMatrix4(m00, m01, m02, m03,
                         m10, m11, m12, m13,
                         m20, m21, m22, m23,
                         m30, m31, m32, m33) {
        return {
            m00: m00, m01: m01, m02: m02, m03: m03,
            m10: m10, m11: m11, m12: m12, m13: m13,
            m20: m20, m21: m21, m22: m22, m23: m23,
            m30: m30, m31: m31, m32: m32, m33: m33
        };
    }

    // Returns a copy of the specified vector or matrix.
    static copy(o) {
        if (Vmath.isVector(o))  return Vmath.copyVector(o);
        if (Vmath.isMatrix(o))  return Vmath.copyMatrix(o);
        return {};
    }

    // Returns a copy of the specified vector.
    static copyVector(p) {
        if (!p)  p = Vmath.EMPTY_OBJECT;
        return { x: p.x, y: p.y, z: p.z, w: p.w };
    }

    // Returns a copy of the specified matrix.
    static copyMatrix(m) {
        if (!m)  m = Vmath.EMPTY_OBJECT;
        return {
            m00: m.m00, m01: m.m01, m02: m.m02, m03: m.m03,
            m10: m.m10, m11: m.m11, m12: m.m12, m13: m.m13,
            m20: m.m20, m21: m.m21, m22: m.m22, m23: m.m23,
            m30: m.m30, m31: m.m31, m32: m.m32, m33: m.m33
        };
    }

    // Converts an existing vector to a vector of order 2.
    // Returns the converted vector.
    static toVector2(p) {
        if (!p)  p = Vmath.EMPTY_OBJECT;

        let x = p.x !== undefined ? p.x : 0;
        let y = p.y !== undefined ? p.y : 0;

        return { x: x, y: y };
    }

    // Converts an existing vector to a vector of order 3.
    // Returns the converted vector.
    static toVector3(p) {
        if (!p)  p = Vmath.EMPTY_OBJECT;

        let x = p.x !== undefined ? p.x : 0;
        let y = p.y !== undefined ? p.y : 0;
        let z = p.z !== undefined ? p.z : 0;

        return { x: x, y: y, z: z };
    }

    // Converts an existing vector to a vector of order 4.
    // Returns the converted vector.
    static toVector4(p) {
        if (!p)  p = Vmath.EMPTY_OBJECT;

        let x = p.x !== undefined ? p.x : 0;
        let y = p.y !== undefined ? p.y : 0;
        let z = p.z !== undefined ? p.z : 0;
        let w = p.w !== undefined ? p.w : 0;

        return { x: x, y: y, z: z, w: w };
    }

    // Converts an existing matrix to a 2x2 matrix.
    // Returns the converted matrix.
    static toMatrix2(m) {
        if (!m)  m = Vmath.EMPTY_OBJECT;

        let m00 = m.m00 !== undefined ? m.m00 : 1;
        let m01 = m.m01 !== undefined ? m.m01 : 0;
        let m10 = m.m10 !== undefined ? m.m10 : 0;
        let m11 = m.m11 !== undefined ? m.m11 : 1;

        return {
            m00: m00, m01: m01,
            m10: m10, m11: m11
        };
    }

    // Converts an existing matrix to a 3x3 matrix.
    // Returns the converted matrix.
    static toMatrix3(m) {
        if (!m)  m = Vmath.EMPTY_OBJECT;

        let m00 = m.m00 !== undefined ? m.m00 : 1;
        let m01 = m.m01 !== undefined ? m.m01 : 0;
        let m02 = m.m02 !== undefined ? m.m02 : 0;
        let m10 = m.m10 !== undefined ? m.m10 : 0;
        let m11 = m.m11 !== undefined ? m.m11 : 1;
        let m12 = m.m12 !== undefined ? m.m12 : 0;
        let m20 = m.m20 !== undefined ? m.m20 : 0;
        let m21 = m.m21 !== undefined ? m.m21 : 0;
        let m22 = m.m22 !== undefined ? m.m22 : 1;

        return {
            m00: m00, m01: m01, m02: m02,
            m10: m10, m11: m11, m12: m12,
            m20: m20, m21: m21, m22: m22
        };
    }

    // Converts an existing matrix to a 4x4 matrix.
    // Returns the converted matrix.
    static toMatrix4(m) {
        if (!m)  m = Vmath.EMPTY_OBJECT;

        let m00 = m.m00 !== undefined ? m.m00 : 1;
        let m01 = m.m01 !== undefined ? m.m01 : 0;
        let m02 = m.m02 !== undefined ? m.m02 : 0;
        let m03 = m.m03 !== undefined ? m.m03 : 0;
        let m10 = m.m10 !== undefined ? m.m10 : 0;
        let m11 = m.m11 !== undefined ? m.m11 : 1;
        let m12 = m.m12 !== undefined ? m.m12 : 0;
        let m13 = m.m13 !== undefined ? m.m13 : 0;
        let m20 = m.m20 !== undefined ? m.m20 : 0;
        let m21 = m.m21 !== undefined ? m.m21 : 0;
        let m22 = m.m22 !== undefined ? m.m22 : 1;
        let m23 = m.m23 !== undefined ? m.m22 : 0;
        let m30 = m.m30 !== undefined ? m.m30 : 0;
        let m31 = m.m31 !== undefined ? m.m31 : 0;
        let m32 = m.m32 !== undefined ? m.m32 : 0;
        let m33 = m.m33 !== undefined ? m.m33 : 1;

        return {
            m00: m00, m01: m01, m02: m02, m03: m03,
            m10: m10, m11: m11, m12: m12, m13: m13,
            m20: m20, m21: m21, m22: m22, m23: m23,
            m30: m30, m31: m31, m32: m32, m33: m33
        };
    }

    // Computes a dot product for the two specified vectors.
    // Returns the scalar dot product.
    static dot(p0, p1) {
        if (!p0 || !p1)  return 0.0;

        let dot = 0.0;

        if (p0.x !== undefined && p1.x !== undefined)  dot += p0.x * p1.x;
        if (p0.y !== undefined && p1.y !== undefined)  dot += p0.y * p1.y;
        if (p0.z !== undefined && p1.z !== undefined)  dot += p0.z * p1.z;
        if (p0.w !== undefined && p1.w !== undefined)  dot += p0.w * p1.w;

        return dot;
    }

    // Adds the two specified vectors together.
    // Returns the added vector.
    static add(p0, p1) {
        if (!p0)  p0 = Vmath.EMPTY_OBJECT;
        if (!p1)  p1 = Vmath.EMPTY_OBJECT;

        let x, y, z, w;

        if (p0.x !== undefined && p1.x !== undefined)  x = p0.x + p1.x;
        else if (p0.x !== undefined)                   x = p0.x;
        else if (p1.x !== undefined)                   x = p1.y;

        if (p0.y !== undefined && p1.y !== undefined)  y = p0.y + p1.y;
        else if (p0.y !== undefined)                   y = p0.y;
        else if (p1.y !== undefined)                   y = p1.y;

        if (p0.z !== undefined && p1.z !== undefined)  z = p0.z + p1.z;
        else if (p0.z !== undefined)                   z = p0.z;
        else if (p1.z !== undefined)                   z = p1.z;

        if (p0.w !== undefined && p1.w !== undefined)  w = p0.w + p1.w;
        else if (p0.w !== undefined)                   w = p0.w;
        else if (p1.w !== undefined)                   w = p1.w;

        return { x: x, y: y, z: z, w: w };
    }

    // Subtracts the second vector from the first vector.
    // Differs from the delta() method in that it handles missing fields
    // differently.  (Missing fields will be treated as if they are set to zero.)
    // Returns the results of the subtraction.
    static subtract(p0, p1) {
        if (!p0)  p0 = Vmath.EMPTY_OBJECT;
        if (!p1)  p1 = Vmath.EMPTY_OBJECT;

        let x, y, z, w;

        if (p0.x !== undefined && p1.x !== undefined)  x = p0.x - p1.x;
        else if (p0.x !== undefined)                   x = p0.x;
        else if (p1.x !== undefined)                   x = -p1.y;

        if (p0.y !== undefined && p1.y !== undefined)  y = p0.y - p1.y;
        else if (p0.y !== undefined)                   y = p0.y;
        else if (p1.y !== undefined)                   y = -p1.y;

        if (p0.z !== undefined && p1.z !== undefined)  z = p0.z - p1.z;
        else if (p0.z !== undefined)                   z = p0.z;
        else if (p1.z !== undefined)                   z = -p1.z;

        if (p0.w !== undefined && p1.w !== undefined)  w = p0.w - p1.w;
        else if (p0.w !== undefined)                   w = p0.w;
        else if (p1.w !== undefined)                   w = -p1.w;

        return { x: x, y: y, z: z, w: w };
    }

    // Finds the delta between the two specified vectors.
    // Differs from the subtract() method in that it handles missing fields
    // differently.  (Missing fields will generate a zero delta.)
    // Returns the delta.
    static delta(p0, p1) {
        if (!p0)  p0 = Vmath.EMPTY_OBJECT;
        if (!p1)  p1 = Vmath.EMPTY_OBJECT;

        let x, y, z, w;

        if (p0.x !== undefined && p1.x !== undefined)  x = p0.x - p1.x;
        else if (p0.x !== undefined)                   x = 0.0;
        else if (p1.x !== undefined)                   x = 0.0;

        if (p0.y !== undefined && p1.y !== undefined)  y = p0.y - p1.y;
        else if (p0.y !== undefined)                   y = 0.0;
        else if (p1.y !== undefined)                   y = 0.0;

        if (p0.z !== undefined && p1.z !== undefined)  z = p0.z - p1.z;
        else if (p0.z !== undefined)                   z = 0.0;
        else if (p1.z !== undefined)                   z = 0.0;

        if (p0.w !== undefined && p1.w !== undefined)  w = p0.w - p1.w;
        else if (p0.w !== undefined)                   w = 0.0;
        else if (p1.w !== undefined)                   w = 0.0;

        return { x: x, y: y, z: z, w: w };
    }

    // Creates a new vector that is the negative of the specified vector.
    // Returns the new vector.
    static negate(p) {
        if (!p)  p = Vmath.EMPTY_OBJECT;

        let x, y, z, w;

        if (p.x !== undefined)  x = -p.x;
        if (p.y !== undefined)  y = -p.y;
        if (p.z !== undefined)  z = -p.z;
        if (p.w !== undefined)  w = -p.w;

        return { x: x, y: y, z: z, w: w };
    }

    // Scales the specified vector by the specified scalar.
    // Returns the scaled vector.
    static scale(p, scale) {
        if (!p)  p = Vmath.EMPTY_OBJECT;
        if (scale === undefined || scale === null)  scale = 1.0;

        let x, y, z, w;

        if (p.x !== undefined)  x = p.x * scale;
        if (p.y !== undefined)  y = p.y * scale;
        if (p.z !== undefined)  z = p.z * scale;
        if (p.w !== undefined)  w = p.w * scale;

        return { x: x, y: y, z: z, w: w };
    }

    // Scales the second vector by the specified scalar, and adds it to the
    // first vector.
    // Returns the new vector.
    static scaleAndAdd(p, d, scale) {
        if (!p)  p = Vmath.EMPTY_OBJECT;
        if (!d)  d = Vmath.EMPTY_OBJECT;
        if (scale === undefined || scale === null)  scale = 1.0;

        let x, y, z, w;

        if (p.x !== undefined && d.x !== undefined)  x = p.x + d.x * scale;
        else if (p.x !== undefined)                  x = p.x;
        else if (d.x !== undefined)                  x = d.x * scale;

        if (p.y !== undefined && d.y !== undefined)  y = p.y + d.y * scale;
        else if (p.y !== undefined)                  y = p.y;
        else if (d.y !== undefined)                  y = d.y * scale;

        if (p.z !== undefined && d.z !== undefined)  z = p.z + d.z * scale;
        else if (p.z !== undefined)                  z = p.z;
        else if (d.z !== undefined)                  z = d.z * scale;

        if (p.w !== undefined && d.w !== undefined)  w = p.w + d.w * scale;
        else if (p.w !== undefined)                  w = p.w;
        else if (d.w !== undefined)                  w = d.w * scale;

        return { x: x, y: y, z: z, w: w };
    }

    // Takes the cross product of the two specified vector.
    // Both vectors must be three-dimensional.
    // Returns the 3D vector cross-product.
    static cross(p0, p1) {
        let x = 0.0, y = 0.0, z = 0.0;

        if (p0 && p1) {
            if (p0.x !== undefined && p0.y !== undefined && p0.z !== undefined &&
                p1.x !== undefined && p1.y !== undefined && p1.z !== undefined) {
                x = p0.y * p1.z - p0.z * p1.y;
                y = p0.z * p1.x - p0.x * p1.z;
                z = p0.x * p1.y - p0.y * p1.x;
            }
        }

        return { x: x, y: y, z: z };
    }

    // Creates a new unit-length vector from the specified vector.
    // If the original vector is zero-length, the normalized vector will
    // be empty.
    // Returns the normalized vector, or an empty vector on failure.
    static normalize(p) {
        let dot = Vmath.dot(p, p);
        if (dot > Vmath.EPSILON) {
            let scale = 1.0 / Math.sqrt(dot);
            return Vmath.scale(p, scale);
        }
        else {
            return {};
        }
    }

    // Computes the squared distance between two specified vectors.
    // Returns the scalar distance squared.
    static distanceSquared(p0, p1) {
        let pd = Vmath.delta(p0, p1);
        let dist2 = Vmath.dot(pd, pd);
        return dist2;
    }

    // Computes the length squared of the specified vector.
    // Returns the scalar length squared.
    static lengthSquared(p) {
        return Vmath.dot(p, p);
    }

    // Computes the distance between two specified vectors.
    // Returns the scalar distance.
    static distance(p0, p1) {
        return Math.sqrt(Vmath.distanceSquared(p0, p1));
    }

    // Computes the length of the specified vector.
    // Returns the scalar length.
    static length(p) {
        return Math.sqrt(Vmath.lengthSquared(p));
    }

    // Finds the nearest point on a line segment relative to another point.
    // Returns the nearest point as a vector.
    static nearestPointOnLineSegment(p, l0, l1) {
        let ld = Vmath.delta(l1, l0);
        let d2 = Vmath.lengthSquared(ld);
        if (d2 < Vmath.EPSILON)
            return Vmath.copy(l0);

        let pd = Vmath.delta(p, l0);
        let t = Vmath.dot(pd, ld) / d2;
        t = Math.max(0, Math.min(1, t));
        return Vmath.scaleAndAdd(l0, ld, t);
    }

    // Calculates the squared distance between a point and a line segment.
    // Returns the scalar distance squared.
    static distanceToLineSegmentSquared(p, l0, l1) {
        let p1 = Vmath.nearestPointOnLineSegment(p, l0, l1);
        return Vmath.distanceSquared(p, p1);
    }

    // Calculates the distance between a point and a line segment.
    // Returns the scalar distance.
    static distanceToLineSegment(p, l0, l1) {
        return Math.sqrt(Vmath.distanceToLineSegmentSquared(p, l0, l1));
    }

    // Calculates the distance between a point and a plane, as defined
    // by a plane point and a plane normal.
    // Returns the scalar distance.
    static distanceToPlane(p, pp, pn) {
        let pd = Vmath.delta(p, pp);
        return Vmath.dot(pn, pd);
    }

    // Transforms the specified vector using the specified matrix.
    // Returns the transformed vector.
    static transform(p, m) {
        if (!p)  p = Vmath.EMPTY_OBJECT;
        if (!m)  m = Vmath.EMPTY_OBJECT;

        let x = p.x !== undefined ? p.x : 0;
        let y = p.y !== undefined ? p.y : 0;
        let z = p.z !== undefined ? p.z : 0;
        let w = p.w !== undefined ? p.w : 0;

        let m00 = m.m00 !== undefined ? m.m00 : 1;
        let m01 = m.m01 !== undefined ? m.m01 : 0;
        let m02 = m.m02 !== undefined ? m.m02 : 0;
        let m03 = m.m03 !== undefined ? m.m03 : 0;
        let m10 = m.m10 !== undefined ? m.m10 : 0;
        let m11 = m.m11 !== undefined ? m.m11 : 1;
        let m12 = m.m12 !== undefined ? m.m12 : 0;
        let m13 = m.m13 !== undefined ? m.m13 : 0;
        let m20 = m.m20 !== undefined ? m.m20 : 0;
        let m21 = m.m21 !== undefined ? m.m21 : 0;
        let m22 = m.m22 !== undefined ? m.m22 : 1;
        let m23 = m.m23 !== undefined ? m.m22 : 0;
        let m30 = m.m30 !== undefined ? m.m30 : 0;
        let m31 = m.m31 !== undefined ? m.m31 : 0;
        let m32 = m.m32 !== undefined ? m.m32 : 0;
        let m33 = m.m33 !== undefined ? m.m33 : 1;

        let nx, ny, nz, nw;

        if (p.x !== undefined)  nx = x * m00 + y * m10 + z * m20 + w * m30;
        if (p.y !== undefined)  ny = x * m01 + y * m11 + z * m21 + w * m31;
        if (p.z !== undefined)  nz = x * m02 + y * m12 + z * m22 + w * m32;
        if (p.w !== undefined)  nw = x * m03 + y * m13 + z * m23 + w * m33;

        return { x : nx, y: ny, z: nz, w: nw };
    }

    // Inverts the specified matrix.
    // Returns the inverted matrix.
    static invert(m) {
        if (!m)  m = Vmath.EMPTY_OBJECT;

        // We could really use invert4() for all of these cases...  but this is
        // a little more efficient.
        if (m.m33 !== undefined) {
            return Vmath.invert4(m);
        }
        else if (m.m22 !== undefined) {
            return Vmath.invert3(m);
        }
        else if (m.m11 !== undefined) {
            return Vmath.invert2(m);
        }
        else {
            return {};
        }
    }

    // Inverts the specified 2x2 matrix.
    // Returns the inverted matrix.
    static invert2(m) {
        if (!m)  m = Vmath.EMPTY_OBJECT;

        let m00 = m.m00 !== undefined ? m.m00 : 1;
        let m01 = m.m01 !== undefined ? m.m01 : 0;
        let m10 = m.m10 !== undefined ? m.m10 : 0;
        let m11 = m.m11 !== undefined ? m.m11 : 1;

        // Calculate the determinant
        var det = m00 * m11 - m10 * m01;

        if (Math.abs(det) < Vmath.EPSILON)
            return {};

        det = 1.0 / det;

        let nm00, nm01, nm10, nm11;

        if (m.m00 !== undefined)  nm00 =  m11 * det;
        if (m.m10 !== undefined)  nm01 = -m01 * det;
        if (m.m01 !== undefined)  nm10 = -m10 * det;
        if (m.m11 !== undefined)  nm11 =  m00 * det;

        return {
            m00: nm00, m01: nm01,
            m10: nm10, m11: nm11
        };
    }

    // Inverts the specified 3x3 matrix.
    // Returns the inverted matrix.
    static invert3(m) {
        if (!m)  m = Vmath.EMPTY_OBJECT;

        let m00 = m.m00 !== undefined ? m.m00 : 1;
        let m01 = m.m01 !== undefined ? m.m01 : 0;
        let m02 = m.m02 !== undefined ? m.m02 : 0;
        let m10 = m.m10 !== undefined ? m.m10 : 0;
        let m11 = m.m11 !== undefined ? m.m11 : 1;
        let m12 = m.m12 !== undefined ? m.m12 : 0;
        let m20 = m.m20 !== undefined ? m.m20 : 0;
        let m21 = m.m21 !== undefined ? m.m21 : 0;
        let m22 = m.m22 !== undefined ? m.m22 : 1;

        let n01 =  m22 * m11 - m12 * m21;
        let n11 = -m22 * m10 + m12 * m20;
        let n21 =  m21 * m10 - m11 * m20;

        // Calculate the determinant
        let det = m00 * n01 + m01 * n11 + m02 * n21;

        if (Math.abs(det) < Vmath.EPSILON)
            return {};

        det = 1.0 / det;

        let nm00, nm01, nm02;
        let nm10, nm11, nm12;
        let nm20, nm21, nm22;

        if (m.m00 !== undefined)  nm00 = n01 * det;
        if (m.m01 !== undefined)  nm01 = (-m22 * m01 + m02 * m21) * det;
        if (m.m02 !== undefined)  nm02 = ( m12 * m01 - m02 * m11) * det;
        if (m.m10 !== undefined)  nm10 = n11 * det;
        if (m.m11 !== undefined)  nm11 = ( m22 * m00 - m02 * m20) * det;
        if (m.m12 !== undefined)  nm12 = (-m12 * m00 + m02 * m10) * det;
        if (m.m20 !== undefined)  nm20 = n21 * det;
        if (m.m21 !== undefined)  nm21 = (-m21 * m00 + m01 * m20) * det;
        if (m.m22 !== undefined)  nm22 = ( m11 * m00 - m01 * m10) * det;

        return {
            m00 : nm00, m01: nm01, m02: nm02,
            m10 : nm10, m11: nm11, m12: nm12,
            m20 : nm20, m21: nm21, m22: nm22
        };
    }

    // Inverts the specified 4x4 matrix.
    // Returns the inverted matrix.
    static invert4(m) {
        if (!m)  m = Vmath.EMPTY_OBJECT;

        let m00 = m.m00 !== undefined ? m.m00 : 1;
        let m01 = m.m01 !== undefined ? m.m01 : 0;
        let m02 = m.m02 !== undefined ? m.m02 : 0;
        let m03 = m.m03 !== undefined ? m.m03 : 0;
        let m10 = m.m10 !== undefined ? m.m10 : 0;
        let m11 = m.m11 !== undefined ? m.m11 : 1;
        let m12 = m.m12 !== undefined ? m.m12 : 0;
        let m13 = m.m13 !== undefined ? m.m13 : 0;
        let m20 = m.m20 !== undefined ? m.m20 : 0;
        let m21 = m.m21 !== undefined ? m.m21 : 0;
        let m22 = m.m22 !== undefined ? m.m22 : 1;
        let m23 = m.m23 !== undefined ? m.m23 : 0;
        let m30 = m.m30 !== undefined ? m.m30 : 0;
        let m31 = m.m31 !== undefined ? m.m31 : 0;
        let m32 = m.m32 !== undefined ? m.m32 : 0;
        let m33 = m.m33 !== undefined ? m.m33 : 1;

        let n00 = m00 * m11 - m01 * m10;
        let n01 = m00 * m12 - m02 * m10;
        let n02 = m00 * m13 - m03 * m10;
        let n03 = m01 * m12 - m02 * m11;
        let n04 = m01 * m13 - m03 * m11;
        let n05 = m02 * m13 - m03 * m12;
        let n06 = m20 * m31 - m21 * m30;
        let n07 = m20 * m32 - m22 * m30;
        let n08 = m20 * m33 - m23 * m30;
        let n09 = m21 * m32 - m22 * m31;
        let n10 = m21 * m33 - m23 * m31;
        let n11 = m22 * m33 - m23 * m32;

        // Calculate the determinant
        var det = n00 * n11 - n01 * n10 + n02 * n09 + n03 * n08 - n04 * n07 + n05 * n06;

        if (Math.abs(det) < Vmath.EPSILON)
            return {};

        det = 1.0 / det;

        let nm00, nm01, nm02, nm03;
        let nm10, nm11, nm12, nm13;
        let nm20, nm21, nm22, nm23;
        let nm30, nm31, nm32, nm33;

        if (m.m00 !== undefined)  nm00 = (m11 * n11 - m12 * n10 + m13 * n09) * det;
        if (m.m01 !== undefined)  nm01 = (m02 * n10 - m01 * n11 - m03 * n09) * det;
        if (m.m02 !== undefined)  nm02 = (m31 * n05 - m32 * n04 + m33 * n03) * det;
        if (m.m03 !== undefined)  nm03 = (m22 * n04 - m21 * n05 - m23 * n03) * det;
        if (m.m10 !== undefined)  nm10 = (m12 * n08 - m10 * n11 - m13 * n07) * det;
        if (m.m11 !== undefined)  nm11 = (m00 * n11 - m02 * n08 + m03 * n07) * det;
        if (m.m12 !== undefined)  nm12 = (m32 * n02 - m30 * n05 - m33 * n01) * det;
        if (m.m13 !== undefined)  nm13 = (m20 * n05 - m22 * n02 + m23 * n01) * det;
        if (m.m20 !== undefined)  nm20 = (m10 * n10 - m11 * n08 + m13 * n06) * det;
        if (m.m21 !== undefined)  nm21 = (m01 * n08 - m00 * n10 - m03 * n06) * det;
        if (m.m22 !== undefined)  nm22 = (m30 * n04 - m31 * n02 + m33 * n00) * det;
        if (m.m23 !== undefined)  nm23 = (m21 * n02 - m20 * n04 - m23 * n00) * det;
        if (m.m30 !== undefined)  nm30 = (m11 * n07 - m10 * n09 - m12 * n06) * det;
        if (m.m31 !== undefined)  nm31 = (m00 * n09 - m01 * n07 + m02 * n06) * det;
        if (m.m32 !== undefined)  nm32 = (m31 * n01 - m30 * n03 - m32 * n00) * det;
        if (m.m33 !== undefined)  nm33 = (m20 * n03 - m21 * n01 + m22 * n00) * det;

        return {
            m00 : nm00, m01: nm01, m02: nm02, m03: nm03,
            m10 : nm10, m11: nm11, m12: nm12, m13: nm13,
            m20 : nm20, m21: nm21, m22: nm22, m23: nm23,
            m30 : nm30, m31: nm31, m32: nm32, m33: nm33
        };
    }

    // Transposes the specified matrix.
    // Returns the transposed matrix.
    static transpose(m) {
        if (!m)  m = Vmath.EMPTY_OBJECT;
        return {
            m00: m.m00, m01: m.m10, m02: m.m20, m03: m.m30,
            m10: m.m01, m11: m.m11, m12: m.m21, m13: m.m31,
            m20: m.m02, m21: m.m12, m22: m.m22, m23: m.m32,
            m30: m.m03, m31: m.m13, m32: m.m23, m33: m.m33
        };
    }

    // Multiplies the two specified matrices.
    // Returns the multiplied matrix.
    static multiply(m0, m1) {
        if (!m0)  m = Vmath.EMPTY_OBJECT;
        if (!m1)  m = Vmath.EMPTY_OBJECT;

        let mat4 = m0.m33 !== undefined || m1.m33 !== undefined;
        let mat3 = m0.m22 !== undefined || m1.m22 !== undefined;
        let mat2 = m0.m11 !== undefined || m1.m11 !== undefined;
        let mat1 = m0.m00 !== undefined || m1.m00 !== undefined;

        let a00 = m0.m00 !== undefined ? m0.m00 : 1;
        let a01 = m0.m01 !== undefined ? m0.m01 : 0;
        let a02 = m0.m02 !== undefined ? m0.m02 : 0;
        let a03 = m0.m03 !== undefined ? m0.m03 : 0;
        let a10 = m0.m10 !== undefined ? m0.m10 : 0;
        let a11 = m0.m11 !== undefined ? m0.m11 : 1;
        let a12 = m0.m12 !== undefined ? m0.m12 : 0;
        let a13 = m0.m13 !== undefined ? m0.m13 : 0;
        let a20 = m0.m20 !== undefined ? m0.m20 : 0;
        let a21 = m0.m21 !== undefined ? m0.m21 : 0;
        let a22 = m0.m22 !== undefined ? m0.m22 : 1;
        let a23 = m0.m23 !== undefined ? m0.m23 : 0;
        let a30 = m0.m30 !== undefined ? m0.m30 : 0;
        let a31 = m0.m31 !== undefined ? m0.m31 : 0;
        let a32 = m0.m32 !== undefined ? m0.m32 : 0;
        let a33 = m0.m33 !== undefined ? m0.m33 : 1;

        let b00 = m1.m00 !== undefined ? m1.m00 : 1;
        let b01 = m1.m01 !== undefined ? m1.m01 : 0;
        let b02 = m1.m02 !== undefined ? m1.m02 : 0;
        let b03 = m1.m03 !== undefined ? m1.m03 : 0;
        let b10 = m1.m10 !== undefined ? m1.m10 : 0;
        let b11 = m1.m11 !== undefined ? m1.m11 : 1;
        let b12 = m1.m12 !== undefined ? m1.m12 : 0;
        let b13 = m1.m13 !== undefined ? m1.m13 : 0;
        let b20 = m1.m20 !== undefined ? m1.m20 : 0;
        let b21 = m1.m21 !== undefined ? m1.m21 : 0;
        let b22 = m1.m22 !== undefined ? m1.m22 : 1;
        let b23 = m1.m23 !== undefined ? m1.m23 : 0;
        let b30 = m1.m30 !== undefined ? m1.m30 : 0;
        let b31 = m1.m31 !== undefined ? m1.m31 : 0;
        let b32 = m1.m32 !== undefined ? m1.m32 : 0;
        let b33 = m1.m33 !== undefined ? m1.m33 : 1;

        let nm00, nm01, nm02, nm03;
        let nm10, nm11, nm12, nm13;
        let nm20, nm21, nm22, nm23;
        let nm30, nm31, nm32, nm33;

        if (mat1)  nm00 = b00 * a00 + b01 * a10 + b02 * a20 + b03 * a30;
        if (mat2)  nm01 = b00 * a01 + b01 * a11 + b02 * a21 + b03 * a31;
        if (mat3)  nm02 = b00 * a02 + b01 * a12 + b02 * a22 + b03 * a32;
        if (mat4)  nm03 = b00 * a03 + b01 * a13 + b02 * a23 + b03 * a33;

        if (mat2)  nm10 = b10 * a00 + b11 * a10 + b12 * a20 + b13 * a30;
        if (mat2)  nm11 = b10 * a01 + b11 * a11 + b12 * a21 + b13 * a31;
        if (mat3)  nm12 = b10 * a02 + b11 * a12 + b12 * a22 + b13 * a32;
        if (mat4)  nm13 = b10 * a03 + b11 * a13 + b12 * a23 + b13 * a33;

        if (mat3)  nm20 = b20 * a00 + b21 * a10 + b22 * a20 + b23 * a30;
        if (mat3)  nm21 = b20 * a01 + b21 * a11 + b22 * a21 + b23 * a31;
        if (mat3)  nm22 = b20 * a02 + b21 * a12 + b22 * a22 + b23 * a32;
        if (mat4)  nm23 = b20 * a03 + b21 * a13 + b22 * a23 + b23 * a33;

        if (mat4)  nm30 = b30 * a00 + b31 * a10 + b32 * a20 + b33 * a30;
        if (mat4)  nm31 = b30 * a01 + b31 * a11 + b32 * a21 + b33 * a31;
        if (mat4)  nm32 = b30 * a02 + b31 * a12 + b32 * a22 + b33 * a32;
        if (mat4)  nm33 = b30 * a03 + b31 * a13 + b32 * a23 + b33 * a33;

        return {
            m00: nm00, m01: nm01, m02: nm02, m03: nm03,
            m10: nm10, m11: nm11, m12: nm12, m13: nm13,
            m20: nm20, m21: nm21, m22: nm22, m23: nm23,
            m30: nm30, m31: nm31, m32: nm32, m33: nm33
        };
    }

    // Converts a matrix to an array of vectors (based on matrix rows).
    // Returns the n-dimensional vectors as an array.
    static vectorsFromMatrix(m) {
        if (!m)  m = Vmath.EMPTY_OBJECT;

        let p = [];

        if (p.length > 0 ||
            m.m30 !== undefined || m.m31 !== undefined || m.m32 !== undefined || m.m33 !== undefined)
            p.push(Vmath.createVector(m.m30, m.m31, m.m32, m.m33));
        if (p.length > 0 ||
            m.m20 !== undefined || m.m21 !== undefined || m.m22 !== undefined || m.m23 !== undefined)
            p.push(Vmath.createVector(m.m20, m.m21, m.m22, m.m23));
        if (p.length > 0 ||
            m.m10 !== undefined || m.m11 !== undefined || m.m12 !== undefined || m.m13 !== undefined)
            p.push(Vmath.createVector(m.m10, m.m11, m.m12, m.m13));
        if (p.length > 0 ||
            m.m00 !== undefined || m.m01 !== undefined || m.m02 !== undefined || m.m03 !== undefined)
            p.push(Vmath.createVector(m.m00, m.m01, m.m02, m.m03));

        p.reverse();

        return p;
    }

    // Converts a number of vectors to a matrix (based on matrix rows).
    // The vectors may be specified individually or as an array.
    // Returns the new n-dimensional matrix.
    static matrixFromVectors(p0, p1, p2, p3) {
        if (Array.isArray(p0)) {
            p3 = p0.length > 3 ? p0[3] : null;
            p2 = p0.length > 2 ? p0[2] : null;
            p1 = p0.length > 1 ? p0[1] : null;
            p0 = p0.length > 0 ? p0[0] : null;
        }

        if (!p0)  p0 = Vmath.EMPTY_OBJECT;
        if (!p1)  p1 = Vmath.EMPTY_OBJECT;
        if (!p2)  p2 = Vmath.EMPTY_OBJECT;
        if (!p3)  p3 = Vmath.EMPTY_OBJECT;

        return {
            m00: p0.x, m01: p0.y, m02: p0.z, m03: p0.w,
            m10: p1.x, m11: p1.y, m12: p1.z, m13: p1.w,
            m20: p2.x, m21: p2.y, m22: p2.z, m23: p2.w,
            m30: p3.x, m31: p3.y, m32: p3.z, m33: p3.w
        };
    }
}
Vmath.EMPTY_OBJECT = Object.freeze({});
Vmath.EPSILON      = 1e-7;
