
// This file is only used to compile a UMD for projects without a JS bundler.
// When using rollup, webpack, etc, you'll reach directly into the src files.

import Histogram from '../src/histogram';
import Volume from '../src/volume';
import VolumeViewer from '../src/volume-viewer';
import Animate from '../src/animate';
import * as Tables from "../src/tables";
import dconsole from "../src/dconsole";

VolumeViewer.Histogram = Histogram;
VolumeViewer.Volume = Volume;
VolumeViewer.Animate = Animate;
VolumeViewer.Tables = Tables;
VolumeViewer.dconsole = dconsole;

export default VolumeViewer;